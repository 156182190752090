import { serverErrors, UNEXPECTED_ERROR } from "./../../utils/errors";
import api from "../../api";
import * as tracking from './../../utils/gt-tracking';
import history from "./../../History";
import { toast } from 'react-toastify';
import _ from 'lodash';
import { BASE_PATH } from "./../../Config";
import moment from "moment";
import { loadCustomerData } from "./CustomerAction";

export const FETCHING_ORDERS_ERROR        = 'FETCHING_ORDERS_ERROR';
export const FETCHING_ORDERS              = 'FETCHING_ORDERS';
export const FETCHING_ORDERS_SUCCESS      = 'FETCHING_ORDERS_SUCCESS';

export function fetchOrders() {
   return function(dispatch, getState) {
     
     dispatch({type: FETCHING_ORDERS});
     api.getOrders()
       .then(resp => {
         dispatch({type: FETCHING_ORDERS_SUCCESS, orders: resp.data});
       }, errorResp => {
         dispatch({type: FETCHING_ORDERS_ERROR, errors: serverErrors(errorResp)});
       });
   };
}

export const FETCHING_CLICK_ERROR        = 'FETCHING_CLICK_ERROR';
export const FETCHING_CLICK              = 'FETCHING_CLICK';
export const FETCHING_CLICK_SUCCESS      = 'FETCHING_CLICK_SUCCESS';

export function fetchSuggestedItemClick(suggestedItemId) {
   return function(dispatch, getState) {
     
     dispatch({type: FETCHING_CLICK});
     api.addSuggestedItemsClickTracking(suggestedItemId)
       .then(resp => {
         dispatch({type: FETCHING_CLICK_SUCCESS, orders: resp.data});
       }, errorResp => {
         dispatch({type: FETCHING_CLICK_ERROR, errors: serverErrors(errorResp)});
       });
   };
}

export const BOX_REQUESTED       = 'BOX_REQUESTED';
export const BOX_REQUESTED_SUCCESS   = 'BOX_REQUESTED_SUCCESS';
export const BOX_REQUESTED_ERROR     = 'BOX_REQUESTED_ERROR';

export const UPDATING_STYLE_PROFILE              = 'UPDATING_STYLE_PROFILE';
export const UPDATING_STYLE_PROFILE_SUCCESS      = 'UPDATING_STYLE_PROFILE_SUCCESS';
export const UPDATING_STYLE_PROFILE_ERROR        = 'UPDATING_STYLE_PROFILE_ERROR';
export function boxRequested(data = {}){
  return function(dispatch, getState){
    let ship_by_date = data.ship_by_date ? data.ship_by_date : moment().add(4, 'days');
    dispatch(updateCurrentOrder({ship_by_date}));
    
    data = _.merge(_.get(getState(), 'orders.currentOrder', {}), data);
    
    dispatch({type: BOX_REQUESTED, data});
    
    return api.createOrder(data)
      .then(resp => {
        dispatch({type: BOX_REQUESTED_SUCCESS, order: resp.data});
        
        tracking.push({eventCategory: 'request_trendsend', eventAction: 'complete'});
        if (resp.data.customer_pick_up) {
          tracking.push({eventCategory: 'purchase', eventAction: 'pickup order placed', eventLabel: 'purchase'});
        } else {
          tracking.push({eventCategory: 'purchase', eventAction: 'on demand box order', eventLabel: 'purchase'});
        }
        toast.success("Your order has been created. Update your Style Profile to receive looks you’ll love.")
        dispatch(fetchOrders());
        dispatch(loadCustomerData());
        history.push(`${BASE_PATH}/dashboard`);
      }, errorResp => {
        dispatch({type: BOX_REQUESTED_ERROR, error: serverErrors(errorResp)});
      });
  }
}
  
export function boxRequestedWithStyleProfile(responses = {}, data = {}){
  return function(dispatch, getState){
    let questions = getState().questions;

    if (questions.responses.birthday_month && questions.responses.birthday_month.length !== 10) {
      delete questions.responses.birthday_month;
    }
    
    dispatch({type: UPDATING_STYLE_PROFILE});
    
    api.updateStyleProfile(questions.id, _.merge(questions.responses, responses) )
      .then(resp => {
        dispatch({type: UPDATING_STYLE_PROFILE_SUCCESS, responses, stats: resp.data.data.stats});
      
        let ship_by_date = data.ship_by_date ? data.ship_by_date : moment().add(4, 'days');
        dispatch(updateCurrentOrder({ship_by_date}));
        
        data = _.merge(_.get(getState(), 'orders.currentOrder', {}), data);
        
        dispatch({type: BOX_REQUESTED, data});
        
        return api.createOrder(data)
          .then(resp => {
            dispatch({type: BOX_REQUESTED_SUCCESS, order: resp.data});
            
            tracking.push({eventCategory: 'request_trendsend', eventAction: 'complete'});
            if (resp.data.customer_pick_up) {
              tracking.push({eventCategory: 'purchase', eventAction: 'pickup order placed', eventLabel: 'purchase'});
            } else {
              tracking.push({eventCategory: 'purchase', eventAction: 'on demand box order', eventLabel: 'purchase'});
            }
            toast.success("Your order has been created. Update your Style Profile to receive looks you’ll love.")
            dispatch(fetchOrders());
            dispatch(loadCustomerData());
            history.push(`${BASE_PATH}/dashboard`);
          }, errorResp => {
            dispatch({type: BOX_REQUESTED_ERROR, error: serverErrors(errorResp)});
          });
      }, errorResp => {
        dispatch({type: UPDATING_STYLE_PROFILE_ERROR, errors: serverErrors(errorResp)});
      });
  }
}

export const UPDATE_ORDER       = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS   = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR     = 'UPDATE_ORDER_ERROR';
  
export function updateOrder(responses = {}, data = {}){
  return function(dispatch, getState){
    let questions = getState().questions;

    if (questions.responses.birthday_month && questions.responses.birthday_month.length !== 10) {
      delete questions.responses.birthday_month;
    }
    
    dispatch({type: UPDATING_STYLE_PROFILE});
    
    api.updateStyleProfile(questions.id, _.merge(questions.responses, responses) )
      .then(resp => {
        dispatch({type: UPDATING_STYLE_PROFILE_SUCCESS, responses, stats: resp.data.data.stats});
      
        let ship_by_date = data.ship_by_date ? data.ship_by_date : moment().add(4, 'days');
        dispatch(updateCurrentOrder({ship_by_date}));
        
        data = _.merge(_.get(getState(), 'orders.currentOrder', {}), data);
        
        dispatch({type: UPDATE_ORDER, data});
    
        return api.updateOrder(data.id, data)
          .then(resp => {
            dispatch({type: UPDATE_ORDER_SUCCESS, order: resp.data});
            history.push(`${BASE_PATH}/dashboard`);
            dispatch(fetchOrders());
          }, errorResp => {
            dispatch({type: UPDATE_ORDER_ERROR, error: serverErrors(errorResp)});
          });
      }, errorResp => {
        dispatch({type: UPDATING_STYLE_PROFILE_ERROR, errors: serverErrors(errorResp)});
      });
  }
}


export const UPDATE_CURRENT_ORDER = 'UPDATE_CURRENT_ORDER';

export function updateCurrentOrder(data) {
  return function(dispatch, getState) {
    dispatch({type: UPDATE_CURRENT_ORDER, data})
  }
}

export const EXTEND_CHECKOUT_ERROR = 'EXTEND_CHECKOUT_ERROR';

export function extendCheckout(data, extendDate) {
  return function (dispatch, getState) {
    if (window.confirm(`Your new checkout date will be ${extendDate}. Please check out and postmark returns by this date to avoid being charged for your full order.`)) {
      dispatch({ type: UPDATE_CURRENT_ORDER, data })

      api.extendCheckout(data.id, data)
        .then(resp => {
          dispatch({ type: UPDATE_ORDER_SUCCESS, order: resp.data });
          dispatch(fetchOrders());
          toast.success(`Your checkout has been extended by 5 days.`);
          history.push(`${BASE_PATH}/dashboard`);
        }, errorResp => {
          toast.error('Could not extend checkout. Please try again or contact customer support.');
          dispatch({ type: EXTEND_CHECKOUT_ERROR, error: serverErrors(errorResp) });
        });
    }
  }
}

// export const UPDATE_EXCHANGE_ADDRESS = 'UPDATE_EXCHANGE_ADDRESS';
const SHIPPING_PARAMS = ['id', 'name', 'phone', 'fax', 'line_1', 'line_2', 'city', 'state', 'postal_code', 'country', 'is_primary'];

export function setExchangeAddress(data, redirectTo) {
  return function (dispatch, getState) {
    let shippingData = _.pick(
      _.reduce(
        _.pickBy(data, (val, key) => {
          return _.includes(key, 'shipping_');
        }),
        (current, val, key) => {
          current['is_primary'] = true;
          current[key.replace('shipping_', '')] = val;
          return current;
        },
        {}
      ),
      SHIPPING_PARAMS
    );

    if (!_.isEmpty(shippingData)) {
      localStorage.removeItem('exchangeAddress');
      localStorage.setItem(
        'exchangeAddress',
        JSON.stringify(_.pick(shippingData, SHIPPING_PARAMS))
      );
      history.push(`${BASE_PATH}${redirectTo}`);
    }
  };
}


export const CANCEL_ORDER_REQUESTED = "CANCEL_ORDER_REQUESTED";
export const CANCEL_ORDER_RECEIVED  = "CANCEL_ORDER_RECEIVED";
export const CANCEL_ORDER_ERROR     = "CANCEL_ORDER_ERROR";
export function cancelOrder(orderId) {
  return function(dispatch, getState) {
    if (window.confirm('Are you sure you want to cancel this order?')) {
      dispatch({type: CANCEL_ORDER_REQUESTED});

      api.cancelOrder(orderId)
        .then(
          resp => {
            dispatch({type: CANCEL_ORDER_RECEIVED, orderId: orderId});
            toast.success("Your current order has been successfully canceled.")
            dispatch(fetchOrders());
          },
          resp => dispatch({type: CANCEL_ORDER_ERROR, errors: serverErrors(resp)})
        );
    }
  }
}



export const INVOICE_REQUESTED      = "INVOICE_REQUESTED";
export const INVOICE_RECEIVED       = "INVOICE_RECEIVED";
export const INVOICE_RECEIVED_ERROR = "INVOICE_RECEIVED_ERROR";

export function fetchOrCreateInvoice(orderId) {
  return function(dispatch, getState) {
    dispatch({type: INVOICE_REQUESTED});

    api.getOrderInvoice(orderId)
      .then(resp => {
        dispatch({type: INVOICE_RECEIVED, invoice: resp.data});
      }, errorResp => {
        if (errorResp.response.status === 404) {
          api.createOrderInvoice(orderId)
            .then(invoiceResp => {
              // This is hacky but since creating an invoice also modifies the order's line item
              // pricing, we need to re-fetch the order to ensure we display correct pricing.
              api.getOrder(orderId)
                .then(orderResp => {
                  dispatch({type: FETCHING_ORDERS_SUCCESS, orders: [orderResp.data]})
                  dispatch({type: INVOICE_RECEIVED, invoice: invoiceResp.data})
                  history.push(`${BASE_PATH}/checkout/review`);
                }, getOrderErrorResp => {
                  dispatch({
                    type: FETCHING_ORDERS_ERROR,
                    errors: serverErrors(getOrderErrorResp)
                  });
                });
            }, errorResp => {
              dispatch({
                type: INVOICE_RECEIVED_ERROR,
                errors: serverErrors(errorResp)
              });
            });
        } else {
          dispatch({type: INVOICE_RECEIVED_ERROR, errors: serverErrors(errorResp)});
        }
      });
  }
}


export const ORDER_FEEDBACK_REQUESTED      = "ORDER_FEEDBACK_REQUESTED";
export const ORDER_FEEDBACK_RECEIVED       = "ORDER_FEEDBACK_RECEIVED";
export const ORDER_FEEDBACK_RECEIVED_ERROR = "ORDER_FEEDBACK_RECEIVED_ERROR";

export function feedbackSubmitted(orderId, orderData) {
  return function(dispatch, getState) {
    dispatch({type: ORDER_FEEDBACK_REQUESTED});

    api.updateOrder(orderId, orderData)
      .then(resp => {
        dispatch({type: ORDER_FEEDBACK_RECEIVED, order: resp.data});
        history.push(`${BASE_PATH}/checkout/confirmation`)
      })
      .catch(resp => {
        dispatch({type: ORDER_FEEDBACK_RECEIVED_ERROR, errors: serverErrors(resp)});
      });
  }
}

export const UPDATE_CURRENT_ORDER_LINE_ITEMS          = "UPDATE_CURRENT_ORDER_LINE_ITEMS";
export const UPDATE_CURRENT_ORDER_LINE_ITEMS_SUCCESS  = "UPDATE_CURRENT_ORDER_LINE_ITEMS_SUCCESS";
export const UPDATE_CURRENT_ORDER_LINE_ITEMS_ERROR    = "UPDATE_CURRENT_ORDER_LINE_ITEMS_ERROR";

export function updateOrderLineItems(feedback) {
  return function(dispatch, getState) {
    let orderId = _.get(getState(), `orders.currentOrder.id`);
    dispatch({type: UPDATE_CURRENT_ORDER_LINE_ITEMS, feedback});
    let lineItems = _.chain(getState())
                     .get(`orders.currentOrder.outfits`)
                     .map(o => o.line_items)
                     .flatten()
                     .map(l => {
                       let {action, info, additionalInfo} = _.get(feedback, `${l.id}`);
                       if (action === 'Return' && !_.isArray(info)) {
                         additionalInfo = info;
                         info = [];
                       }
                       l.claimed_kept = action !== 'Return';
                       l.exchange_size = action === 'Exchange' ? info : '';
                       l.customer_feedback = action === 'Return' || action === 'Keep' ? `${_.isEmpty(info) ? '' : `${info.join(' , ')}. `}` : '';
                       l.additional_info = additionalInfo ? `${additionalInfo}` : '';
                       return l;
                     })
      .value()

    const filteredLineItems = _.flatMap(_.uniqBy(lineItems, "id"))

    api.updateOrder(orderId, {line_items_attributes: filteredLineItems})
      .then(resp => {
        dispatch({type: UPDATE_CURRENT_ORDER_LINE_ITEMS_SUCCESS, feedback});
        dispatch({type: UPDATE_CURRENT_ORDER, order: resp.data});
        api.getOrderInvoice(orderId)
           .then(resp => {
             dispatch({type: INVOICE_RECEIVED, invoice: resp.data})
             api.getOrders()
                 .then(resp => {
                   dispatch({type: FETCHING_ORDERS_SUCCESS, orders: resp.data});
                   history.push(`${BASE_PATH}/checkout/confirmation`);
                 }, errorResp => {
                   dispatch({type: FETCHING_ORDERS_ERROR, errors: serverErrors(errorResp)});
                 });
           }, errorResp => {
             dispatch({
               type: INVOICE_RECEIVED_ERROR,
               errors: serverErrors(errorResp)
             });
           })
        
      })
      .catch(resp => {
        dispatch({type: UPDATE_CURRENT_ORDER_LINE_ITEMS_ERROR, errors: serverErrors(resp)});
      });
  }
}


export const AVAILABLE_EXCHANGE_SIZES_REQUESTED = 'AVAILABLE_EXCHANGE_SIZES_REQUESTED';
export const AVAILABLE_EXCHANGE_SIZES_RECEIVED = 'AVAILABLE_EXCHANGE_SIZES_RECEIVED';
export const AVAILABLE_EXCHANGE_SIZES_RECEIVED_ERROR = 'AVAILABLE_EXCHANGE_SIZES_RECEIVED_ERROR';
export function fetchAvailableExchangeSizes(lineItemId) {
  return function(dispatch, getState) {
    dispatch({type: AVAILABLE_EXCHANGE_SIZES_REQUESTED});

    api.availableExchangeSizes(lineItemId)
      .then(resp => {
        dispatch({type: AVAILABLE_EXCHANGE_SIZES_RECEIVED, exchangeSizes: resp.data, lineItemId});
      })
      .catch(resp => {
        dispatch({type: AVAILABLE_EXCHANGE_SIZES_RECEIVED_ERROR, errors: serverErrors(resp)});
      });
  };
}


export const ADD_GIFT_CARD_REQUESTED      = "ADD_GIFT_CARD_REQUESTED";
export const ADD_GIFT_CARD_RECEIVED       = "ADD_GIFT_CARD_RECEIVED";
export const ADD_GIFT_CARD_RECEIVED_ERROR = "ADD_GIFT_CARD_RECEIVED_ERROR";
export function addGiftCard(invoiceId, giftCardCode) {
  return function(dispatch, getState) {
    dispatch({type: ADD_GIFT_CARD_REQUESTED});
    api.addGiftCardToInvoice(invoiceId, giftCardCode)
      .then(resp => dispatch({type: INVOICE_RECEIVED, invoice: resp.data}))
      .catch(resp => {
        dispatch({type: ADD_GIFT_CARD_RECEIVED_ERROR, errors: serverErrors(resp)});
      });
  };
}

export const REMOVE_GIFT_CARD_REQUESTED      = "REMOVE_GIFT_CARD_REQUESTED";
export const REMOVE_GIFT_CARD_RECEIVED       = "REMOVE_GIFT_CARD_RECEIVED";
export const REMOVE_GIFT_CARD_RECEIVED_ERROR = "REMOVE_GIFT_CARD_RECEIVED_ERROR";
export function removeGiftCard(invoiceId) {
  return function(dispatch, getState) {
    dispatch({type: REMOVE_GIFT_CARD_REQUESTED});

    api.removeGiftCardFromInvoice(invoiceId)
      .then(resp => dispatch({type: INVOICE_RECEIVED, invoice: resp.data}))
      .catch(resp => {
        dispatch({type: REMOVE_GIFT_CARD_RECEIVED_ERROR, errors: serverErrors(resp)});
      });
  };
}

export const ADD_COUPON_REQUESTED      = "ADD_COUPON_REQUESTED";
export const ADD_COUPON_RECEIVED       = "ADD_COUPON_RECEIVED";
export const ADD_COUPON_RECEIVED_ERROR = "ADD_COUPON_RECEIVED_ERROR";
export function addCoupon(invoiceId, code) {
  return function(dispatch, getState) {
    dispatch({type: ADD_COUPON_REQUESTED});

    api.addCouponToInvoice(invoiceId, code)
      .then(resp => dispatch({type: INVOICE_RECEIVED, invoice: resp.data}))
      .catch(resp => {
        dispatch({type: ADD_COUPON_RECEIVED_ERROR, errors: serverErrors(resp)});
      });
  };
}

export const REMOVE_COUPON_REQUESTED      = "REMOVE_COUPON_REQUESTED";
export const REMOVE_COUPON_RECEIVED       = "REMOVE_COUPON_RECEIVED";
export const REMOVE_COUPON_RECEIVED_ERROR = "REMOVE_COUPON_RECEIVED_ERROR";
export function removeCoupon(invoiceId) {
  return function(dispatch, getState) {
    dispatch({type: REMOVE_COUPON_REQUESTED});

    api.removeCouponFromInvoice(invoiceId)
      .then(resp => dispatch({type: INVOICE_RECEIVED, invoice: resp.data}))
      .catch(resp => {
        dispatch({type: REMOVE_COUPON_RECEIVED_ERROR, errors: serverErrors(resp)});
      });
  };
}

export const CHECKOUT_REQUESTED      = "CHECKOUT_REQUESTED";
export const CHECKOUT_RECEIVED       = "CHECKOUT_RECEIVED";
export const CHECKOUT_RECEIVED_ERROR = "CHECKOUT_RECEIVED_ERROR";
export function checkout(invoiceId, order, exchangeAddress) {
  
  return function (dispatch, getState) {
    dispatch({ type: CHECKOUT_REQUESTED });
    api
      .checkoutOrder(invoiceId, exchangeAddress)
      .then((resp) => {
        dispatch({ type: INVOICE_RECEIVED, invoice: resp.data });

        !_.isEmpty(localStorage.removeItem('exchangeAddress')) &&
          localStorage.removeItem('exchangeAddress');

        history.push(
          `${BASE_PATH}/checkout/success${
            order.customer_pick_up ? '-drtg' : ''
          }`
        );
      })
      .catch((resp) => {
        dispatch({ type: CHECKOUT_RECEIVED_ERROR, errors: serverErrors(resp) });
      });
  };
}

