import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../History';
import { BASE_PATH } from '../Config';
import style from '../TextArea/TextArea.module.scss';
import { Cell, Grid } from 'styled-css-grid';
import SectionHeader from 'SectionHeader';
import { Formik, Form, Field } from 'formik';
import Button from 'Button';
import { submitReview } from './../store/Actions/ReviewActions';
import { schema, reviewData, initialValues } from './schema';
import _ from 'lodash';
import ReactStars from 'react-rating-stars-component';
import { fetchOrders } from 'store/Actions/OrderAction';

function OrderReview() {
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.customer.data);
  const order = useSelector((state) => _.get(state, 'orders.currentOrder', []));
  const [isReviewEmpty, setReviewEmpty] = useState(true);
  const [reviewRating, setReviewRating] = useState('N/A');

  const reviewStars = {
    size: 25,
    color: '#c7d4e4',
    activeColor: '#03a9f4',
    isHalf: false,
    onChange: (newValue) => {
      setReviewRating(newValue);
    },
  };

  function submitForm(values) {
    const orderWithReview = {
      ...order,
      order_review: values.review,
      review_rating: reviewRating,
    };

    const orderData = orderWithReview ? orderWithReview : '';

    const params = {
      appkey: `${process.env.REACT_APP_YOTPO_API_KEY}`,
      domain: 'www.trendsend.com',
      sku: 'yotpo_site_reviews',
      product_title: 'Site Review',
      product_description:
        'How as your overall experience? Tell us what you thought of the styling, products, shipping, or website.',
      display_name: `${customer.first_name} ${customer.last_name}`,
      email: customer.email,
      review_content: values.review,
      review_title:
        'How as your overall experience? Tell us what you thought of the styling, products, shipping, or website.',
      review_score: reviewRating,
      signature:
        '14392ba624b04f8fadeead7173ac9974d7aebfa35fbbda96709c297324b31736',
      time_stamp: `${Math.round(new Date().getTime()/1000)}`,
      reviewer_type: 'verified_reviewer',
    };

    dispatch(submitReview(params, orderData));
  }

  const setReviewText = (event) => {
    const reviewText = event.target.value && event.target.value;
    setReviewEmpty(reviewText ? false : true);
  };

  useEffect(() => {}, [dispatch]);

  return (
    <div className='group group--slim mt mb'>
      <Cell width={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={submitForm}
        >
          {({ isValid }) => (
            <Form>
              <SectionHeader title={'Leave a Review'} FontSizeLarge />
              <div className='df aic jfc'>
                <span className='mr'>Rate your stylist: </span>
                <span>
                  <ReactStars {...reviewStars} />
                </span>
              </div>
              <SectionHeader
                description={
                  'How was your overall experience? Tell us what you thought of the styling, products, shipping, or website.'
                }
              />
              <Grid>
                {reviewData.map(
                  ({ label, placeholder, columnWidth, type, name, id }) => {
                    return (
                      <Cell
                        width={columnWidth}
                        key={id}
                        className={style.TextArea}
                      >
                        <label className={style.TextAreaLabel}>
                          <Field
                            as='textarea'
                            className={style.TextAreaInput}
                            name={name}
                            type={type}
                            label={label}
                            placeholder={label}
                            onKeyUp={setReviewText}
                          ></Field>
                        </label>
                      </Cell>
                    );
                  }
                )}
              </Grid>
              <div className='p mt2'>
                <Button
                  handleClick={
                    isReviewEmpty
                      ? () => {
                          dispatch(fetchOrders());
                          history.push(`${BASE_PATH}/`);
                        }
                      : null
                  }
                  variant='Primary'
                  content={
                    isReviewEmpty && reviewRating === 'N/A'
                      ? 'Go To Dashboard'
                      : 'Submit Review'
                  }
                  fullWidth={true}
                  type='submit'
                />
              </div>
            </Form>
          )}
        </Formik>
      </Cell>
    </div>
  );
}

export default OrderReview;
