import AngleFitImage from "images/perfect-fit.jpg";
import AngleShippingConfirm from "images/shipping-confirm.jpg";
import { DASHBOARD_URL, HOME_URL, BASE_PATH } from "./../Config.js"
import moment from "moment";

export const stylist = {
  title: [`Thanks for letting us know what makes you tick. Now, let's make sure we get the <span class="c-primary">perfect fit</span>.`],
  image: AngleFitImage,
  angleColor: "#c7d4e4",
  angleSecondaryColor: "#003b5c",
  button: {
    title: `Sounds Good!`,
    path: `${BASE_PATH}/quiz/15`,
  }
}

export const shipping = (date, isDressingRoomToGoCustomer, isThemeboxSignUp) => { 
  
  return {
    title: [
      isDressingRoomToGoCustomer ? (`Got it! Your order has been submitted and a stylist will get started on it soon. <br> <br>
      ${ date ? `Trendsend pickup date: <span class="c-primary">${moment(date).format('MM/DD/YYYY')}</span>` : '' }.
      <br/>
      <br/>
      We’ll contact you when it’s ready! In the meantime, you can add more to your style preferences by clicking “Update Style Profile ” from the Dashboard.`) : (`<span class="c-primary">How Exciting!</span> Your stylist will get to work curating your box soon.`,
      date ? `Your Trendsend’s estimated delivery date is <span class="c-primary">${moment(date).format('MM/DD/YYYY')}</span>.
      <br/>
      <br/>
      Please click below to view your profile or to customize your order${!isThemeboxSignUp ? ` by selecting a Theme Box` : ''}.` : '')
    ],
    angleColor: isDressingRoomToGoCustomer ? '' : "#FA86C8",
    angleSecondaryColor: isDressingRoomToGoCustomer ? '' : "#FDC3E4",
    image: AngleShippingConfirm,
    button: {
      title: `Go To Dashboard`,
      path: `${BASE_PATH}/dashboard`,
    }
  }
}

export const account = {
  title: [
    `Thank you for creating your account! Let us know more about <span class="c-primary">your preferences</span> so we can get it just right.`,
  ],
  image: AngleShippingConfirm,
  angleColor: "#F9423A",
  angleSecondaryColor: "#FCA09C",
  button: {
    title: `SOUNDS GREAT!`,
    path: `${BASE_PATH}/quiz/1`,
  }
}