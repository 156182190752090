import React from 'react';
import style from "./Dashboard.module.scss";

function CoreContent(props) {
    return (
        <div className={style.coreTile}>
            <div className={style.imgNoCTA}>
                <img src={props.image} className={style.iconNoCTA} alt=""/>
            </div>
            <div className={style.textNoCTA}>
                <h3>{props.h3}</h3>
                {props.isDrtg ? <h4>{props.h4}</h4> : <h4>{props.h4}</h4>}
                {props.isDrtg ? <h4 style={{ marginTop: '12px'}}>{props.showAddress}</h4> : ''}
            </div>
        </div>
    );
}

export default CoreContent;
