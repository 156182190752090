import React, { useState } from 'react';
import { Cell } from 'styled-css-grid';
import style from './Dropdown.module.scss';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';

function Dropdown({
  getValue,
  initialValue,
  questionRef,
  choices,
  placeholder,
  title,
  columnWidth,
}) {
  const [showInput, setShowInput] = useState(false);
  const customChoice = initialValue && !choices.some((choice) => choice.label === initialValue);

  function updateOption(e) {
    const selectedValue = e.target.value;
    setShowInput(
      selectedValue === 'Other' && questionRef === 'jeans_best_fit_brand'
    );
    getValue && getValue(selectedValue, questionRef);
  }

  const isMobile = useMediaQuery({ query: '(max-width: 512px)' });

  const defaultOption = <option value=''>{placeholder}</option>;
  const options = choices.map((item, index) => {
    return (
      <option
        key={index}
        value={_.isEmpty(item.value) ? item.label : item.value}
      >
        {item.label}
      </option>
    );
  });

  columnWidth = 12;
  return (
    <Cell
      width={questionRef.includes('height') ? (isMobile ? 4 : 2) : columnWidth}
      left={
        questionRef.includes(`height_feet`) && isMobile
          ? 3
          : questionRef.includes(`height_feet`) && !isMobile
          ? 5
          : 0
      }
    >
      {title && title !== '...' ? <label className='tac'>{title}</label> : ''}
      { customChoice ? <select
        className={style.Dropdown}
        onChange={updateOption}
        defaultValue={"Other"}
      >{defaultOption}{options}</select> : <select
        className={style.Dropdown}
        onChange={updateOption}
        defaultValue={initialValue}
      >{defaultOption}{options}</select> }

      <br />
      {(showInput || customChoice) && (
        <input
          className={style.Dropdown}
          type='text'
          placeholder='Please type your favorite denim brand here'
          defaultValue={(customChoice && initialValue) || ''}
          onChange={(e) => getValue && getValue(e.target.value, questionRef)}
        />
      )}
    </Cell>
  );
}

export default Dropdown;
