const envMap = {
  staging: `https://api-customer.trendsend.dev`,
  stg: `https://api.customer.stg.trendsend.com`,
  prd: `https://api.customer.prd.trendsend.com`,
  development: `http://api.customer.dev.trendsend.com:3000`,
  production: `https://api.customer.trendsend.com`,
}

const dashMap = {
  staging: `https://staging.trendsend.com/members/`,
  stg: `https://stg.trendsend.com/members/`,
  prd: `https://prd.trendsend.com/members/`,
  development: `http://dev.public.trendsend.com/members/`,
  production: `https://www.trendsend.com/members/`
}

const homeMap = {
  staging: `https://staging.trendsend.com`,
  stg: `https://stg.trendsend.com`,
  prd: `https://prd.trendsend.com`,
  development: `http://dev.public.trendsend.com`,
  production: `https://www.trendsend.com`
}

export const TYPEFORM_URL = `https://api.typeform.com/forms/AbPQpW`

export const API_URL = `${envMap[process.env.REACT_APP_ENV]}/api`;

export const API_BASE = envMap[process.env.REACT_APP_ENV];

export const DASHBOARD_URL = dashMap[process.env.REACT_APP_ENV];

export const HOME_URL = homeMap[process.env.REACT_APP_ENV]

export const BASE_PATH = `/members`