import { serverErrors } from "./../../utils/errors";
import api from "../../api";
import history from "./../../History";
import { BASE_PATH } from '../../Config';
import axios from 'axios';
import _ from 'lodash';
import { fetchOrders } from "./OrderAction";

export const REVIEWS_REQUESTED = 'REVIEWS_REQUESTED';
export const REVIEWS_RECEIVED = 'REVIEWS_RECEIVED';
export const REVIEWS_ERROR = 'REVIEWS_ERROR';

export const ADD_REVIEW_TO_ORDER       = 'ADD_REVIEW_TO_ORDER';
export const ADD_REVIEW_TO_ORDER_SUCCESS   = 'ADD_REVIEW_TO_ORDER_SUCCESS';
export const ADD_REVIEW_TO_ORDER_ERROR     = 'ADD_REVIEW_TO_ORDER_ERROR';

export function submitReview(params, data) {
  return function (dispatch, getState) {
    fetch('https://api.yotpo.com/v1/widget/reviews', {
      method: 'post',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => {
        data
          ? api.updateOrder(data.id, data).then(
              (resp) => {
                dispatch({
                  type: ADD_REVIEW_TO_ORDER_SUCCESS,
                  order: resp.data,
                });
                history.push(`${BASE_PATH}/dashboard`);
                dispatch(fetchOrders());
              },
              (errorResp) => {
                dispatch({
                  type: ADD_REVIEW_TO_ORDER_ERROR,
                  error: serverErrors(errorResp),
                });
              }
            )
          : history.push(`${BASE_PATH}`);
      })
      .catch((errorResp) => {});
  };
}