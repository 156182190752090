import React from 'react';
import SubHeader from '../CustomizeOrder/SubHeader';
import style from './ReviewItem.module.scss';
import Radio from 'Radio';
import { Grid } from 'styled-css-grid';
import classnames from 'classnames';
import Discount from 'images/Tile/Icon/Dash-Discount.svg';
import CurrencyFormat from 'react-currency-format';
import * as format from './../utils/formatters';

const ReviewItem = ({
  lineItem,
  hr,
  discount,
  lineItemValue,
  noOptions,
  showSize,
  noIcon,
  action,
  isLastLineItem,
  isDrtg,
  isSuggestedItem,
  isShippingItem,
}) => {
  function setValue(e, props) {
    lineItemValue(e, props);
  }
  let retail_price = parseFloat(lineItem.retail_price);
  let original_price = parseFloat(lineItem.original_price);

  const itemMarkedDown = original_price < retail_price;
  return (
    <div
      className={classnames(
        style.ReviewItem,
        isDrtg
          ? ''
          : !isLastLineItem && {
              [style.ReviewItemHr]: hr,
            }
      )}
    >
      <div
        className={classnames(
          style.ReviewItemWrapper,
          isDrtg && style.Drtg,
          isSuggestedItem && style.SuggestedItem
        )}
        key={lineItem.description}
      >
        {isSuggestedItem && (
          <>
            <h1 className={style.SuggestedItemHeader}>Stylist Suggestion</h1>
            <p className={style.SuggestedItemAnnoucement}>
              Your stylist has selected this pair of shoes from our curated
              assortment on EVEREVE.com. Click to view available sizes and place
              an order.
            </p>
          </>
        )}
        <div
          className={classnames(style.ReviewItemInner, isSuggestedItem && 'df')}
        >
          <img
            width='85px'
            height='auto'
            className={style.ReviewItemImage}
            src={lineItem.image_url}
            alt={lineItem.description}
          />
          <div className={style.ReviewItemInfo}>
            <h3 className={style.ReviewItemDesc}>{lineItem.description}</h3>
            {!isSuggestedItem && (
              <p className={style.ReviewItemDesc}>
                {lineItem.color} -{' '}
                {isShippingItem ? (
                  <span className='fw-semibold c-tertiary ttu'>
                    {' '}
                    {lineItem.exchange_size.substr(
                      0,
                      lineItem.exchange_size.indexOf('-')
                    )}
                  </span>
                ) : (
                  lineItem.size
                )}
              </p>
            )}
            <p className={style.ReviewItemBrand}>{lineItem.brand}</p>

            {!noOptions && isSuggestedItem && (
              <div className='mt'>
                <Radio
                  questionId={lineItem.brand}
                  getValue={(e) => setValue(e, lineItem)}
                  initialValue={action}
                  choices={lineItem.choices}
                  isSuggestedItem={isSuggestedItem}
                />
              </div>
            )}
            {!isSuggestedItem && (
              <CurrencyFormat
                className={classnames(style.ReviewItemPrice, {
                  strikethrough: itemMarkedDown || discount,
                })}
                value={format.dollars(retail_price)}
                displayType={'text'}
                prefix={'$'}
              />
            )}

            {itemMarkedDown && !isSuggestedItem && (
              <span className={`dib ml- ${discount ? '' : 'fw-medium'}`}>
                <CurrencyFormat
                  className={classnames(style.ReviewItemPrice, {
                    strikethrough: discount,
                  })}
                  value={format.dollars(original_price)}
                  displayType={'text'}
                  prefix={'$'}
                />
              </span>
            )}

            {discount && !isSuggestedItem && (
              <span className='dib ml- fw-medium'>
                <CurrencyFormat
                  className={style.ReviewItemPrice}
                  value={format.dollars(
                    original_price - (10 / 100) * original_price
                  )}
                  displayType={'text'}
                  prefix={'$'}
                />
                {noIcon && (
                  <img
                    className={classnames(style.DiscountIcon, 'vam ml-')}
                    src={Discount}
                    alt='Discount Code'
                  />
                )}
              </span>
            )}

            {showSize && !isSuggestedItem && (
              <p className='fw-medium ttu c-gray'>
                Current Size:{' '}
                <span className='fw-semibold c-tertiary'>{lineItem.size}</span>
              </p>
            )}
          </div>
        </div>
        {!noOptions && !isSuggestedItem && (
          <Grid className='mt'>
            <Radio
              questionId={lineItem.brand}
              getValue={(e) => setValue(e, lineItem)}
              width={4}
              initialValue={action}
              choices={lineItem.choices}
              isSuggestedItem={isSuggestedItem}
            />
          </Grid>
        )}
      </div>
      {isDrtg ? <div className={classnames(style.DrtgReviewItemHr)}></div> : ''}
    </div>
  );
};

export default ReviewItem;
