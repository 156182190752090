import React, { Fragment, useState } from 'react';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import Footer from 'Footer';
import style from './Auth.module.scss';
import classnames from 'classnames';
import SectionHeader from 'SectionHeader';
import FormInput from 'FormInput';
import { BASE_PATH } from './../Config.js';
import history from "./../History";
import register from './register.json';
import registerInStore from './registerInStore.json';
import { registerSchema, registerInitialValues } from './schema';
import {
  registerSchemaInStore,
  registerInitialValuesInStore,
} from './schemaInStore';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Cell } from 'styled-css-grid';
import { Formik, Form, Field } from 'formik';
import {
  getReferralCode,
  getCampaignCode,
  getCampaignUrl,
} from '.././utils/cookie';
import { registerCustomer } from './../store/Actions/AuthAction.js';
import _ from 'lodash';
import queryString from 'query-string';
import ReCAPTCHA from "react-google-recaptcha";

function Auth({ match }) {
  const path = match.path.split(`${BASE_PATH}/`)[1];

  const dispatch = useDispatch();
  const errors = useSelector((state) => state.auth.errors);
  const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);

  const isThemeboxSignUp = path === 'register-themebox';
  const inStore = path === 'trendsend-store-signup';

  const authMap = {
    register: inStore ? registerInStore : register,
    'trendsend-store-signup': inStore ? registerInStore : register,
    'register-themebox': inStore ? registerInStore : register,
  };

  const schemaMap = {
    register: inStore ? registerSchemaInStore : registerSchema,
    'trendsend-store-signup': inStore ? registerSchemaInStore : registerSchema,
    'register-themebox': inStore ? registerSchemaInStore : registerSchema,
  };

  const item = authMap[path];

  const vals = queryString.parse(window.location.search);

  if (vals.themebox) {
    dispatch({type: 'THEMEBOX_SELECTED', themebox: vals.themebox});
  }

  function submitForm(values) {
    const customerData = {
      ...values,
      password_confirmation: values.password,
      campaign_code: getCampaignCode(),
      campaign_url: getCampaignUrl(),
      referred_by_code: getReferralCode(),
      internal_note: 'typeform',
      themebox_signup: isThemeboxSignUp,
    };

    if (inStore) {
      customerData.registered_in_store = true;
    }

    dispatch(registerCustomer(customerData));
  }

  return (
    <>
      <NavBar
        text='white'
        background="#003b5c"
        logo={EvereveTrendsendStackedLogoWhite}
        dashboard
        hasLogOut
        centerLogo
      />

      <div className={classnames('group group--slim mt2', [style.Auth])}>

        <SectionHeader
          title={
            inStore
              ? ''
              : isThemeboxSignUp
              ? item.titleThemeboxSignup
              : item.title
          }
          description={
            ''
          }
          style={{ 'font-weight': 'normal' }}
          className="signup-section-header"
        />

        <Formik
          isInitialValid={false}
          initialValues={
            inStore ? registerInitialValuesInStore : registerInitialValues
          }
          validationSchema={schemaMap[path]}
          onSubmit={submitForm}
        >
          {({ isValid }) => (
            <Form>
              <Grid>
                {item.fields.map(
                  ({ label, placeholder, columnWidth, type, name, mask }) => {
                    return (
                      <Fragment>
                        <Cell width={columnWidth} key={name} className="mb2">
                          <Field
                            name={name}
                            type={type}
                            label={label}
                            mask={mask}
                            placeholder={placeholder}
                            component={FormInput}
                          />
                        </Cell>
                        {name === 'email' &&
                        _.find(errors, { code: 'email_invalid' }) ? (
                          <Cell
                            width={columnWidth}
                            key={`${name}-email-taken`}
                            className={classnames(style.SignIn, 'mb c-error')}
                          >
                            Email taken.{' '}
                            <a href='#' onClick={(e) => { e.preventDefault(); history.push(`${BASE_PATH}/login`); }}>
                              Click here to sign in
                            </a>
                          </Cell>
                        ) : (
                          ''
                        )}
                      </Fragment>
                    );
                  }
                )}
                <Cell width={12}>
                  <p className={`already-have-account ${style.SignIn}`}>
                    {`Already have an account? `}
                    <a href='#' onClick={(e) => { e.preventDefault(); history.push(`${BASE_PATH}/login`); }}>
                      Sign In
                    </a>
                  </p>
                </Cell>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={(value) => {
                  setRecaptchaSuccess(value ? true : false);
                }}
              />
              </Grid>
              <Footer
                bgColor={[`#F9423A`, `#FCA09C`]}
                disableButton={!isValid || !recaptchaSuccess}
                buttonType="submit"
                buttonCopy={'Create My Account'}
                createAccountButton
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Auth;
