import {
  LOGIN_SUCCESS,
  LOGIN_SUBMITTED,
  LOGIN_ERROR,
  SESSION_EXPIRED,
  REGISTRATION_REQUESTED,
  REGISTRATION_RECEIVED,
  REGISTRATION_RECEIVED_ERROR,
  SIGN_OUT_REQUESTED,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
  CREATE_NEW_PASSWORD_REQUESTED,
  CREATE_NEW_PASSWORD_RECEIVED,
  CREATE_NEW_PASSWORD_ERROR,
  UPDATE_PASSWORD_REQUESTED,
  UPDATE_PASSWORD_RECEIVED,
  UPDATE_PASSWORD_RECEIVED_ERROR
} from "./../Actions/AuthAction";
import {
  CUSTOMER_DATA_RECEIVED
} from "./../Actions/CustomerAction";
import _ from 'lodash';

let userSession = JSON.parse(localStorage.getItem('session')) || {};

const initState = {
  authenticated: !_.isEmpty(userSession.data),
  session: userSession.config || {},
  isLoading: false,
  errors: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case REGISTRATION_RECEIVED_ERROR:
    case LOGIN_ERROR:
    case SIGN_OUT_ERROR:
    case CREATE_NEW_PASSWORD_ERROR:
    case UPDATE_PASSWORD_RECEIVED_ERROR:
      return { ...state, isLoading: false, errors: action.errors };
    case CUSTOMER_DATA_RECEIVED:
    case LOGIN_SUCCESS:
      return { ...state, isLoading: false, authenticated: true, errors: [] };
    case SIGN_OUT_SUCCESS:
      return { ...state, isLoading: false, authenticated: false, errors: [] };
    case REGISTRATION_REQUESTED:
    case UPDATE_PASSWORD_REQUESTED:
    case CREATE_NEW_PASSWORD_REQUESTED:
    case LOGIN_SUBMITTED:
      return { ...state, isLoading: true, errors: [] };
    case REGISTRATION_RECEIVED:
    case UPDATE_PASSWORD_RECEIVED:
    case CREATE_NEW_PASSWORD_RECEIVED:
      return { ...state, isLoading: false, errors: [] };
    default:
      return state;
  }
}