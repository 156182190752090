import _ from 'lodash';


/* 
* prepareInvoice(invoice <Object>) takes the invoice object provied by the Trendsend API and 
* adds further calculations of the various prices and totals to be displayed 
* to the customer based on a series of conditions which are not reflected by 
* the server at the time of processing. Namely, the invoice receives prospective 
* calculations of the individual item totals and full invoice total based on 
* whether or not the customer has kept 4 or more items, a condition which invokes
* a 10% discount for all items included in the final invoice.
*/
export default function prepareInvoice(invoice, params = {}) {

  // return immediately if invoice object isn't defined or has no line items associated with it
  if (invoice.total === undefined || _.isEmpty(invoice.invoice_line_items)) return invoice;
  // is the customer keeping 4 or more items?
  invoice.fourItems = _.size(_.filter(invoice.invoice_line_items, {claimed_kept: true, exchange_size: ''})) + _.size(_.filter(invoice.invoice_line_items, l => l.exchange_size !== '')) >= 4;
  // does the customer have a credit balance that can be applied to this order?
  invoice.displayCredit = parseFloat(invoice.credit_amount) > 0;
  // has this order received any kind of discount from a coupons or discount code?
  invoice.displayDiscount = parseFloat(invoice.discount_amount) > 0;
  // is a gift card balance being applied to this order?
  invoice.displayGiftCardCharge = parseFloat(invoice.gift_card_amount_due) > 0;
  // what is the tax rate to be applied for this customer (based on location)
  invoice.taxRate = parseFloat(invoice.tax_total) / (parseFloat(invoice.total) - parseFloat(invoice.tax_total));
  // the total price of the order after accounting for all coupons/discount codes and the 4+ item discount if applicable
  invoice.totalBeforeTax = (parseFloat(invoice.original_subtotal) * (invoice.fourItems ? 0.9 : 1)) - parseFloat(invoice.discount_amount);
  invoice.totalBeforeTax = invoice.totalBeforeTax < 0 ? 0.00 : invoice.totalBeforeTax;
  // the total tax owed on this order
  invoice.tax = invoice.totalBeforeTax * invoice.taxRate;
  // the total discounted price with tax
  invoice.totalWithTax = invoice.totalBeforeTax + invoice.tax;
  // the amount to be applied from a gift card's balance if one was applied
  invoice.totalGiftCardAmountUsed = (parseFloat(invoice.gift_card_amount_due) > invoice.totalWithTax ? invoice.totalWithTax : parseFloat(invoice.gift_card_amount_due));
  // the total discounted price with tax minus any gift card and/or credit balance applied (the final amount the customer will be billed for) 
  invoice.totalDue = invoice.totalWithTax - parseFloat(invoice.credit_amount) - parseFloat(invoice.totalGiftCardAmountUsed);
  // if the final total is less than zero, reset to zero (the remaining credit will be applied to their account behind the scenes)
  invoice.totalDue = invoice.totalDue < 0 ? 0.00 : invoice.totalDue;

  _.each(invoice.invoice_line_items, (invoiceLineItem) => {
    // has the item been marked down from its MSRP?
    invoiceLineItem.itemMarkedDown = parseFloat(invoiceLineItem.original_price) < parseFloat(invoiceLineItem.retail_price);
    // are any coupons or discount codes being applied?
    invoiceLineItem.discountApplied = parseFloat(invoiceLineItem.discounted_price) < parseFloat(invoiceLineItem.original_price);
    // the new marked down/discounted price
    invoiceLineItem.discountAmount = (parseFloat(invoiceLineItem.original_price) / parseFloat(invoice.original_subtotal)) * parseFloat(invoice.discount_amount);
    // the discounted price (10% off) if the customer is keeping 4 or more items
    invoiceLineItem.fourItemDiscountedPrice = parseFloat(invoiceLineItem.original_price) * (invoice.fourItems ? 0.9 : 1);
    // the fully discounted final price after accounting for mark downs, discounts, and the 4+ item discount
    invoiceLineItem.couponDiscountedPrice = parseFloat(invoiceLineItem.fourItemDiscountedPrice) - invoiceLineItem.discountAmount;
  });
  return invoice;
}