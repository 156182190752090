import React, { Component, Fragment } from 'react';
import style from "./Dashboard.module.scss";
import history from "../History";
import { useSelector } from "react-redux";
// components
import Core from "./Core.jsx";
import Welcome from "./Welcome.jsx";
import SecondaryTile from "./SecondaryTile.jsx";
import NavBar from "../NavBar";
import Footer from 'Footer';
// images
import StyleProfileImg from "../images/Tile/Icon/StyleProfile.svg";
import SubscribeImg from "../images/Tile/Icon/Subscription.svg";
import ReferImg from "../images/Tile/Icon/ReferAFriend.svg";
import SettingsImg from "../images/Tile/Icon/AccountMangement.svg";
import EvereveTrendsendStackedLogoWhite from "../images/EVEREVE-TrensendStackedLogoWhite.png";
import logOut from "../images/icon_logout.svg";
import { BASE_PATH } from "./../Config";
import _ from 'lodash';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

function Dashboard() {

  const customer = useSelector(reduxState => reduxState.customer.data);
  const mostRecentOrderDate = useSelector((reduxState) =>
    _.chain(reduxState)
      .get("orders.orders", [])
      .filter((f) => f.subscription_type !== "subscription_on_demand")
      .map((o) => new Date(o.expected_delivery_date))
      .orderBy()
      .reverse()
      .get("0")
      .value()
  );
  const currentOrder = useSelector(state => state.orders.currentOrder);
  const subscription = useSelector(state => state.customer.data && state.customer.data.subscription_type);
  const isDressingRoomToGoOrder = useSelector(state => state.orders.currentOrder && state.orders.currentOrder.customer_pick_up);
  
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  let subscriptionType = customer.subscription_type || '';
  
  let estimatedDeliveryDate = currentOrder.id ? currentOrder.expected_delivery_date : (!_.isNaN(parseInt(subscriptionType.split('_')[1])) && moment(mostRecentOrderDate).add(parseInt(subscriptionType.split('_')[1]) + 5, 'days'));

  const customerSkipDays = useSelector((state) =>
    _.get(state, 'customer.data.skip_days')
  );

  const boxCurrentlySkipped = customerSkipDays && customerSkipDays !== 0;

  const estimatedDate = currentOrder.id
    ? currentOrder.expected_delivery_date
    : boxCurrentlySkipped
    ? moment(estimatedDeliveryDate)
        .add(parseInt(subscriptionType.split('_')[1]), 'days')
        .format('MM/DD/YYYY')
    : estimatedDeliveryDate;

  function showOrdeArrrivalDate() {
    switch (subscription) {
      case "subscription_30_day":
      case "subscription_60_day":
      case "subscription_90_day":
        return `Your next box is scheduled to arrive ${moment(estimatedDate).format('MM/DD/YYYY')}`;
      case "subscription_on_demand":
        return "Receive regular wardrobe refreshes shipped to your door.";
    }
  }

  function showFrequency() {
    switch (subscription) {
      case "subscription_30_day":
        return <span><div>Subscription</div><div className={style.SubscriptionType}>Monthly</div></span>;
      case "subscription_60_day":
        return <span><div>Subscription</div><div className={style.SubscriptionType}>Every Other Month</div></span>;
      case "subscription_90_day":
        return <span><div>Subscription</div><div className={style.SubscriptionType}>Quarterly</div></span>;
      case "subscription_on_demand":
        return <span>Start a Trendsend Box Subscription</span>;
    }
  }

  function orderTileState() {
    if (isDressingRoomToGoOrder === true) {
      return (
        <Fragment>
          <Core isDressingRoomToGo={true} />
        </Fragment>
      );
    } else if (isDressingRoomToGoOrder === false) {
      return (
        <Fragment>
          <Core />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Core isDressingRoomToGo={true} />
          <Core />
        </Fragment>
      );
    }
  }

  function handleTile(path) {
    history.push(`${BASE_PATH}${path}`);
  }

  return (
    <div>
      <NavBar
        text='white'
        background="#003b5c"
        logo={EvereveTrendsendStackedLogoWhite}
        logOut={logOut}
        dashboard="true"
      />
      
      <div className="group">
      
        <Welcome />
        
        <div className={style.dashboardWrapper}>
          <div className={style.DashboardGrid}>
            {orderTileState()}
            <SecondaryTile
              image={SubscribeImg}
              h3={showFrequency()}
              h4={
                !_.isEmpty(currentOrder) &&
                subscription !== "subscription_on_demand"
                  ? ""
                  : showOrdeArrrivalDate()
              }
              handle={() => handleTile("/manage/subscription")}
              styleclass1={style.c3}
            />
            <SecondaryTile
              image={StyleProfileImg}
              h3="Update Style Profile"
              h4="Rate new arrivals and keep your preferences up to date for your stylist."
              handle={() => handleTile("/styleprofile")}
              styleclass1={style.c4}
            />
            <SecondaryTile
              image={ReferImg}
              h3="Get 20% Off"
              h4="Refer a friend and save on your next Trendsend."
              handle={() => handleTile("/refer/friend")}
              styleclass1={style.c5}
            />
            <SecondaryTile
              image={SettingsImg}
              h3={isMobile ? <><div>Account Management</div><div>& Purchase History</div></> : `Account Management & Purchase History`}
              h4={`Update your payment and shipping details, view your purchase history and more.`}
              handle={() => handleTile("/account/management")}
              styleclass1={style.c6}
            />
          </div>
        </div>
      </div>
      <Footer bgColor={[`#003b5c`]} isDashboard={true} />
    </div>
  );

}

export default Dashboard;