import queryString from 'query-string';

// Yanked from http://stackoverflow.com/questions/5639346/shortest-function-for-reading-a-cookie-in-javascript
export function getCookie(name) {
  var cookies, c, C;

  if(cookies){ return cookies[name]; }

  c = document.cookie.split('; ');
  cookies = {};

  for(var i=c.length-1; i>=0; i--){
    C = c[i].split('=');
    cookies[C[0]] = C.splice(1).join('=');
  }

  return cookies[name];
}

function setCookie(name, value) {
  document.cookie = `${name}=${value || ''}`;
}

export function getCampaignCode() {
  return localStorage.getItem('campaign_code') || getCookie('campaign_code');
}

export function getCampaignUrl() {
  return localStorage.getItem('campaign_url') || getCookie('campaign_url');
}

export function getReferralCode() {
  // Fall back on URL query string in off chance that user has cookies disabled
  return getCookie('referral') || queryString.parse(window.location.search).referral  || localStorage.getItem('referral') || null;
}

export function clearCampaignVals() {
  localStorage.removeItem('campaign_code');
  localStorage.removeItem('campaign_url');
  localStorage.removeItem('referral');
}

export function initializeCookies() {
  const hashParams = queryString.parse(window.location.hash);
  const urlParams = queryString.parse(window.location.search);

  if (!localStorage.getItem('campaign_code') && (urlParams.utm_campaign || hashParams.utm_campaign)){ localStorage.setItem('campaign_code', urlParams.utm_campaign || hashParams.utm_campaign)};
  if (!localStorage.getItem('campaign_url')){ localStorage.setItem('campaign_url', window.location.href)};
  if (!localStorage.getItem('referral')){ localStorage.setItem('referral', urlParams.referral)};

  if (!getCookie('campaign_code')){ setCookie('campaign_code', urlParams.utm_campaign || hashParams.utm_campaign)};
  if (!getCookie('campaign_url')){ setCookie('campaign_url', window.location.href)};
  if (!getCookie('referral')){ setCookie('referral', urlParams.referral)};
}
