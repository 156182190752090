import React from "react";
import style from "./ProgressBar.module.scss";


function ProgressBar({
  currentQuestion,
  totalQuestions,
  currentStep,
  width,
  color,
}) {
  const stepStyle = {
    "--progress-steps": totalQuestions
  };

  const ProgressWidth = {
    "--progress-width": currentQuestion
  };

  const uiCOlor = {
    "--ui-color": color,
  }

  return (
    <div className={style.ProgressBar} style={stepStyle}>
      <div className={style.ProgressBarRange} style={ProgressWidth} />
      <ul className={style.ProgressBarWrapper} style={uiCOlor}>

      </ul>
    </div>
  );
}

export default ProgressBar;
