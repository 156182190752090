import React from 'react';
import "./StyleProfile.Module.scss";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Check from "../images/Tile/StyleProfile/Check.svg";


function StyleProfileOption({ percentage, newContent, image, title, handle }) {

    let progressIndicator;

    if (newContent) {
        progressIndicator =
            <div className="badge">
                <div className="badgeCTA">Just in!</div>
            </div>;
    } else {
        if (percentage === 100) {
            progressIndicator =
                <CircularProgressbarWithChildren
                    value={percentage}
                    styles={buildStyles({
                        strokeLinecap: "butt",
                        pathColor: '#003b5c',
                    })}
                >
                    <img style={{ width: 40 }} src={Check} alt="progress complete" />
                </CircularProgressbarWithChildren>;
        } else {
            progressIndicator =
                <CircularProgressbar
                    value={percentage}
                    styles={buildStyles({
                        strokeLinecap: "butt",
                        pathColor: '#003b5c'
                    })}
                />;
        }
    }

    return (
        <div className="optionCard" role="button" onClick={handle}>
            <div className="optionImgDiv">
                <img src={image} alt="" className="optionImg" />
            </div>
            <div className="optionText">
                <h3>{title}</h3>
            </div>
            <div className="optionProgress">
                {progressIndicator}
            </div>
        </div>
    );

}



export default StyleProfileOption;