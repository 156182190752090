import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import style from '../Button/Button.module.scss';
import themeStyle from '../ThemeBox/ThemeBox.module.scss';
import { Cell } from 'styled-css-grid';
import { useMediaQuery } from 'react-responsive';
import { fetchSuggestedItemClick } from '../store/Actions/OrderAction';
import _ from 'lodash';

const Radio = ({
  choices,
  title,
  width,
  getValue,
  initialValue,
  kind,
  questionId,
  questionRef,
  multiSelect,
  supersized,
  deselectable,
  hasThemeboxes,
  match,
  isSuggestedItem,
  isExchangeModal,
}) => {
  let deselect = (event) => {
    let val = event.target.value;
    let checked = event.target.checked;
    if (checked && deselectable) {
      getValue('', questionRef);
    }
    console.log('handleClick checked ', checked, 'val ', val);
  };
  questionId = questionRef || _.uniqueId('radio_');

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.stopPropagation();

    let val = event.target.value;
    let description = event.target.title;
    let checked = event.target.checked;
    let newVal = val;
    if (multiSelect) {
      newVal = _.isArray(initialValue) ? initialValue : [val];
      if (checked) {
        newVal = _.includes(newVal, val) ? newVal : _.concat(newVal, val);
      } else {
        newVal = _.without(newVal, val);
      }
    }
    console.log(
      'handleChange checked ',
      checked,
      'val ',
      val,
      'newVal ',
      newVal
    );
    
    getValue(newVal, questionRef);
  };

  const suggestedItemClick = (website_url, id) => {
    const itemUrl = website_url.replace('/p/', '/');
    window.open(`${itemUrl}-c`);
    id && dispatch(fetchSuggestedItemClick(id));
  };

  const hasImage = _.get(choices, '0.attachment.href');

  const aspectRatio =
    kind === `picture_choice` || !questionRef === 'colors_to_avoid'
      ? !supersized
        ? { '--aspect-width': '1', '--aspect-height': '1' }
        : { '--aspect-width': '2', '--aspect-height': '3' }
      : null;

  const isMobile = useMediaQuery({ query: '(max-width: 512px)' });

  const styles = classNames([
    [style.Button],
    [style.ButtonRadio],
    match &&
      match.path === '/members/edit-profile/:section' &&
      questionRef.includes(`common_fit_issues`) &&
      !isMobile,
    {
      [style.AspectRatio]: aspectRatio,
    },
    {
      [style.ButtonPreviewImage]: hasImage,
    },
  ]);

  const previewTitle = classNames({
    [style.ButtonPreviewTitle]: hasImage,
  });

  const setColumnWidth = function () {
    let columns = 12;
    const deviceWidth = window.outerWidth;

    if (kind === `multiple_choice`) {
      if (
        questionRef.includes(`top_size`) ||
        questionRef.includes(`jeans_size`) ||
        questionRef.includes(`bra_size`) ||
        questionRef.includes(`dress_size`) ||
        questionRef.includes(`skirt_bottoms_size`) ||
        questionRef.includes(`_style_frequency`) ||
        questionRef.includes(`_styled_looks_frequency`) ||
        questionRef.includes(`shoe_size`) ||
        questionRef.includes(`prefer_tops_to_fit`) ||
        questionRef.includes(`prefer_to_conceal`) ||
        questionRef.includes(`prefer_to_highlight`) ||
        questionRef.includes(`proportions_torso`) ||
        questionRef.includes(`proportions_shoulders`) ||
        questionRef.includes(`proportions_arms`) ||
        questionRef.includes(`proportions_hips`) ||
        questionRef.includes(`proportions_legs`) ||
        questionRef.includes(`proportions_butt`) ||
        questionRef.includes(`typical_spend_amount_denim`) ||
        questionRef.includes(`typical_spend_amount_tops`) ||
        questionRef.includes(`typical_spend_amount_sweaters`) ||
        questionRef.includes(`typical_spend_amount_dresses`) ||
        questionRef.includes(`typical_spend_amount_bottoms`) ||
        questionRef.includes(`typical_spend_amount_jackets`) ||
        questionRef.includes(`jeans_best_fit_brand`) ||
        questionRef.includes(`birthday_month`)
      ) {
        columns = 4;
        if (deviceWidth < 512) {
          columns = 4;
        }
      } else if (
        questionRef.includes(`send_jewelry_answers`) ||
        questionRef.includes(`wear_earings`) ||
        questionRef.includes(`preferred_metal_colo`) ||
        questionRef.includes(`is_mom_answers`) ||
        questionRef.includes(`is_pregnant`)
      ) {
        columns = 6;
      }
    } else if (kind === `picture_choice`) {
      columns = 6;
      if (deviceWidth < 512) {
        columns = 6;
      }
      if (
        questionRef.includes(`theme_boxes`) ||
        questionRef.includes(`proportions_waist`)
      ) {
        columns = 4;
        if (deviceWidth < 512) {
          columns = 4;
        }
      }
      if (questionRef.includes(`theme_boxes`)) {
        columns = 12;
        if (deviceWidth >= 768 && deviceWidth < 1024) {
          columns = 4;
        }
        if (deviceWidth >= 1024) {
          columns = 6;
        }
      }
      if (questionRef.includes(`colors_to_avoid`)) {
        columns = 4;
      }
    }
    return [columns];
  };

  const sortedThemeboxes =
    hasThemeboxes &&
    choices.sort((a, b) => (a.position_number > b.position_number ? 1 : -1));

  const publishedThemeboxes = hasThemeboxes === true
    ? sortedThemeboxes.filter((themebox) => themebox.publish === true)
    : choices;
    
  return (
    <Fragment>
      {publishedThemeboxes.map(
        ({
          id,
          label,
          attachment,
          value,
          theme,
          description,
          position_number,
          website_url,
          publish,
        }) => {
          const getPreviewImage = {
            backgroundImage: `url(${
              (hasThemeboxes && attachment) || (attachment && attachment.href)
            })`,
          };

          // display theme box title and description

          let displayLabel = label;

          const editedLabel = isExchangeModal ? `${label},${value}` : value || label;

          label = value || label;

          {/* console.log(editedLabel, label, value); */}

          const labelStyles = { ...getPreviewImage, ...aspectRatio };
          const setId = !_.isUndefined(id) ? id : _.uniqueId('input_id_');
          let sizesClass;
          let CheckboxClass;

          if (
            questionId === `top_size` ||
            questionId === `jeans_size` ||
            questionId === `bra_size_number` ||
            questionId === `bra_size_letter`
          ) {
            sizesClass = style.Sizes;
          }

          if (questionId === `favorite_stores_where`) {
            CheckboxClass = style.CustomCheckbox;
          }

          return (
            <Cell key={setId} width={width ? width : setColumnWidth()}>
              {multiSelect ? (
                !CheckboxClass ? (
                  <input
                    type="checkbox"
                    name={questionId}
                    id={setId}
                    className={style.ButtonInput}
                    onChange={handleChange}
                    value={label}
                    checked={
                      multiSelect
                        ? _.includes(initialValue, label)
                        : initialValue === label
                    }
                  />
                ) : (
                  ''
                )
              ) : (
                <input
                  type="radio"
                  name={questionId}
                  id={setId}
                  className={classNames(
                    hasImage
                      ? (style.CheckMarkButton, style.ButtonInput)
                      : style.ButtonInput
                  )}
                  onChange={
                    isSuggestedItem
                      ? () => suggestedItemClick(website_url, id)
                      : handleChange
                  }
                  value={editedLabel}
                  checked={
                    multiSelect
                      ? _.includes(initialValue, editedLabel)
                      : initialValue === editedLabel
                  }
                  onClick={deselect}
                />
              )}

              {(hasThemeboxes && attachment) ||
              (attachment && attachment.href) ? (
                <div
                  className={classNames(
                    style.ButtonPreviewImage,
                    questionId === `colors_to_avoid`
                      ? style.ColorPreviewImage
                      : ''
                  )}
                >
                  <label
                    htmlFor={setId}
                    className={classNames(styles, 'nb nbr')}
                    style={labelStyles}
                  ></label>
                  {!questionId === `colors_to_avoid` && (
                    <span className={classNames(style.CheckMark)}></span>
                  )}
                  {!theme && (
                    <span className={previewTitle}>{displayLabel}</span>
                  )}
                </div>
              ) : (
                <label
                  htmlFor={setId}
                  className={classNames(
                    CheckboxClass ? CheckboxClass : styles,
                    sizesClass,
                    CheckboxClass,
                    isSuggestedItem && style.SuggestedItem
                  )}
                  style={labelStyles}
                >
                  {CheckboxClass ? (
                    <Fragment>
                      <input
                        type="checkbox"
                        name={questionId}
                        id={setId}
                        className={classNames(style.ButtonInput)}
                        onChange={handleChange}
                        value={label}
                        checked={
                          multiSelect
                            ? _.includes(initialValue, label)
                            : initialValue === label
                        }
                      />
                      <span className={classNames(style.CheckMark)}></span>
                    </Fragment>
                  ) : (
                    ''
                  )}
                  <span className={previewTitle}>
                    {!theme ? displayLabel : ''}
                  </span>
                </label>
              )}
              {description && !theme && (
                <p className="h4 mt tac">{description}</p>
              )}
              {theme && (
                <div>
                  <p className={themeStyle.ThemeTitle}>{theme}</p>
                  <p className={themeStyle.ThemeDescription}>{description}</p>
                </div>
              )}
            </Cell>
          );
        }
      )}
    </Fragment>
  );
};

export default Radio;
