import React from 'react';
import "./OrderHistory.Module.scss";
import NoOrder from "../images/Tile/OrderHistory/NoOrderHistory.svg";


function NoOrderHistory() {
    
    return (
        <div className="noOrderHistory">
            <div className="noOrderImgDiv">
                <img src={NoOrder} alt="" className="noOrderImg"/>
            </div>
            <div className="noOrderTextDiv">
                <p>You do not have any previous orders.</p>
            </div>
            
        </div>
    );
}

export default NoOrderHistory;