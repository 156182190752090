import React, { useState, useEffect, Fragment, Component } from "react";
import { withRouter } from "react-router-dom";
import { getQuestionSelector } from "QuestionSelector";
import style from "../Quiz/Quiz.module.scss";
import lstyle from "./Longform.module.scss";
import Footer from "Footer";
import Header from '../Dashboard/Header';
import ProgressBar from "ProgressBar";
import { Grid, Cell } from "styled-css-grid";
import history from "../History";
import classnames from "classnames";
import _ from "lodash";
import NavBar from "NavBar";
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import logOut from "../images/icon_logout.svg";
import profile from "../images/icon_account.svg";
import { useDispatch, useSelector, connect } from "react-redux";
import { BASE_PATH } from "./../Config";
import { useMediaQuery } from 'react-responsive';

import {
  fetchStyleProfile,
  updateStyleProfile,
  updateResponse,
} from "../store/Actions/QuestionAction";
import Loading from "Loading";

const onboardingSections = {
  physical: [`#FFC600`, `#FFE27F`],
  style: [`#F9423A`, `#FCA09C`],
  lifestyle: [`#FA86C8`, `#FDC3E4`],
  preferences: [`#CE0058`, `#E67FAB`],
};

function Longform({ match, location }) {
  const dispatch = useDispatch();
  let fields = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.section === match.params.section)
        .filter(
          (f) =>
            !_.includes(
              ["match_with_stylist_2", "subscription_type_group"],
              f.ref
            )
        )
        .filter((f) => f.type !== "statement")
        .value()
  );

  fields = _.chain(fields)
    .map((f) =>
      f.type === "group"
        ? [f].concat(
            _.map(f.properties.fields, (f) => _.merge(f, { child: true }))
          )
        : f
    )
    .flatten()
    .value();

  const responses = useSelector((state) => state.questions.responses);
  const isLoading = useSelector((state) => state.questions.isLoading);
  const [button, setButton] = useState(false);

  let footerColor = "";

  const setHeaderTitle = () => {
    let header;
    switch (location.pathname) {
      case '/members/edit-profile/physical':
        return header = 'Perfect Fit';
        break;
      case '/members/edit-profile/style':
        return header = 'Style';
        break;
      case '/members/edit-profile/lifestyle':
        return header = 'Lifestyle';
        break;
      case '/members/edit-profile/preferences':
        return header = 'Preferences';
        break;

      default:
        return header = '';
    }
  }

  useEffect(() => {
    dispatch(fetchStyleProfile('master'));
    if (!isLoading) {
      // setIsLoading(false);
    }
  }, [dispatch, fields.length]);

  let handleSave = () => {
    dispatch(updateStyleProfile(responses));
    history.push(`${BASE_PATH}/styleprofile`);
  };

  let val = (value, ref) => {
    if (value && value.length > 1) {
      setButton(true);
    } else {
      setButton(false);
    }
    dispatch(updateResponse(ref, value));
  };

  return (
    <div className={classnames(style.Quiz)}>
      <NavBar
        background="#003b5c"
        text="white"
        logo={EvereveTrendsendStackedLogoWhite}
        profile={profile}
        logOut={logOut}
      />
      <div className="group group--slim mt2 mb2 longform">
        <Fragment>
          {setHeaderTitle() !== '' && (
            <Header header={setHeaderTitle()} TextAlignCenter FontSizeLarge />
          )}
          <Grid columns={12} gap="16px" justifyContent="center">
            {fields.map((item) => {
              const Question = getQuestionSelector(item.type);
              const section = item.section;
              footerColor = onboardingSections[section];

              const formattedTitle = item.title.replace(`$text`, ``);
              const isTitleNotBlank = item.title.replace(/\s/g, '').length > 0;

              return (
                <Fragment key={item.id}>
                  {!item.child && item.id !== `nYZtNuKKsChw` && (
                    <Cell width={12}>
                      <h3 className="h2 ff-heading mt2 pt mb tac">
                        {formattedTitle}
                      </h3>
                      {item.properties &&
                        item.properties.description &&
                        item.properties.description !==
                          'Select as many as apply' &&
                        item.type !== `long_text` && (
                          <h3 className="tac">{item.properties.description}</h3>
                        )}
                    </Cell>
                  )}

                  {item.child &&
                    item.type !== `short_text` &&
                    item.type !== `dropdown` &&
                    item.type !== `statement` &&
                    isTitleNotBlank && (
                      <Cell width={12}>
                        <h3 className="h4 ff-body ttu pt mb0 tac">
                          {item.title}
                        </h3>
                      </Cell>
                    )}

                  {item.attachment && item.attachment.href && (
                    <Cell width={12}>
                      <img
                        className="db mla mra mt2 mb2"
                        width="300px"
                        height="auto"
                        src={item.attachment.href}
                        alt={item.title}
                      />
                    </Cell>
                  )}

                  {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                    <Question
                      label={item.title}
                      choices={item.properties && item.properties.choices}
                      placeholder={
                        item.properties && item.properties.description
                      }
                      getValue={val}
                      initialValue={responses[item.ref]}
                      rotationDegree={responses.full_body_image_rotation}
                      kind={item.type}
                      title={item.title}
                      form={{}}
                      multiSelect={
                        item.properties &&
                        item.properties.allow_multiple_selection
                      }
                      questionRef={item.ref}
                      questionId={item.id}
                      supersized={item.properties && item.properties.supersized}
                      parentComponentName={'LongForm'}
                      match={match}
                    />
                  )}
                </Fragment>
              );
            })}

            <Cell width={12}>
              <Footer
                bgColor={footerColor}
                buttonType="submit"
                footerStep={handleSave}
                buttonCopy={`Save Changes`}
              />
            </Cell>
          </Grid>
        </Fragment>
      </div>
    </div>
  );
}

export default Longform;
