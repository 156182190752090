import React from 'react';


function ReferralItem({name}) {
    return <li className="referralItem"><p>{name}</p></li>
}


function ReferralList({referrals}) {
    const referralItems = referrals.map( referral => 
        <ReferralItem key={referral.id} name={referral.customer_name}/>
    );
    return (
        <ul>
            {referralItems}
        </ul>
    );
}

export default ReferralList;