import React, {PropTypes} from "react";
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

export function address(a) {
  if(!a) { return ''; }

  return (
    <span>
      {a.name}<br/>
      {a.line_1}{a.line_2 ? ` ${a.line_2}` : ''}<br/>
      {a.city}, {a.state} {a.postal_code}
    </span>
  );
}

export function inlineAddress(a) {
  return a
    ? `${a.line_1} ${a.line_2 || ''}, ${a.city}, ${a.state} ${a.postal_code}`
    : '';
}

export function fullName(obj) {
  return obj ? `${obj.first_name} ${obj.last_name}` : '';
}

export function longDate(date) {
  return date ? moment(date).format('MM/D/YYYY') : '';
}

export function shortDate(date) {
  return date ? moment(date).format('MM/DD/YYYY') : '';
}

export function prettyDate(date) {
  return date ? moment(date).format('dddd, MMMM D, YYYY') : '';
}

export function datetime(dt) {
  return dt ? moment(dt).format('MM/D/YYYY, h:mm:ss a') : '';
}

export function dollars(amount) {
  return numeral(amount) >= 0 ? numeral(amount).format('$0,0.00') : '';
}

export function number(amount) {
  return amount ? numeral(amount).format('0,0') : '';
}

export function formatPhone(number) {
  return number ? number.replace(/[^0-9]/g, '') : '';
}

export function phoneOutput(number) {
  return number ? number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : '';
}
