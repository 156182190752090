import React from "react";
import Icon from "Icon";
import styles from './SectionHeader.module.scss';
import classnames from 'classnames';

function SectionHeader({ title, description, hr, hrColor, style, className }) {
  const formattedTitle = title && title.replace(`$carousel`, ``);
  return (
    <div className={classnames(styles.SectionHeader, `mt2 mb2 ${className}`)} style={style || {}}>
      <h2 className="mt2">{formattedTitle}</h2>
      <p>{description}</p>
    </div>
  );
}

export default SectionHeader;
