import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'styled-css-grid';
import Radio from 'Radio';
import { choices } from './themebox_choices';
import style from './ThemeBox.module.scss';
import { updateCurrentOrder } from './../store/Actions/OrderAction';
import { updateResponse } from './../store/Actions/QuestionAction';
import { loadThemeboxes } from './../store/Actions/ThemeboxActions';
import _ from 'lodash';

function ThemeBoxItems({ order, themeboxes }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadThemeboxes());
  }, [dispatch]);

  return (
    <div className={style.ThemeOptions}>
      <Grid columns={12} gap="16px" justifyContent="center">
        <Radio
          choices={themeboxes.length ? themeboxes : choices}
          initialValue={order.themebox}
          getValue={(val) => {
            const themeboxDescription = val
              ? _.filter(themeboxes, (obj) => obj.theme === `${val}`)
              : '';

            dispatch(
              updateCurrentOrder({
                themebox: val,
                themebox_description: val
                  ? themeboxDescription[0].description
                  : '',
              })
            );
            dispatch({type: 'THEMEBOX_SELECTED', themebox: val});
            dispatch(updateResponse('themebox', val));
            dispatch(
              updateResponse(
                'themebox_description',
                val ? themeboxDescription[0].description : ''
              )
            );
          }}
          questionRef="theme_boxes"
          kind="picture_choice"
          deselectable="true"
          hasThemeboxes={themeboxes.length}
        />
      </Grid>
    </div>
  );
}

export default ThemeBoxItems;
