import React from 'react';
import Header from '../Dashboard/Header';
import Footer from 'Footer';
import { BASE_PATH } from './../Config';
import history from '../History';
import styles from './StylistNote.module.scss';
import { useSelector } from 'react-redux';
import { Grid, Cell } from 'styled-css-grid';
import _ from 'lodash';

function Review() {
  let itemCount = 0;
  let buildLineItems = [];
  let lineItemCollection = [];

  const order = useSelector((state) => _.get(state, 'orders.currentOrder', {}));

  const outfits = _.flatten(
    _.map(order.outfits, (outfit) => {
      return outfit;
    })
  );

  _.each(order.outfits, (outfit, i) => {
    outfit.title = `OUTFIT ${i + 1}`;
    buildLineItems.push(outfit.line_items);
  });

  buildLineItems.map((item, index) => {
    item.reduce((o, v) => {
      v.choices = order.customer_pick_up
        ? [
            { id: `return-${v.id}`, label: 'Return' },
            { id: `keep-${v.id}`, label: 'Keep' },
          ]
        : [
            { id: `return-${v.id}`, label: 'Return' },
            { id: `exchange-${v.id}`, label: 'Exchange' },
            { id: `keep-${v.id}`, label: 'Keep' },
          ];
      return lineItemCollection.push({
        ...v,
        label: '',
        discounted: false,
      });
    }, {});
    return (itemCount = lineItemCollection.length);
  });

  return (
    <>
      <div className='group group--slim' style={{ paddingBottom: `30vh` }}>
        <Grid columns={1} className={styles.StylistNote}>
          <Header
            header='Your Outfits and Stylist Note'
            subheader={!_.isEmpty(order.stylist_note) && order.stylist_note}
            TextAlignCenter
            FontSizeLarge
            SansSerif
          />

          {outfits.map((outfit, i) => {
            return (
              <>
                <div className={styles.OutfitTitle}>{outfit.title}</div>
                {outfit.stylist_note && <div>{outfit.stylist_note}</div>}
                <Grid columns={4}>
                  {outfit.line_items.map((lineItem, index) => {
                    return (
                      <Cell key={index}>
                        <img
                          width='100%'
                          height='auto'
                          src={lineItem.image_url}
                        />
                        <p className={styles.LineItemDescription}>
                          {lineItem.description}
                        </p>
                        <p className='pb'>{lineItem.brand}</p>
                      </Cell>
                    );
                  })}
                </Grid>
              </>
            );
          })}
        </Grid>

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType='submit'
          buttonCopy='Continue to Checkout'
          footerStep={() => {
            history.push(`${BASE_PATH}/checkout/review`);
          }}
        />
      </div>
    </>
  );
}

export default Review;
