import React, { useEffect, useState } from "react";
import NavBar from "NavBar";
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import { Grid, Cell } from "styled-css-grid";
import SwipeableCard from "SwipeableCard";
import ProgressBar from "ProgressBar";
import Header from "../Dashboard/Header";
import Footer from "Footer";
import style from "../Quiz/Quiz.module.scss";
import _ from "lodash";
import { fetchStyleProfile, updateResponse, updateStyleProfile, SHUFFLE_COMPLETE } from "../store/Actions/QuestionAction";
import { useDispatch, useSelector } from "react-redux";
import history from "../History";
import { BASE_PATH } from "./../Config";
import * as tracking from './../utils/gt-tracking';
import { useMediaQuery } from 'react-responsive';


function StyleQuiz({ title, nextStep, currentStep, width, currentQuestion, totalQuestions }) {

  const dispatch = useDispatch();
  const getFields = useSelector(state => state.questions.data.shuffle);
  const getResponses = useSelector(state => state.questions.responses) || {};
  const authenticated = useSelector(state => state.auth.authenticated) || false;
  const fields = !_.isEmpty(getFields) && getFields;
  const cards = [];
  const [shuffleIndex, setShuffleIndex] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })


  for (var field in fields) {
    cards.push({ id: field, image: fields[field] })
  }

  let handleStep = () => {
    tracking.push({ eventCategory: 'onboarding', eventAction: 'style shuffle', eventLabel: 'completed' });
    dispatch({type: SHUFFLE_COMPLETE});
    history.push(`${BASE_PATH}/quiz/${nextStep}`);
  }

  let getValue = (id, val) => {
    setShuffleIndex(shuffleIndex + 1);
    dispatch(authenticated ? updateStyleProfile({ [id]: val }) : updateResponse(id, val));
    if (shuffleIndex + 1 === cards.length) {
      dispatch({type: SHUFFLE_COMPLETE});
      history.push(`${BASE_PATH}/quiz/${nextStep}`);
    }
  }

  useEffect(() => {
    dispatch(fetchStyleProfile());
  }, [dispatch]);

  return (
    <div className={style.Quiz}>
      <NavBar
        text='white'
        background='#003b5c'
        logo={EvereveTrendsendStackedLogoWhite}
        dashboard
        hasLogOut
        centerLogo
      />
      <div className='group group--slim mt2 mb2'>
        <div className='pl pr'>
          <ProgressBar
            currentStep='style'
            width={0}
            color={'#003b5c'}
            currentStep={currentStep}
            width={width}
            currentQuestion={currentQuestion}
            totalQuestions={totalQuestions}
          />
        </div>

        <Header
          header={
            <>
              <div>Let's get to know your style.</div>
              <div>Would you wear this outfit?</div>
            </>
          }
          TextAlignCenter
        />
        <Grid columns={12}>
          <Cell width={12}>
            <SwipeableCard
              getValue={getValue}
              cards={cards}
              reshuffle={false}
            />

            <Footer
              bgColor={[`#F9423A`, `#FCA09C`]}
              icon={`rightArrow`}
              disableButton={shuffleIndex < 10}
              footerStep={handleStep}
              buttonCopy={`Continue`}
              hasArrow={true}
            />
          </Cell>
        </Grid>
      </div>
    </div>
  );
}

export default StyleQuiz;
