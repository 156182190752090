import React from 'react';
import "./OrderHistory.Module.scss";
import OrderBoxItem from "./OrderBoxItem";

function OrderBox({orders}) {
      
    return (
        <div className="OrderItem">
            <div className="OrderItemWrapper">
                {orders.map(order =>
                    <OrderBoxItem order={order} key={order.id} />
                )}
            </div>
        </div>
    );
}

export default OrderBox;