import React, { useState, Fragment } from "react";
import style from "./OptionCarousel.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import classnames from "classnames";
import { Cell } from "styled-css-grid";
import "./OptionCarousel.override.scss";
import Icon from "Icon";

const descStyles = classnames(`fw mb`, [style.OptionCarouselDesc]);
const deviceWidth = window.outerWidth;
const displayCount = deviceWidth > 512 ? 5 : 3;
const OptionCarousel = ({ choices, getValue }) => {
const [selected, setSelected] = useState(false);
  function toggleState(id) {
    if (id === selected) {
      setSelected(null);
    } else {
      setSelected(id);
    }
    getValue(id)
  }

  return (
    <Cell width={12} top={2}>
      <Carousel
        className={style.OptionCarousel}
        showThumbs={false}
        centerMode
        selectedItem={2}
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        centerSlidePercentage={100 / displayCount}
        useKeyboardArrows={true}
        emulateTouch={false}
        showIndicators={true}
      >
        {choices.map(({ id, attachment, label }) => {
          const splitLabel = label.split(/ {/);
          const formattedTitle = splitLabel[0]
          const formattedDescription = splitLabel[1].split(/}/)

          return (
            <Fragment key={id}>

              <div
                className={style.OptionCarouselSlide}
                onClick={() => { toggleState(id) }}
              >
                <div className={classnames(
                  {
                    [style.OptionCarouselSlideSelected]: selected === id
                  }
                )}>
                  <img className="db mla mra" src={attachment && attachment.href} alt={formattedTitle} />
                  {selected === id &&
                    <span className={style.OptionCarouselTick}>
                      <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg"><path d="M15 30a15 15 0 100-30 15 15 0 000 30zM7.6 16a1.3 1.3 0 011.8-1.8l3.7 3.7 8-8a1.2 1.2 0 111.7 1.8L14 20.6c-.5.5-1.3.5-1.8 0L7.6 16z" fill="#FFF" fillRule="evenodd" /></svg>
                    </span>
                  }
                </div>
                <h3 className="ttu ff-body fw-medium mt2 mb">{formattedTitle}</h3>
                <p className={descStyles}>{formattedDescription}</p>
              </div>

            </Fragment>
          );
        })}
      </Carousel>
    </Cell>
  );
};

export default OptionCarousel;