import React, { useState, useEffect, Fragment, useRef } from 'react';
import './CustomOrder.Module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentOrder,
  boxRequestedWithStyleProfile,
  boxRequested,
  updateOrder,
} from './../store/Actions/OrderAction';
import {
  fetchStyleProfile,
  updateResponse,
} from '../store/Actions/QuestionAction';
import {
  fetchBillingInfo,
  fetchShippingAddress,
  fetchHolidays
} from './../store/Actions/CustomerAction';
import { DayPickerSingleDateController } from 'react-dates';
import history from '../History';
import moment from 'moment';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { getQuestionSelector } from 'QuestionSelector';

// components
import Header from '../Dashboard/Header';
import SubHeader from './SubHeader';
import TextArea from '../TextArea';
import NavBar from 'NavBar';
import { Grid, Cell } from 'styled-css-grid';
import Footer from 'Footer';
import ThemeBoxItems from '../ThemeBox/ThemeBoxItems';
import SubscriptionSelector from './../ManageSubscription/SubscriptionSelector';
import ConfirmShippingAddress from './../ConfirmShippingAddress';
import ConfirmPayment from './../ConfirmPayment';
import { BASE_PATH } from './../Config';

// images
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';

import { isDayBlockedForShipment } from "./../utils/dayPicker";

const color = {
  color: '#003b5c',
};

function CustomizeOrder({ match, location }) {
  const dispatch = useDispatch();

  const returnToFormScroll = useRef(null);

  const isPlaceOrder = match.params.step === `order`;

  const order = useSelector((state) =>
    _.get(state, 'orders.currentOrder', {})
  );

  const themeboxes = useSelector((state) =>
    _.get(state, 'themeboxes.themeboxes', {})
  );

  let selectedThemebox = useSelector((state) =>
    _.get(state, 'themeboxes.selectedThemebox')
  );

  const celerantId = useSelector((state) =>
    _.get(state, 'customer.data.celerant_id', {})
  );

  const isAbandonedAccount = _.isEmpty(celerantId);

  const billingInfo = useSelector((state) =>
    _.get(state, 'customer.billingInfo.card_info', {})
  );

  const hasBillingInfo = !_.isEmpty(billingInfo);

  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  const hasShippingAddress = !_.isEmpty(shippingAddress);

  const isLoadingOrders = useSelector((state) =>
    _.get(state, 'orders.isLoading')
  );

  const expectedDeliveryDate = useSelector((state) =>
    _.get(state, 'orders.currentOrder.expected_delivery_date')
  );

  const isOnDemand = useSelector((state) =>
    _.chain(state)
      .get('customer.data.subscription_type')
      .isEqual('subscription_on_demand')
      .value()
  );

  const responses = useSelector((state) =>
    _.get(state, 'questions.responses', {})
  );

  const isEmptyResponses = _.isEmpty(responses);

  const [button, setButton] = useState(true);
  const [hasHeight, setHasHeight] = useState(false);
  const [hasBodyType, setBodyType] = useState(false);
  const [hasDenimRise, setDenimRise] = useState(false);
  const [hasDenimStyle, setDenimStyle] = useState(false);
  const [hasDenimDistressing, setDenimDistressing] = useState(false);
  const [hasDenimSpend, setDenimSpend] = useState(false);
  const [hasAgeRange, setAgeRange] = useState(false);
  const [hasShoeSize, setShoeSize] = useState(false);
  const [hasTopSize, setTopSize] = useState(false);
  const [hasJeanBestFit, setJeansBestFit] = useState(false);
  const [hasBraSize, setBraSize] = useState(false);
  const [date, setDate] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  if (!selectedThemebox) {
    selectedThemebox = localStorage.getItem('selectedThemebox');
  }

  let selectedDate = localStorage.getItem('selectedDate');

  let selectedSubscription = localStorage.getItem('selectedSubscription');

  let heightQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['height_group'], f.ref))
        .flatten()
        .value()
  );

  let bodyTypeQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['proportions_waist'], f.ref))
        .flatten()
        .value()
  );

  let denimRiseQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['preferred_jeans_rises'], f.ref))
        .flatten()
        .value()
  );

  let denimStyleQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['preferred_jeans'], f.ref))
        .flatten()
        .value()
  );

  let denimDistressingQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['distressing_preferences'], f.ref))
        .flatten()
        .value()
  );

  let denimSpendQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['typical_spend_amount_denim'], f.ref))
        .flatten()
        .value()
  );

  let ageRangeQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['age_range'], f.ref))
        .flatten()
        .value()
  );

  let shoeSizesQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['shoe_size'], f.ref))
        .flatten()
        .value()
  );

  let denimBestFitQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['jeans_best_denim_fit'], f.ref))
        .flatten()
        .value()
  );

  let topSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['top_size'], f.ref))
        .flatten()
        .value()
  );

  let braSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['bra_size_group'], f.ref))
        .flatten()
        .value()
  );

  const holidays = useSelector((state) =>
    _.get(state, 'holidays', {})
  );

  const scrollToArea = () => {
    returnToFormScroll.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(fetchShippingAddress());
    dispatch(fetchHolidays());
    dispatch(fetchBillingInfo());
    dispatch(fetchStyleProfile());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmptyResponses) {
      responses.height_feet && responses.height_inches && setHasHeight(true);
      responses.proportions_waist && setBodyType(true);
      responses.preferred_jeans_rises && setDenimRise(true);
      responses.preferred_jeans && setDenimStyle(true);
      responses.distressing_preferences && setDenimDistressing(true);
      responses.typical_spend_amount_denim && setDenimSpend(true);
      responses.shoe_size && setShoeSize(true);
      responses.age_range && setAgeRange(true);
      responses.top_size && setTopSize(true);
      responses.jeans_best_fit_brand &&
        responses.jeans_size &&
        setJeansBestFit(true);
      responses.bra_size_letter &&
        responses.bra_size_number &&
        setBraSize(true);
    }
  }, [isEmptyResponses]);

  useEffect(() => {
    if (
      responses.height_feet &&
      responses.height_inches &&
      responses.proportions_waist &&
      responses.top_size &&
      responses.jeans_best_fit_brand &&
      responses.jeans_size &&
      responses.bra_size_letter &&
      responses.bra_size_number &&
      responses.preferred_jeans_rises &&
      responses.preferred_jeans &&
      responses.distressing_preferences &&
      responses.typical_spend_amount_denim &&
      responses.shoe_size &&
      hasBillingInfo &&
      hasShippingAddress
    ) {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [
    hasBillingInfo,
    hasShippingAddress,
    responses.height_feet,
    responses.heightInches,
    responses.proportions_waist,
    responses.top_size,
    responses.jeans_best_fit_brand &&
      responses.jeans_size &&
      responses.bra_size_letter,
    responses.bra_size_number,
    responses.preferred_jeans_rises,
    responses.preferred_jeans,
    responses.shoe_size,
    responses.distressing_preferences,
    responses.typical_spend_amount_denim,
  ]);

  useEffect(() => {
    location.state &&
      location.state.previousPath === '/members/change/payment' &&
      scrollToArea();

    !order.themebox &&
      selectedThemebox &&
      dispatch(updateCurrentOrder({ themebox: selectedThemebox }));

    !order.ship_by_date &&
      selectedDate &&
      dispatch(updateCurrentOrder({ ship_by_date: selectedDate }));
    !order.subscription_type &&
      selectedSubscription &&
      dispatch(updateCurrentOrder({ subscription_type: selectedSubscription }));
  }, [dispatch, order.themebox, order.ship_by_date, order.subscription_type]);

  let val = (value, ref) => {
    dispatch(updateResponse(ref, value));
  };

  function handleOnDemandCustomerMsgChange(on_demand_customer_msg) {
    dispatch(updateCurrentOrder({ on_demand_customer_msg }));
  }

  function handleSubscriptionChange(subscription_type) {
    localStorage.setItem('selectedSubscription', subscription_type);
    dispatch(updateCurrentOrder({ subscription_type }));
  }

  function handleCustomDeliveryDateChange(selected_delivery_date) {
    setDate(moment(selected_delivery_date));
    localStorage.setItem('selectedDate', selected_delivery_date);
    dispatch(
      updateCurrentOrder({ ship_by_date: moment(selected_delivery_date) })
    );
  }

  function submitForm(e) {
    dispatch(
      updateCurrentOrder({ expected_delivery_date: order.ship_by_date })
    );

    dispatch(
      order.id
        ? updateOrder(responses, order)
        : boxRequestedWithStyleProfile(responses, order)
    );

    localStorage.removeItem('selectedThemebox');
    localStorage.removeItem('selectedSubscription');
    localStorage.removeItem('selectedDate');
  }

  const hasShoeSizeQuestion = shoeSizesQuestion.length > 0;

  return (
    <>
      <NavBar
        text='white'
        background="#003b5c"
        logo={EvereveTrendsendStackedLogoWhite}
        profile={profile}
        logOut={logOut}
      />

      <div className='customOrderContainer group group--slim mt2 mb2'>
        <Header
          header={
            order.id
              ? 'Customize your Trendsend Order'
              : 'Order a Trendsend Box'
          }
          subheader={
            isAbandonedAccount
              ? `We can't wait to style you! Let's complete your account and create your first order.`
              : ''
          }
          color={color}
          TextAlignCenter
          FontSizeLarge
        />

        <SubHeader
          header='Trendsend Theme'
          subheader='Your stylist will use this selection to choose pieces that are perfect for your body type and personal style.'
          color={color}
          TextAlignCenter
        />
        <ThemeBoxItems
          order={order}
          selectedThemebox={selectedThemebox}
          themeboxes={themeboxes}
        />

        <SubHeader
          header='Trendsend Delivery Date'
          subheader={
            !isPlaceOrder && expectedDeliveryDate && !order.customer_pick_up
              ? `Your current Trendsend's estimated delivery is ${moment(
                  expectedDeliveryDate
                ).format('MM/DD/YYYY')}`
              : 'When do you want to receive your Trendsend? This is an estimate – we’ll do our best!'
          }
          color={color}
          TextAlignCenter
        />

        {!isLoadingOrders && (
          <div style={{ marginTop: 30 }}>
            <DayPickerSingleDateController
              onDateChange={(d) => {
                handleCustomDeliveryDateChange(
                  d === order.ship_by_date ? null : d
                );
              }}
              date={
                selectedDate
                  ? moment(selectedDate)
                  : date
                  ? date
                  : order.expected_delivery_date &&
                    moment(order.expected_delivery_date)
              }
              isDayBlocked={(d) => {
                return isDayBlockedForShipment(d, holidays);
              }}
              numberOfMonths={isMobile ? 1 : 2}
              daySize={40}
              focused={true}
              initialVisibleMonth={() => {
                return order.ship_by_date
                  ? moment(order.ship_by_date)
                  : moment();
              }}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        )}

        {isOnDemand && !order.id && (
          <Fragment>
            <SubHeader
              header='Subscription Frequency'
              subheader='Select a frequency below to have Trendsend orders automatically scheduled. You can cancel anytime.'
              color={color}
              TextAlignCenter
            />
            <SubscriptionSelector
              value={order.subscription_type}
              onSubscriptionSelect={handleSubscriptionChange}
              selectedSubscription={selectedSubscription}
            />
          </Fragment>
        )}

        {(isAbandonedAccount || !hasHeight) && (
          <>
            <SubHeader
              header='How tall are you?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {heightQuestion.map((item) => {
                return (
                  <Fragment key={item.id}>
                    {item.type == `group` &&
                      item.properties.fields.map((item) => {
                        const Question = getQuestionSelector(item.type);

                        return (
                          <Question
                            label={item.title}
                            choices={item.properties && item.properties.choices}
                            placeholder={
                              item.properties && item.properties.description
                            }
                            getValue={val}
                            initialValue={responses[item.ref]}
                            rotationDegree={responses.full_body_image_rotation}
                            kind={item.type}
                            title={item.title}
                            form={{}}
                            multiSelect={
                              item.properties &&
                              item.properties.allow_multiple_selection
                            }
                            questionRef={item.ref}
                            questionId={item.id}
                            supersized={
                              item.properties && item.properties.supersized
                            }
                            parentComponentName={'LongForm'}
                            match={match}
                          />
                        );
                      })}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBodyType) && (
          <>
            <SubHeader
              header="What's your body shape?"
              subheader='Think about the proportions of your shoulders, waist, and hips.'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {bodyTypeQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimRise) && (
          <>
            <SubHeader
              header='Which denim rises would you wear?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimRiseQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimStyle) && (
          <>
            <SubHeader
              header='Which denim styles would you wear?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimStyleQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimDistressing) && (
          <>
            <SubHeader
              header='What are your distressing preferences?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimDistressingQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimSpend) && (
          <>
            <SubHeader
              header='How much do you typically spend on jeans?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimSpendQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasJeanBestFit) && (
          <>
            <SubHeader
              header='Now let’s find your perfect pair of jeans.'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimBestFitQuestion.map((item) => {
                return (
                  <Fragment key={item.id}>
                    {item.type == `group` &&
                      item.properties.fields.map((item) => {
                        const Question = getQuestionSelector(item.type);

                        return (
                          <Question
                            label={item.title}
                            choices={item.properties && item.properties.choices}
                            placeholder={
                              item.properties && item.properties.description
                            }
                            getValue={val}
                            initialValue={responses[item.ref]}
                            rotationDegree={responses.full_body_image_rotation}
                            kind={item.type}
                            title={item.title}
                            form={{}}
                            multiSelect={
                              item.properties &&
                              item.properties.allow_multiple_selection
                            }
                            questionRef={item.ref}
                            questionId={item.id}
                            supersized={
                              item.properties && item.properties.supersized
                            }
                            parentComponentName={'LongForm'}
                            match={match}
                          />
                        );
                      })}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasAgeRange) && (
          <>
            <SubHeader
              header='How old are you?'
              color={color}
              TextAlignCenter
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {ageRangeQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasTopSize) && (
          <>
            <SubHeader
              header='What is your top size?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {topSizeQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type == `multiple_choice` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBraSize) && (
          <>
            <SubHeader
              header='What is your bra size?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {braSizeQuestion.map((item) => {
                return (
                  <Fragment key={item.id}>
                    {item.type == `group` &&
                      item.properties.fields.map((item) => {
                        const Question = getQuestionSelector(item.type);

                        return (
                          <>
                            <Cell width={12}>
                              <h3 className='h4 ff-body ttu pt mb0'>
                                {item.title}
                              </h3>
                            </Cell>

                            <Question
                              label={item.title}
                              choices={
                                item.properties && item.properties.choices
                              }
                              placeholder={
                                item.properties && item.properties.description
                              }
                              getValue={val}
                              initialValue={responses[item.ref]}
                              rotationDegree={
                                responses.full_body_image_rotation
                              }
                              kind={item.type}
                              title={item.title}
                              form={{}}
                              multiSelect={
                                item.properties &&
                                item.properties.allow_multiple_selection
                              }
                              questionRef={item.ref}
                              questionId={item.id}
                              supersized={
                                item.properties && item.properties.supersized
                              }
                              parentComponentName={'LongForm'}
                              match={match}
                            />
                          </>
                        );
                      })}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {((isAbandonedAccount && hasShoeSizeQuestion) ||
          (!hasShoeSize && hasShoeSizeQuestion)) && (
          <>
            <SubHeader
              header='What size shoes do you wear?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {shoeSizesQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type == `multiple_choice` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        <SubHeader
          header='Note to Stylist'
          subheader='Include details about your lifestyle or an event that you’re shopping for. If there is a certain piece you’d like in your order, request it here.'
          color={color}
          TextAlignCenter
        />

        <TextArea
          getValue={handleOnDemandCustomerMsgChange}
          placeholder='Start typing here'
          initialValue={order.on_demand_customer_msg}
        />

        <div ref={returnToFormScroll}></div>

        <ConfirmPayment
          path={history.location.pathname}
          isAbandonedAccount={isAbandonedAccount}
        />

        <ConfirmShippingAddress
          path={history.location.pathname}
          isAbandonedAccount={isAbandonedAccount}
          shippingAddress={shippingAddress}
          hasShippingAddress
        />

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType='submit'
          buttonCopy={isPlaceOrder ? `Send My Trendsend!` : `Save Changes`}
          footerStep={submitForm}
          disableButton={button}
          requiredQuestion
          paymentButton={isPlaceOrder}
        />
      </div>
    </>
  );
}

export default CustomizeOrder;
