import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavBar from "NavBar";
import EvereveTrendsendStackedLogoWhite from "../images/EVEREVE-TrensendStackedLogoWhite.png";
import style from "./Login.module.scss";
import classnames from "classnames";
import history from "./../History";
import SectionHeader from "SectionHeader";
import FormInput from "FormInput";
import Button from "Button";
import login from "./login.json";
import { loginSchema, loginInitialValues } from "./schema";
import { Grid, Cell } from "styled-css-grid";
import { Formik, Form, Field } from "formik";
import { loginSubmitted } from "./../store/Actions/AuthAction";
import queryString from 'query-string';
import { BASE_PATH } from "./../Config";
import _ from 'lodash';

const loginMap = {
  "login": login,
}

const schemaMap = {
  "login": loginSchema,
}

function Login({ match }) {

  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.auth.isLoading);
  const path = match.path && match.path.split(`${BASE_PATH}/`)[1];
  const item = loginMap[path];

  const vals = queryString.parse(window.location.search);
  if (vals.themebox) {
    dispatch({ type: 'THEMEBOX_SELECTED', themebox: vals.themebox });
  }

  function submitForm(values) {
    dispatch(loginSubmitted(values, null, 'login'));
  }

  return (
    <>
      <NavBar
        text='white'
        background="#003b5c"
        logo={EvereveTrendsendStackedLogoWhite}
        dashboard
        hasLogOut
        centerLogo
      />
      <div className={classnames('group group--slim mt2', [style.Login])}>

        <SectionHeader
          title={item.title}
          description={item.description}
        />

        <Formik
          initialValues={loginInitialValues}
          validationSchema={schemaMap[path]}
          onSubmit={submitForm}
        >
          {({ isValid }) => (
            <Form>
              <Grid>
                {item.fields.map(({ label, placeholder, columnWidth, type, name, mask }) => {
                  return (
                    <Cell width={columnWidth} key={name} className="mb">
                      <Field
                        name={name}
                        type={type}
                        label={label}
                        mask={mask}
                        placeholder={placeholder}
                        component={FormInput}
                      />
                    </Cell>
                  )
                })}
                <Cell width={12}>
                  <p className={classnames(style.SignIn, "mb ")}>
                    {`Want to create an account? `}
                    <a href="#" onClick={(e) => { e.preventDefault(); history.push(`${BASE_PATH}/register`) }} >
                      Sign Up
                    </a>
                  </p>
                </Cell>
                <Cell width={8} left={3} className="mb">
                  <div className={classnames('db')}>
                    <Button
                      variant="Primary"
                      content={isLoading ? 'One moment...' : 'Sign In'}
                      fullWidth={'Login to my account'}
                      type="submit"
                      loginButton
                    />
                  </div>
                </Cell>
                <Cell width={8} left={3} key={`forgot-password`} className={classnames('mb tac', style.ForgotPassword)}>
                  <a className="mb" href={`${BASE_PATH}/forgot_password`}>Forgot Password?</a>
                </Cell>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Login;