import {
  CUSTOMER_DATA_REQUESTED,
  CUSTOMER_DATA_RECEIVED,
  CUSTOMER_DATA_ERROR,
  SUBSCRIPTION_UPDATE_REQUEST,
  SUBSCRIPTION_UPDATE_SUCCESS,
  SUBSCRIPTION_UPDATE_ERROR,
  UPDATE_SHIPPING_ADDRESS_REQUESTED,
  UPDATE_SHIPPING_ADDRESS_RECEIVED,
  UPDATE_SHIPPING_ADDRESS_RECEIVED_ERROR,
  CREATE_SHIPPING_ADDRESS_REQUESTED,
  CREATE_SHIPPING_ADDRESS_RECEIVED,
  BILLING_INFO_RECEIVED,
  BILLING_INFO_REQUESTED,
  BILLING_INFO_ERROR,
  UPDATE_BILLING_INFO_REQUEST,
  UPDATE_BILLING_INFO_SUCCESS,
  UPDATE_BILLING_INFO_ERROR,
  ALL_REFERRALS_SUCCESS,
  STORE_LOCATIONS_REQUESTED,
  STORE_LOCATIONS_RECEIVED,
  STORE_LOCATIONS_RECEIVED_ERROR,
  UPDATING_CUSTOMER_DATA,
  UPDATING_CUSTOMER_DATA_SUCCESS,
  UPDATING_CUSTOMER_DATA_ERROR,
  CREATE_PAYMENT_DATA_REQUESTED,
  CREATE_PAYMENT_DATA_RECEIVED,
  CREATE_PAYMENT_DATA_ERROR,
  SET_IN_STORE_SIGNUP,
  SET_DRESSING_ROOM_TO_GO_SIGNUP,
  SET_SKIP_A_BOX_RECEIVED,
  SET_SKIP_A_BOX_RECEIVED_ERROR,
  SET_UNSKIP_A_BOX_RECEIVED,
  SET_UNSKIP_A_BOX_RECEIVED_ERROR,
} from "./../Actions/CustomerAction";
import _ from 'lodash';



const initState = {
  data: {},
  referrals: [],
  billingInfo: {},
  storeLocations: [],
  isLoading: false,
  errors: []
};

export default function (state = initState, action) {
  switch (action.type) {
    case SET_IN_STORE_SIGNUP: 
      return { ...state, inStore: true }
    case SET_DRESSING_ROOM_TO_GO_SIGNUP: 
      return { ...state, drtgSignup: true }
    case BILLING_INFO_ERROR:
    case SUBSCRIPTION_UPDATE_ERROR:
    case UPDATING_CUSTOMER_DATA_ERROR:
    case STORE_LOCATIONS_RECEIVED_ERROR:
    case CREATE_PAYMENT_DATA_ERROR:
    case UPDATE_BILLING_INFO_ERROR:
    case UPDATE_SHIPPING_ADDRESS_RECEIVED_ERROR:
    case CUSTOMER_DATA_ERROR:
    case SET_SKIP_A_BOX_RECEIVED_ERROR:
    case SET_UNSKIP_A_BOX_RECEIVED_ERROR:
      return { ...state, isLoading: false, errors: action.errors };
    case UPDATE_SHIPPING_ADDRESS_RECEIVED:
    case CREATE_SHIPPING_ADDRESS_RECEIVED:
      return { ...state, isLoading: false, data: {
        ...state.data,
        registration_shipping_address_complete: true,
        shipping_addresses: [
        ..._.filter(state.data.shipping_addresses, s => !s.is_primary), 
        action.data]
      }, errors: [] };
    case SUBSCRIPTION_UPDATE_SUCCESS:
      return { ...state, isLoading: false, data: {
        ...state.data, 
        style_profile_complete: true,
        subscription_type_selected: true
      }, errors: [] };
    case UPDATING_CUSTOMER_DATA_SUCCESS:
    case CUSTOMER_DATA_RECEIVED:
    case SET_SKIP_A_BOX_RECEIVED:
    case SET_UNSKIP_A_BOX_RECEIVED:
      return { ...state, isLoading: false, data: {
        ...state.data,
        ...action.data
      }, errors: [] };
    case BILLING_INFO_RECEIVED:
      return { ...state, isLoading: false, billingInfo: action.data, errors: [] };
    case SUBSCRIPTION_UPDATE_REQUEST:
    case CREATE_SHIPPING_ADDRESS_REQUESTED:
    case CREATE_PAYMENT_DATA_REQUESTED:
    case UPDATE_SHIPPING_ADDRESS_REQUESTED:
    case BILLING_INFO_REQUESTED:
    case CUSTOMER_DATA_REQUESTED:
    case UPDATE_BILLING_INFO_REQUEST:
    case STORE_LOCATIONS_REQUESTED:
    case UPDATING_CUSTOMER_DATA:
      return { ...state, isLoading: true, errors: [] };
    case UPDATE_BILLING_INFO_SUCCESS:
      return { ...state, isLoading: false, errors: [], data: {
        ...state.data,
        billingInfo: action.data
      }};
    case CREATE_PAYMENT_DATA_RECEIVED:
      return { ...state, isLoading: false, errors: [], data: {
        ...state.data,
        billing_info_complete: true
      }};
    case ALL_REFERRALS_SUCCESS:
      return { ...state, isLoading: false, referrals: action.data, errors: [] };
    case STORE_LOCATIONS_RECEIVED:
      return { ...state, isLoading: false, storeLocations: action.storeLocations, recommendedStoreLocation: action.storeLocations[0], errors: [] };
    default:
      return state;
  }
}

