import React from 'react';
import style from "./Dashboard.module.scss";
import Button from "Button";

function CoreContentWithBtn(props) {
    function imgSize() {
        if (props.CTA2) {
            return "style.tileIconSm"
        } else {
            return "style.tileIconBg"
        }
    }
    return (
        <div className={style.coreTile}>
            <div className={style.coreTileImgDiv}>
                <img src={props.image} className={style.tileIcon} alt="" />
            </div>
            <div className={style.coreTileText}>
                <h3>{props.h3}</h3>
                <h4>{props.h4}</h4>
                {!props.noPrimaryButton ? <Button fullWidth variant="Primary" content={props.CTA} onClick={props.handle} /> : ''}
                <div className={props.CTA3 && style.additionalOptions}>
                    <div style={{flexGrow: 2}}></div>
                    {props.CTA2 &&
                        <Button className="link" fullWidth variant="TextBlack" content={props.CTA2} onClick={props.handle2} />
                    }
                    {props.CTA3 &&
                        <Button className="link" fullWidth variant="TextBlack" content={props.CTA3} onClick={props.handle3} />
                    }
                    <div style={{flexGrow: 2}}></div>
                </div>
            </div>
        </div>
       
    );
}

export default CoreContentWithBtn;
