import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./Reducers";
import {createLogger} from 'redux-logger';

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const middlewareList = [thunk, createLogger()]

const enhancer = composeEnhancers(
  applyMiddleware(...middlewareList)
);

const store = createStore(
  reducers,
  enhancer
);

export default store;