import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { withRouter } from 'react-router-dom';
import Footer from "Footer";
import style from "./ResetPassword.module.scss";
import classnames from "classnames";
import SectionHeader from "SectionHeader";
import ProgressBar from "ProgressBar";
import FormInput from "FormInput";

import Button from "Button";
import resetPassword from "./reset_password.json";
import { resetPasswordSchema, resetPasswordInitialValues } from "./schema";

import { Grid, Cell } from "styled-css-grid";
import { Formik, Form, Field } from "formik";
import { getReferralCode, getCampaignCode, getCampaignUrl } from '.././utils/cookie';
import history from "../History";
import store from "./../store"
import { updatePassword } from "./../store/Actions/AuthAction";
import { BASE_PATH } from "./../Config";
import _ from 'lodash';

const resetPasswordMap = {
  "reset_password": resetPassword,
}

const schemaMap = {
  "reset_password": resetPasswordSchema,
}

function ResetPassword({match}) {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => _.get(state, 'auth.isLoading'));

  function submitForm(values) {
    dispatch(updatePassword(values.password, values.password_confirmation));
  }

  const path = _.get(match, 'path', '').split(`${BASE_PATH}/`)[1];
  const item = resetPasswordMap[path];


  return (
    <div className={classnames('group group--slim mt2', [style.ResetPassword])}>

      <SectionHeader
        title={item.title}
        description={item.description}
      />

      <Formik
        initialValues={resetPasswordInitialValues}
        validationSchema={schemaMap[path]}
        onSubmit={submitForm}
      >
        {({ isValid }) => (
          <Form>
            <Grid>
              {item.fields.map(({ label, placeholder, columnWidth, type, name, mask }) => {
                return (
                  <Cell width={columnWidth} key={name} className="mb">
                    <Field
                      name={name}
                      type={type}
                      label={label}
                      mask={mask}
                      placeholder={placeholder}
                      component={FormInput}
                    />
                  </Cell>
                )
              })}
              <Cell width={7} className="mb">
                <div className={classnames('db')}>
                  <Button
                    variant="Primary"
                    content={isLoading ? 'One moment...' : 'Update Password'}
                    fullWidth={'Update Password'}
                    type="submit"
                  />
                </div>
              </Cell>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}



export default ResetPassword;