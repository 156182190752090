import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "Footer";
import SectionHeader from "SectionHeader";
import FormInput from "FormInput";
import { Grid, Cell } from "styled-css-grid";
import { Formik, Form, Field } from "formik";
import { BASE_PATH } from "../Config";
import queryString from "query-string";
import shipping from "./account_shipping.json";
import { shippingSchema } from "./schema";
import {
  editShippingAddress,
  fetchBillingInfo,
} from "../store/Actions/CustomerAction";
import _ from "lodash";
import { setExchangeAddress } from "store/Actions/OrderAction";

function EditAddress({ location }) {
  const dispatch = useDispatch();

  const currentOrderStatus = useSelector((state) =>
    _.get(state, "orders.currentOrder.status")
  );
  
  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get("customer.data.shipping_addresses")
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : "";
        return current;
      }, {})
      .value()
  );

  const hasShippingAddress = !_.isEmpty(shippingAddress);

  useEffect(() => {
    hasShippingAddress && dispatch(fetchBillingInfo());
  }, [dispatch]);

  function submitForm(values, shippingAddress) {
    let response = true;

    if (_.includes(["created", "styled", "styling"], currentOrderStatus)) {
      response = window.confirm(
        "Changing your shipping address will update the address on your current order."
      );
    }
    
    if (response == true) {
      values = {
        ...values,
        shipping_phone: shippingAddress.shipping_phone,
        shipping_id: shippingAddress.shipping_id,
        shipping_is_primary: true,
      };
      location.navProps !== undefined && location.navProps.isConfirmation === true
        ? dispatch(
            setExchangeAddress(
              values,
              location.navProps !== undefined ? location.navProps.prevPath : ''
            )
          )
        : dispatch(
            editShippingAddress(
              values,
              location.navProps !== undefined ? location.navProps.prevPath : ''
            )
          );
    }
  }

  // if (_.isEmpty(shippingAddress)) return "";

  return (
    <div className="group group--slim mt2 mb2">
      <SectionHeader title="Change Shipping Address" description="" />

      <Formik
        isInitialValid={false}
        initialValues={shippingAddress}
        validationSchema={shippingSchema}
        onSubmit={(values) => submitForm(values, shippingAddress)}
      >
        {({ isValid }) => (
          <Form>
            <Grid>
              {shipping.map(
                ({ label, placeholder, columnWidth, type, name, mask }) => {
                  return (
                    <Cell width={columnWidth} key={name} className="mb2">
                      <Field
                        name={name}
                        type={type}
                        label={label}
                        mask={mask}
                        placeholder={placeholder}
                        component={FormInput}
                      />
                    </Cell>
                  );
                }
              )}
            </Grid>
            <Footer
              bgColor={[`#CE0058`, `#E67FAB`]}
              disableButton={!isValid}
              buttonType="submit"
              buttonCopy="Save Address"
              hasCancelButton={true}
              redirectPath={
                location.navProps !== undefined
                  ? location.navProps.prevPath
                  : ""
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditAddress;
