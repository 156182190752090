import React from "react";
import style from "./Callout.module.scss";
import classnames from "classnames";
import AngleHeader from "AngleHeader";
import Button from "Button";
import history from "../History";
import _ from "lodash";

function Callout({ image, title, button, angleColor, angleSecondaryColor }) {
  return (
    <div className={style.Callout}>

      <div className="group mt2">
        <div className={style.CalloutWrapper}>

          {title.map((t, key) => {
            return (
              <p
                key={key}
                className={classnames('ff-body mb2', style.CalloutTitle)} dangerouslySetInnerHTML={{ __html: t }}
              />
            )
          })}

          <div className="pl2 pr2 pt2">
            <Button
              handleClick={() => {
                if (_.includes(button.path, 'http')) {
                  window.location = button.path;
                } else {
                  history.push(button.path);
                }
              }}
              variant="Primary"
              content={button.title}
              fullWidth={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Callout;
