import React from "react";

const icons = {
  leftArrow: [
    "M21.5 16c0 .3-.2.6-.4.9l-6.7 6.7a1.2 1.2 0 1 1-1.8-1.8l5.8-5.8-5.8-5.8a1.3 1.3 0 0 1 1.8-1.8l6.7 6.7c.2.3.4.6.4.9z"
  ],
  rightArrow: [
    "M21.5 16c0 .3-.2.6-.4.9l-6.7 6.7a1.2 1.2 0 1 1-1.8-1.8l5.8-5.8-5.8-5.8a1.3 1.3 0 0 1 1.8-1.8l6.7 6.7c.2.3.4.6.4.9z"
  ],
  cross: [
    "M9.5 11.496l-7.09 7.09A1.413 1.413 0 0 1 .413 16.59L7.504 9.5.431 2.424A1.41 1.41 0 1 1 2.425.431L9.5 7.504l7.09-7.09a1.412 1.412 0 0 1 1.998 1.996L11.496 9.5l7.091 7.092a1.41 1.41 0 0 1-1.995 1.995L9.5 11.496z"
  ],
  heart: [
    "M14.3 2.5a8.3 8.3 0 0111.5-.3l.3.3a8.6 8.6 0 010 11.9l-9.5 9.8a3.3 3.3 0 01-4.6.1l-.1-.1-9.5-9.8a8.6 8.6 0 010-11.9 8.3 8.3 0 0111.5-.3l.3.3h.1zm-.6 20c.3.3.8.3 1.1 0l9.5-9.8a6.1 6.1 0 000-8.5l-.2-.2a5.6 5.6 0 00-7.9.2l-1 1c-.5.5-1.3.5-1.8 0l-1-1-.2-.2a5.8 5.8 0 00-8 .2 6.1 6.1 0 000 8.5l9.5 9.8z"
  ],
  tick: ["M13.62 0.608363L5.39989 8.82849L2.37952 5.80811C2.23308 5.66168 1.99564 5.66168 1.84917 5.80811L0.965297 6.69199C0.818859 6.83843 0.818859 7.07586 0.965297 7.22233L5.1347 11.3917C5.28114 11.5382 5.51858 11.5382 5.66505 11.3917L15.0342 2.02258C15.1806 1.87614 15.1806 1.63871 15.0342 1.49224L14.1503 0.608363C14.0039 0.461926 13.7665 0.461926 13.62 0.608363Z"],
  add: ["M15 15V9a1 1 0 0 1 2 0v6h6a1 1 0 0 1 0 2h-6v6a1 1 0 0 1-2 0v-6H9a1 1 0 0 1 0-2h6z"],
  error: ["M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0-10h-.04a.96.96 0 0 0-.917.887L9.042 5l.264 6.335a.695.695 0 0 0 1.377.1l.011-.1.264-6.335A.96.96 0 0 0 10 4z"],
  squiggle: [`M1 2.7c1.6.3 3.1 1 4.3 2l1.2.9c3.8 3 8.6 3 12.4 0l1.8-1.4a8.3 8.3 0 0110.4 0l2.4 1.7a9.6 9.6 0 0012.1-.1l2-1.5a8.3 8.3 0 0110.6 0l1.5 1.2c1.6 1.3 3.4 2 5.2 2.2a1 1 0 001.1-1c0-.5-.4-1-1-1-1.4-.3-2.9-1-4.4-2.1l-1.5-1.3c-3.8-3-8.6-3-12.4 0l-2 1.5a8.3 8.3 0 01-10.4 0L32 2.2a9.6 9.6 0 00-12.2.2L18 3.6a8.3 8.3 0 01-10.7 0l-1.2-1a10.3 10.3 0 00-5.1-2 1 1 0 00-1.1.9c0 .6.4 1 1 1.2z`]
};

const Icon = ({ icon, width, height, color, classes}) => (
  <svg
    fill={color}
    className={classes}
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
  >
    {icon &&
      icons[icon].map((item, index) => {
        return <path key={index} d={item} />;
      })}
  </svg>
);

export default Icon;
