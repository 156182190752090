import React, { useState, Fragment, useEffect } from 'react';
import './../CustomizeOrder/CustomOrder.Module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentOrder,
  boxRequested,
  boxRequestedWithStyleProfile,
  updateOrder,
} from './../store/Actions/OrderAction';
import {
  fetchStyleProfile,
  updateStyleProfile,
  updateResponse
} from '../store/Actions/QuestionAction';
import {
  editShippingAddress,
  fetchStoreLocations,
  fetchShippingAddress,
  fetchBillingInfo,
  fetchHolidays
} from '../store/Actions/CustomerAction';
import { DayPickerSingleDateController } from 'react-dates';
import history from '../History';
import moment from 'moment';
import _ from 'lodash';
import { getQuestionSelector } from 'QuestionSelector';
import FormInput from 'FormInput';
import ThemeBoxItems from '../ThemeBox/ThemeBoxItems';
import ConfirmPayment from './../ConfirmPayment';

// components
import Header from '../Dashboard/Header';
import SubHeader from './../CustomizeOrder/SubHeader';
import TextArea from '../TextArea';
import NavBar from 'NavBar';
import Footer from 'Footer';
import { Grid, Cell } from 'styled-css-grid';

// images
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';

import { isDayBlockedForPickup } from "./../utils/dayPicker";

const color = {
  color: '#003b5c',
};

function CustomizeDRTGOrder({ match }) {
  const dispatch = useDispatch();
  const isPlaceOrder = match.params.step === `new`;
  const order = useSelector((state) => _.get(state, 'orders.currentOrder', {}));
  const customer = useSelector((state) => _.get(state, 'customer.data', {}));
  const customerPhone = useSelector((state) =>
    _.get(state, 'customer.data.shipping_addresses[0].phone')
  );

  const holidays = useSelector((state) =>
    _.get(state, 'holidays', {})
  );

  const themeboxes = useSelector((state) =>
    _.get(state, 'themeboxes.themeboxes', {})
  );

  const isLoadingCustomer = useSelector((state) =>
    _.get(state, 'customer.isLoading')
  );

  const celerantId = useSelector((state) =>
    _.get(state, 'customer.data.celerant_id', {})
  );

  const isAbandonedAccount = _.isEmpty(celerantId);

  const billingInfo = useSelector((state) =>
    _.get(state, 'customer.billingInfo.card_info', {})
  );

  const hasBillingInfo = !_.isEmpty(billingInfo);

  const responses = useSelector((state) => _.get(state, 'questions.responses', {}));
  const isEmptyResponses = _.isEmpty(responses);

  const [customerCellNumber, setCustomerCellNumber] = useState(customerPhone);
  const [pickupStoreId, setPickupStoreId] = useState();
  const [button, setButton] = useState(true);
  const [hasHeight, setHasHeight] = useState(false);
  const [hasBodyType, setBodyType] = useState(false);
  const [hasDenimRise, setDenimRise] = useState(false);
  const [hasDenimStyle, setDenimStyle] = useState(false);
  const [hasDenimDistressing, setDenimDistressing] = useState(false);
  const [hasDenimSpend, setDenimSpend] = useState(false);
  const [hasAgeRange, setAgeRange] = useState(false);
  const [hasTopSize, setTopSize] = useState(false);
  const [hasJeanBestFit, setJeansBestFit] = useState(false);
  const [hasBraSize, setBraSize] = useState(false);
  const [hasShoeSize, setShoeSize] = useState(false);

  const currentHour = new Date().getHours();
  let pickupDate = localStorage.getItem('shipByDate');

  const contact = [
    {
      id: 0,
      type: 'text',
      name: 'shipping_phone',
      label: ' Cell Phone Number',
      placeholder: 'Cell Phone Number',
      columnWidth: 12,
      mask: '999-999-9999',
      autocomplete: 'tel-country-code',
    },
  ];

  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  let storeLocations = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['match_with_stylist_2'], f.ref))
        .flatten()
        .value()
  );

  storeLocations = _.chain(storeLocations)
    .map((f) => f.properties.fields[0])
    .value();

  const storeLocationsWithIds = useSelector((state) =>
    _.orderBy(
      _.get(state, 'customer.storeLocations', []),
      (s) => s.address_state
    )
  );

  let heightQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['height_group'], f.ref))
        .flatten()
        .value()
  );

  let shoeSizesQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['shoe_size'], f.ref))
        .flatten()
        .value()
  );

  let bodyTypeQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['proportions_waist'], f.ref))
        .flatten()
        .value()
  );

  let denimRiseQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['preferred_jeans_rises'], f.ref))
        .flatten()
        .value()
  );

  let denimStyleQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['preferred_jeans'], f.ref))
        .flatten()
        .value()
  );

  let denimDistressingQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['distressing_preferences'], f.ref))
        .flatten()
        .value()
  );

  let denimSpendQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['typical_spend_amount_denim'], f.ref))
        .flatten()
        .value()
  );

  let ageRangeQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['age_range'], f.ref))
        .flatten()
        .value()
  );

  let topSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['top_size'], f.ref))
        .flatten()
        .value()
  );

  let denimBestFitQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['jeans_best_denim_fit'], f.ref))
        .flatten()
        .value()
  );

  let braSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['bra_size_group'], f.ref))
        .flatten()
        .value()
  );

  useEffect(() => {
    dispatch(fetchShippingAddress());
    dispatch(fetchStoreLocations({ customer_pick_up: true }));
    dispatch(fetchBillingInfo());
    dispatch(fetchStyleProfile());
    dispatch(fetchHolidays());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmptyResponses) {
      responses.height_feet && responses.height_inches && setHasHeight(true);
      responses.proportions_waist && setBodyType(true);
      responses.preferred_jeans_rises && setDenimRise(true);
      responses.preferred_jeans && setDenimStyle(true);
      responses.distressing_preferences && setDenimDistressing(true);
      responses.typical_spend_amount_denim && setDenimSpend(true);
      responses.age_range && setAgeRange(true);
      responses.top_size && setTopSize(true);
      responses.shoe_size && setShoeSize(true);
      responses.jeans_best_fit_brand &&
        responses.jeans_size &&
        setJeansBestFit(true);
      responses.bra_size_letter &&
        responses.bra_size_number &&
        setBraSize(true);
    }
  }, [isEmptyResponses]);

  useEffect(() => {
    !order.ship_by_date &&
      pickupDate &&
      dispatch(
        updateCurrentOrder({ ship_by_date: moment(pickupDate).toDate() })
      );

    if (
      responses.preferred_store &&
      responses.preferred_store !== 'No Preference' &&
      order.ship_by_date &&
      customerCellNumber !== '' &&
      responses.height_feet &&
      responses.height_inches &&
      responses.proportions_waist &&
      responses.top_size &&
      responses.jeans_best_fit_brand &&
      responses.jeans_size &&
      responses.bra_size_letter &&
      responses.bra_size_number &&
      responses.preferred_jeans_rises &&
      responses.preferred_jeans &&
      responses.distressing_preferences &&
      responses.typical_spend_amount_denim &&
      customerCellNumber !== '' &&
      responses.shoe_size &&
      hasBillingInfo
    ) {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [
    responses.preferred_store,
    responses.height_feet,
    responses.heightInches,
    responses.shoe_size,
    responses.proportions_waist,
    responses.top_size,
    responses.jeans_best_fit_brand,
    responses.jeans_size,
    responses.bra_size_letter,
    responses.bra_size_number,
    responses.preferred_jeans_rises,
    responses.preferred_jeans,
    responses.distressing_preferences,
    responses.typical_spend_amount_denim,
    order.ship_by_date,
    customerCellNumber,
    customerPhone,
    hasBillingInfo,
  ]);

  function handleOnDemandCustomerMsgChange(on_demand_customer_msg) {
    dispatch(updateCurrentOrder({ on_demand_customer_msg }));
  }

  function handleCustomDeliveryDateChange(ship_by_date) {
    localStorage.setItem('shipByDate', ship_by_date);
    dispatch(updateCurrentOrder({ ship_by_date: ship_by_date.toDate() }));
  }

  let val = (value, ref) => {
    dispatch(updateResponse(ref, value));
  };

  let setCustomerCellPhoneNumber = (value, ref) => {
    setCustomerCellNumber(value);
  };

  let getStoreIdFromName = (value) => {
    let storeWithId =
      _.find(storeLocationsWithIds, (s) => {
        return s.name === value.split(' - ')[1];
      }) || {};
    return storeWithId.id;
  };

  let getStoreNameFromId = (id) => {
    let storeWithId =
      _.find(storeLocationsWithIds, (s) => {
        return s.id === id;
      }) || {};
    return `${storeWithId.address_state}, ${storeWithId.address_city} - ${storeWithId.name}`;
  };

  let setPickupStore = (value, ref) => {
    dispatch(updateResponse(ref, value));
    let assigned_location_id = getStoreIdFromName(value);
    if (assigned_location_id) {
      setPickupStoreId(assigned_location_id);
      dispatch(updateCurrentOrder({ assigned_location_id }));
    }
  };

  function submitForm(e) {
    const newOrder = {
      ...order,
      customer_pick_up: true,
      assigned_location_id: pickupStoreId,
    };

    responses && dispatch(updateStyleProfile(responses));

    dispatch(fetchStoreLocations({ customer_pick_up: true }));

    customer.id &&
      shippingAddress.shipping_id &&
      dispatch(
        editShippingAddress({
          shipping_id: shippingAddress.shipping_id,
          shipping_phone: customerCellNumber,
        })
      );

    dispatch(
      order.id
        ? updateOrder(responses, order)
        : boxRequestedWithStyleProfile(responses, newOrder)
    );
    localStorage.removeItem('selectedSubscription');
    localStorage.removeItem('selectedDate');
    localStorage.removeItem('shipByDate');
  }

  const hasShoeSizeQuestion = shoeSizesQuestion.length > 0;

  return (
    <div>
      <NavBar
        text='white'
        background='#003b5c'
        logo={EvereveTrendsendStackedLogoWhite}
        profile={profile}
        logOut={logOut}
      />
      <div className='customOrderContainer group group--slim mt2 mb2'>
        <Header
          header={order.id ? 'Trendsend Pickup' : 'Pick up a Trendsend'}
          subheader={
            isAbandonedAccount
              ? `We can't wait to style you! Let's complete your account and create your first order.`
              : `Pick up stylist-selected pieces from your local store and take it all home for two days to try before you buy.`
          }
          color={color}
          TextAlignCenter
          FontSizeLarge
        />

        <SubHeader
          header='Trendsend Theme'
          subheader='Your stylist will use this selection to choose pieces that are perfect for your body type and personal style.'
          color={color}
          TextAlignCenter
        />

        <ThemeBoxItems order={order} themeboxes={themeboxes} />

        <Grid columns={12} gap='16px' justifyContent='center'>
          {storeLocations.map((item) => {
            const Question = getQuestionSelector(item.type);

            let stores = item.properties.choices.filter((choice) => {
              return (
                choice.label !== 'No Preference'
              );
            });

            if (_.isEmpty(stores) || (!isPlaceOrder && !order.id)) return '';

            return (
              <Fragment key={item.id}>
                <Cell width={12}>
                  <SubHeader
                    header='EVEREVE Store'
                    subheader='Where would you like to pick up your Trendsend?'
                    color={color}
                    TextAlignCenter
                    requiredQuestion
                  />

                  <Question
                    label={item.title}
                    choices={stores}
                    placeholder={item.properties && item.properties.description}
                    getValue={setPickupStore}
                    initialValue={
                      order.id
                        ? getStoreNameFromId(
                            _.get(order, 'assigned_location.id')
                          )
                        : ''
                    }
                    kind={item.type}
                    title={item.title}
                    form={{}}
                    multiSelect={
                      item.properties &&
                      item.properties.allow_multiple_selection
                    }
                    questionRef={item.ref}
                    questionId={item.id}
                    supersized={item.properties && item.properties.supersized}
                  />
                </Cell>
              </Fragment>
            );
          })}
        </Grid>

        <SubHeader
          header='Pickup Date'
          subheader={
            <>
              <span>When would you like to pick up your Trendsend?</span>
              <br />
              <span>Your order must be returned five days after pickup.</span>
            </>
          }
          color={color}
          TextAlignCenter
          requiredQuestion
        />

        <DayPickerSingleDateController
          onDateChange={(d) => {
            handleCustomDeliveryDateChange(d === order.ship_by_date ? null : d);
          }}
          date={
            (order.ship_by_date && moment(order.ship_by_date)) ||
            (pickupDate && moment(pickupDate))
          }
          isDayBlocked={(d) => {
            return isDayBlockedForPickup(d, holidays);
          }}
          numberOfMonths={1}
          daySize={40}
          focused={true}
          initialVisibleMonth={() => {
            return order.ship_by_date ? moment(order.ship_by_date) : moment();
          }}
          hideKeyboardShortcutsPanel={true}
        />

        <SubHeader
          header='Note to Stylist'
          subheader={
            <>
              <span>
                Include details about your lifestyle or an event that you’re
                shopping for.
              </span>
              <br />
              <span>
                If there is a certain piece you’d like in your order, request it
                here.
              </span>
            </>
          }
          color={color}
          TextAlignCenter
        />

        <TextArea
          getValue={handleOnDemandCustomerMsgChange}
          placeholder='Start typing here'
          initialValue={order.on_demand_customer_msg}
        />

        {(isAbandonedAccount || !hasHeight) && (
          <>
            <SubHeader
              header='How tall are you?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {heightQuestion.map((item) => {
                return (
                  <Fragment key={item.id}>
                    {item.type == `group` &&
                      item.properties.fields.map((item) => {
                        const Question = getQuestionSelector(item.type);

                        return (
                          <Question
                            label={item.title}
                            choices={item.properties && item.properties.choices}
                            placeholder={
                              item.properties && item.properties.description
                            }
                            getValue={val}
                            initialValue={responses[item.ref]}
                            rotationDegree={responses.full_body_image_rotation}
                            kind={item.type}
                            title={item.title}
                            form={{}}
                            multiSelect={
                              item.properties &&
                              item.properties.allow_multiple_selection
                            }
                            questionRef={item.ref}
                            questionId={item.id}
                            supersized={
                              item.properties && item.properties.supersized
                            }
                            parentComponentName={'LongForm'}
                            match={match}
                          />
                        );
                      })}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBodyType) && (
          <>
            <SubHeader
              header="What's your body shape?"
              subheader='Think about the proportions of your shoulders, waist, and hips.'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {bodyTypeQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimRise) && (
          <>
            <SubHeader
              header='Which denim rises would you wear?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimRiseQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimStyle) && (
          <>
            <SubHeader
              header='Which denim styles would you wear?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimStyleQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimDistressing) && (
          <>
            <SubHeader
              header='What are your distressing preferences?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimDistressingQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimSpend) && (
          <>
            <SubHeader
              header='How much do you typically spend on jeans?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimSpendQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasJeanBestFit) && (
          <>
            <SubHeader
              header='Now let’s find your perfect pair of jeans.'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimBestFitQuestion.map((item) => {
                return (
                  <Fragment key={item.id}>
                    {item.type == `group` &&
                      item.properties.fields.map((item) => {
                        const Question = getQuestionSelector(item.type);

                        return (
                          <Question
                            label={item.title}
                            choices={item.properties && item.properties.choices}
                            placeholder={
                              item.properties && item.properties.description
                            }
                            getValue={val}
                            initialValue={responses[item.ref]}
                            rotationDegree={responses.full_body_image_rotation}
                            kind={item.type}
                            title={item.title}
                            form={{}}
                            multiSelect={
                              item.properties &&
                              item.properties.allow_multiple_selection
                            }
                            questionRef={item.ref}
                            questionId={item.id}
                            supersized={
                              item.properties && item.properties.supersized
                            }
                            parentComponentName={'LongForm'}
                            match={match}
                          />
                        );
                      })}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasAgeRange) && (
          <>
            <SubHeader
              header='How old are you?'
              color={color}
              TextAlignCenter
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {ageRangeQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type !== `group` && item.id !== `nYZtNuKKsChw` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasTopSize) && (
          <>
            <SubHeader
              header='What is your top size?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {topSizeQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type == `multiple_choice` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBraSize) && (
          <>
            <SubHeader
              header='What is your bra size?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {braSizeQuestion.map((item) => {
                return (
                  <Fragment key={item.id}>
                    {item.type == `group` &&
                      item.properties.fields.map((item) => {
                        const Question = getQuestionSelector(item.type);

                        return (
                          <>
                            <Cell width={12}>
                              <h3 className='h4 ff-body ttu pt mb0'>
                                {item.title}
                              </h3>
                            </Cell>
                            <Question
                              label={item.title}
                              choices={
                                item.properties && item.properties.choices
                              }
                              placeholder={
                                item.properties && item.properties.description
                              }
                              getValue={val}
                              initialValue={responses[item.ref]}
                              rotationDegree={
                                responses.full_body_image_rotation
                              }
                              kind={item.type}
                              title={item.title}
                              form={{}}
                              multiSelect={
                                item.properties &&
                                item.properties.allow_multiple_selection
                              }
                              questionRef={item.ref}
                              questionId={item.id}
                              supersized={
                                item.properties && item.properties.supersized
                              }
                              parentComponentName={'LongForm'}
                              match={match}
                            />
                          </>
                        );
                      })}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {((isAbandonedAccount && hasShoeSizeQuestion) ||
          (!hasShoeSize && hasShoeSizeQuestion)) && (
          <>
            <SubHeader
              header='What size shoes do you wear?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {shoeSizesQuestion.map((item) => {
                const Question = getQuestionSelector(item.type);

                return (
                  <Fragment key={item.id}>
                    {item.type == `multiple_choice` && (
                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                      />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        <ConfirmPayment
          path={history.location.pathname}
          isAbandonedAccount={isAbandonedAccount}
        />

        <SubHeader
          header='Cell Phone Number'
          subheader='Please confirm your cell phone number. We’ll text you when your items are ready to be picked up.'
          color={color}
          TextAlignCenter
          requiredQuestion
        />

        {!isLoadingCustomer && (
          <Grid columns={12}>
            <Cell width={12}>
              {contact.map(
                ({ label, placeholder, columnWidth, type, name, mask }) => {
                  return (
                    <FormInput
                      name={name}
                      type={type}
                      label={label}
                      mask={mask}
                      placeholder={placeholder}
                      form={{}}
                      initialValue={
                        customerCellNumber || customerCellNumber === ''
                          ? customerCellNumber
                          : customerPhone
                      }
                      getValue={setCustomerCellPhoneNumber}
                    />
                  );
                }
              )}
            </Cell>
          </Grid>
        )}

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType='submit'
          buttonCopy={isPlaceOrder ? `Place Order` : `Save Order`}
          footerStep={submitForm}
          disableButton={button}
          requiredQuestion
          paymentButton={isPlaceOrder}
        />
      </div>
    </div>
  );
}

export default CustomizeDRTGOrder;
