import React from 'react';
import style from './Dashboard.module.scss';
import classnames from 'classnames';

function Header(props) {
  return (
    <div
      style={props.color}
      className={classnames(
        style.DashboardHeader,
        props.TextAlignCenter && 'tac',
        props.FontSizeLarge && style.FontSizeLarge,
        props.SansSerif && style.SansSerif
      )}
    >
      <h1>{props.header}</h1>
      {props.subheader && (
        <h2 className={props.TextAlignCenter && 'tac'}>{props.subheader}</h2>
      )}
      {props.line ? <img src={props.line} alt='' /> : ''}
    </div>
  );
}

export default Header;
