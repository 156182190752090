import React from "react";
import _ from 'lodash';

export const UNEXPECTED_ERROR = {code: 'unexpected', message: 'An unexpected error occurred'};

export const ERROR_MESSAGES = {
  credit_card_number_invalid: 'Credit card number is invalid',
  not_authorized: 'Not authorized to perform this action',
  credentials_invalid: 'Invalid username or password',
  reset_password_token: 'Unable to reset password'
};

export function serverErrorsFromActiveRecordErrors(errorResp) {
  let errors;
  if (_.get(errorResp, 'data.error')) {
    errors = [{message: _.get(errorResp, 'data.error')}];
  } else {
    errors = _.map(errorResp.data.errors, (v, k) => { 
      return (_.includes(_.keys(v), 'code')) ? v : {code: k, message: v};
    });
  }
  
  return _.isEmpty(errors) ? [UNEXPECTED_ERROR] : errors;
}

export function serverErrors(errorResp) {
  return _.get(errorResp, 'response.data.errors', [_.get(errorResp, 'response.data.error', UNEXPECTED_ERROR)] );
}

// Renders server returned errors that are not associated with a specific field.
export function renderNonFieldErrors(fields, serverErrors) {
  const nonFieldErrors = _.reject(serverErrors, err => {
    return _.some(fields, field => errorsForField(field, [err]).length > 0);
  });

  if(nonFieldErrors.length > 0) {
    return nonFieldErrors.map(err =>
      <div className="form-group text-danger" style={{marginTop: '20px'}}>{e(err)}</div>
    );
  } else {
    return "";
  }
}

// Returns errors that are associated with the given field.
// An error is determined to be associated with the field when the value of its "code"
// property is equal to the value of the "name" property of the field followed by "_invalid".
// All other errors are assumed to be general/base-level errors which can be rendered
// by calling the "renderNonFieldErrors" function.
export function errorsForField(field, serverErrors) {
  return _(serverErrors).
    filter(e => e.code.split('_invalid')[0] === field.name).
    concat(field.error).
    compact().
    value();
}

//
// This function should be used to convert API error objects to displayable error messages.
//
// The expected format of error objects is: {code: 'string', message: 'string'}
//
// If a custom error message is configured in ERROR_MESSAGES for the error's 'code'
// value it will be displayed. Otherwise the error's 'message' value will be returned.
//
export function e(error) {
  const msg = ERROR_MESSAGES[error.code];

  if(msg) {
    return msg;
  } else if(error.message) {
    console.log(`No error message assigned for code: ${error.code}`);
    return error.message;
  } else {
    return null;
  }
};
