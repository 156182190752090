import * as yup from "yup";

export const shippingSchema = yup.object().shape({
  shipping_line_1: yup.string().required("Required"),
  shipping_line_2: yup.string().nullable(),
  shipping_city: yup.string().required("Required"),
  shipping_state: yup.string().required("Required"),
  shipping_postal_code: yup.string().min(5).matches(/(^[0-9]+$)/, 'Please enter a valid zip code.')
  .label('ZIP Code')
  .typeError('Please enter a valid zip code.')
  .required("Required")
})

// export const shippingInitialValues = {
//   shipping_line_1: '',
//   shipping_line_2: '',
//   shipping_city: '',
//   shipping_state: '',
//   shipping_postal_code: ''
// }