import React, { Fragment, useEffect, useState, useRef } from 'react';
import style from '../Quiz/Quiz.module.scss';
import Footer from 'Footer';
import SectionHeader from 'SectionHeader';
import FormInput from 'FormInput';
import { Grid, Cell } from 'styled-css-grid';
import Button from 'Button';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { US_STATES } from './../utils/us-states';
import dropdownStyle from './../Dropdown/Dropdown.module.scss';
import billing from './billing.json';
import shipping from './shipping.json';
import {
  billingSchema,
  shippingSchema,
  billingInitialValues,
  shippingInitialValues,
} from './schema';
import {
  fetchBillingInfo,
  createShippingAddress,
  createBillingInfo,
} from './../store/Actions/CustomerAction';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_PATH } from './../Config.js';
import _ from 'lodash';
import { updateCurrentOrder } from './../store/Actions/OrderAction';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

function Payment({ match }) {
  const isEdit = match.params.step === `edit`;
  const isBilling = match.params.step === `billing` || isEdit;
  const form = isBilling ? billing : isEdit ? billing : shipping;
  const dispatch = useDispatch();

  const USStates = _.map(US_STATES, (s) => (
    <option key={`states-${s.value}`} value={s.value}>
      {s.label}
    </option>
  ));
  const isLoading = useSelector((state) => _.get(state, 'customer.isLoading'));
  const shippingAddress = useSelector((state) =>
    _.get(state, 'customer.data.shipping_addresses.0')
  );
  const shipByDate = useSelector((state) =>
    _.get(state, 'questions.responses.ship_by_date')
  );
  const preferredStore = useSelector((state) =>
    _.get(state, 'questions.responses.preferred_store')
  );
  const errors = useSelector((state) => _.get(state, 'customer.errors'));
  const billingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.billingInfo')
      .values()
      .map(_.toPairs)
      .flatten()
      .fromPairs()
      .reduce((current, value, key) => {
        current[`billing_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );
  const isDressingRoomToGo = useSelector((state) =>
    _.get(state, 'customer.data.dressing_room_to_go_signup')
  );
  const isThemeboxSignUp = useSelector((state) =>
    _.chain(state).get('customer.data.themebox_signup').value()
  );
  const themeboxTheme = useSelector((state) =>
    _.chain(state).get('questions.responses.themebox').value()
  );
  const themeboxDescription = useSelector((state) =>
    _.chain(state).get('questions.responses.themebox_description').value()
  );
  const schedulePickupInfo = {
    ship_by_date: shipByDate,
    preferred_store: preferredStore,
  };
  const options = {
    schedulePickupInfo,
    isDressingRoomToGo,
    isThemeboxSignUp,
    themeboxTheme,
    themeboxDescription,
    initial_billing_values: false,
  };

  const [autocompleteAddress, setAutocompleteAddress] = useState('');
  const formikRef = useRef();

  const handleSelect = async (value) => {
    formikRef.current.resetForm();
    const results = await geocodeByAddress(value);

    results[0].address_components.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) {
        shippingInitialValues.shipping_line_1 = `${component.long_name} `;
      } else if (types.includes('route')) {
        shippingInitialValues.shipping_line_1 += component.long_name;
      } else if (types.includes('locality')) {
        shippingInitialValues.shipping_city = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        shippingInitialValues.shipping_state = component.short_name;
      } else if (types.includes('postal_code')) {
        shippingInitialValues.shipping_postal_code = component.long_name;
      }
    });
    setAutocompleteAddress(value);
  };

  useEffect(() => {
    dispatch(updateCurrentOrder({ themebox: `${themeboxTheme}` }));
  }, [dispatch]);

  if (isBilling && isEdit && !isLoading && _.isEmpty(billingAddress)) {
    dispatch(fetchBillingInfo());
  }

  let submitForm = (values) => {
    if (isBilling) {
      let billingData = _.reduce(
        _.pickBy(values, (val, key) => {
          return _.includes(key, 'billing_');
        }),
        (current, val, key) => {
          current[key.replace('billing_', '')] = val;
          return current;
        },
        {}
      );
      billingData.expiration = billingData.expiration.replace('/', '');
      dispatch(
        createBillingInfo(
          billingData,
          isEdit
            ? `${BASE_PATH}/checkout/confirmation`
            : `${BASE_PATH}/payment/confirmation`,
          options
        )
      );
    } else {
      let shippingData = _.reduce(
        _.pickBy(values, (val, key) => {
          return _.includes(key, 'shipping_');
        }),
        (current, val, key) => {
          current['is_primary'] = true;
          current[key.replace('shipping_', '')] = val;
          return current;
        },
        {}
      );
      dispatch(createShippingAddress(shippingData));
    }
  };

  let setAddressFromShippingInfo = (setValues, values) => {
    setValues(
      _.merge(values, {
        billing_street_address1: _.get(shippingAddress, 'line_1'),
        billing_street_address2: _.get(shippingAddress, 'line_2'),
        billing_city: _.get(shippingAddress, 'city'),
        billing_state: _.get(shippingAddress, 'state'),
        billing_zip_code: _.get(shippingAddress, 'postal_code'),
        billing_phone: _.get(shippingAddress, 'phone'),
      })
    );
  };

  if (isEdit && isBilling && _.isEmpty(billingAddress)) return '';

  return (
    <div className={classnames(style.Quiz)}>
      <div className='group group--slim mt2 mb2'>
        <SectionHeader
          title={
            isBilling
              ? `Payment Details`
              : isEdit
              ? `Change Payment Method`
              : `Shipping Details`
          }
          description={
            !isEdit && isBilling ? (
              <>
                <div className='mb'>
                  Please enter a valid credit card. We do not accept debit
                  cards.
                </div>
                <div>
                  Have a promo code? Enter at time of checkout after you receive
                  your box.
                </div>
              </>
            ) : (
              ''
            )
          }
        />

        <Formik
          isInitialValid={false}
          validateOnMound={true}
          validateOnChange={true}
          initialValues={
            isBilling
              ? isEdit
                ? billingAddress
                : billingInitialValues
              : isEdit
              ? billingInitialValues
              : shippingInitialValues
          }
          validationSchema={
            isBilling ? billingSchema : isEdit ? billingSchema : shippingSchema
          }
          innerRef={formikRef}
          onSubmit={submitForm}
        >
          {({ isValid, setValues, values }) => (
            <Form>
              <Grid>
                {form.map(
                  ({ label, placeholder, columnWidth, type, name, mask }) => {
                    return (
                      <Fragment key={`${name}-fragment`}>
                        {name === 'billing_street_address1' && !isEdit ? (
                          <Cell
                            width={columnWidth}
                            key={`${name}-use-shipping`}
                            className='mb'
                            label={label}
                          >
                            {!isDressingRoomToGo && (
                              <Button
                                variant='Inverted'
                                small='true'
                                content='Use Shipping Address'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setAddressFromShippingInfo(setValues, values);
                                }}
                              />
                            )}
                          </Cell>
                        ) : (
                          ''
                        )}
                        <Cell
                          width={columnWidth}
                          key={name}
                          className='mb2'
                          label={label}
                        >
                          {type === 'select' ? (
                            <Field
                              className={dropdownStyle.Dropdown}
                              as='select'
                              name={name}
                              description={placeholder}
                            >
                              <option>Select Your State</option>
                              {USStates}
                            </Field>
                          ) : !isBilling && name === 'shipping_line_1' ? (
                            <PlacesAutocomplete
                              value={
                                shippingInitialValues.shipping_line_1 ||
                                autocompleteAddress
                              }
                              onChange={setAutocompleteAddress}
                              onSelect={handleSelect}
                              searchOptions={{
                                componentRestrictions: { country: ['us'] },
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <Field
                                    className={dropdownStyle.Dropdown}
                                    {...getInputProps({
                                      placeholder: `${placeholder}`,
                                      name: `${name}`,
                                      type: `${type}`,
                                      label: `${label}`,
                                      mask: `${mask}`,
                                    })}
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 8) {
                                        shippingInitialValues.shipping_line_1 =
                                          '';
                                      }
                                    }}
                                  />
                                  <div>
                                    {loading ? <div>Loading...</div> : null}
                                    {suggestions.map((suggestion) => {
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion
                                          )}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          ) : (
                            <Field
                              name={name}
                              type={type}
                              label={label}
                              mask={mask}
                              placeholder={placeholder}
                              component={FormInput}
                            />
                          )}
                          {name === 'billing_credit_card_number' &&
                          _.find(errors, (e) =>
                            _.includes(
                              e.message,

                              'We do not accept debit cards'
                            )
                          ) ? (
                            <span
                              style={{ top: -30, position: 'relative' }}
                              className='mb c-error'
                            >
                              We do not accept debit cards.
                            </span>
                          ) : (
                            ''
                          )}
                        </Cell>
                      </Fragment>
                    );
                  }
                )}
                {isBilling ? (
                  <Cell width={12} key={`terms-of-use`} className='mb'>
                    <span>
                      By proceeding, you agree to our &nbsp;
                      <a
                        className='c-primary'
                        target='_blank'
                        href={`https://evereve.com/terms-of-service`}
                      >
                        terms of use
                      </a>
                      .
                    </span>
                  </Cell>
                ) : (
                  ''
                )}
              </Grid>
              <Footer
                bgColor={[`#CE0058`, `#E67FAB`]}
                disableButton={!isValid}
                buttonType='submit'
                buttonCopy={
                  isEdit
                    ? `Save & Replace Card`
                    : isBilling
                    ? isDressingRoomToGo
                      ? `Place My Order`
                      : `Send My Trendsend!`
                    : `Enter Payment Details`
                }
                paymentButton={isBilling}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Payment;
