import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email address').required("Required"),
  password: yup.string().label('Password').required("Required")
})


export const loginInitialValues = {
  email: '',
  password: ''
}