import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import { Grid, Cell } from 'styled-css-grid';
import ProgressBar from 'ProgressBar';
import SectionHeader from 'SectionHeader';
import Button from 'Button';
import Footer from 'Footer';
import style from './Delivery.module.scss';
import classnames from 'classnames';
import _ from 'lodash';
import history from '../History';
import { BASE_PATH } from '../Config';
import { useMediaQuery } from 'react-responsive';
import StorePickup from '../images/Tile/Core/BagIcon.svg';
import ThemeBoxImg from '../images/Tile/Core/ThemeBox.svg';
import { fetchStyleProfile } from '../store/Actions/QuestionAction';

function DeliveryMethod() {
  const dispatch = useDispatch();
  const [chosenFlow, setFlow] = useState();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const isThemeboxSignUp = useSelector((state) =>
    _.chain(state).get('customer.data.themebox_signup').value()
  );

  const isInStore = useSelector((state) =>
    _.chain(state).get('customer.data.registered_in_store').value()
  );

  const fastFlow = isThemeboxSignUp || isInStore;

  const drtgSignup = useSelector((state) => state.customer.drtgSignup);

  const fields = useSelector(
    (state) => {

      return state.questions &&
      _.filter(
        state.questions.data.fields,
        fastFlow && !drtgSignup
          ? (f) => f.fast_signup
          : fastFlow && drtgSignup
          ? (f) => f.fast_signup && !_.includes(['subscription_type_group'], f.ref)
          : (f) => !f.exclude_from_onboarding
      )
    }
  );

  useEffect(() => {
    dispatch(fetchStyleProfile());
  }, [dispatch, fields.length]);


  const handleStep = () => {
    chosenFlow === 'pickup'
      ? history.push(`${BASE_PATH}/schedule-pickup`)
      : fastFlow
      ? history.push(`${BASE_PATH}/payment/info`)
      : history.push(`${BASE_PATH}/quiz/${fields.length - 1}`)
  };

  const pickFlow = (flow) => {
    setFlow(flow);
  };

  return (
    <div className={style.Quiz}>
      <NavBar
        text='white'
        background="#003b5c"
        logo={EvereveTrendsendStackedLogoWhite}
        dashboard
        hasLogOut
        centerLogo
      />
      <div className="group group--slim mt2 mb2">
        

        <SectionHeader
          title="How would you like to get your Trendsend?"
          style={{
            textAlign: 'center',
            fontSize: isMobile ? '20px' : '24px',
          }}
        />
        <Grid columns={12}>
          <Cell
            width={isMobile ? 12 : 6}
            className={classnames(
              style.flowOptionContainer,
              isMobile ? 'mt' : 'mt4 mb4'
            )}
          >
            <img src={ThemeBoxImg} className={style.Icon} />
            <h4>Get fully styled looks delivered right to your door.</h4>
            <Button
              content="Shipped To Me"
              hasActiveState={chosenFlow === 'shipped' && true}
              onClick={(e) => {
                e.preventDefault();
                pickFlow('shipped');
              }}
            />
          </Cell>
          <Cell
            width={isMobile ? 12 : 6}
            className={classnames(
              style.flowOptionContainer,
              isMobile ? 'mt' : 'mt4'
            )}
          >
            <img src={StorePickup} className={style.Icon} />
            <h4>
              Swing by EVEREVE to pick up your Trendsend. We'll add a few extra
              pieces including shoes!
            </h4>
            <Button
              content="Store Pickup"
              hasActiveState={chosenFlow === 'pickup' && true}
              onClick={(e) => {
                e.preventDefault();
                pickFlow('pickup');
              }}
            />
          </Cell>
        </Grid>

        <Footer
          bgColor={[`#F9423A`, `#FCA09C`]}
          icon={`rightArrow`}
          disableButton={!chosenFlow}
          footerStep={handleStep}
          buttonCopy={`Continue`}
          hasArrow={true}
        />
      </div>
    </div>
  );
}

export default DeliveryMethod;
