import React from 'react';
import classnames from 'classnames';
import style from './Button.module.scss';
import Icon from 'Icon';
import history from '../History';
import { BASE_PATH } from './../Config';
import arrow from '../images/arrow.svg';

const ButtonVariants = {
  Primary: style.ButtonPrimary,
  Secondary: style.ButtonSecondary,
  Tertiary: style.ButtonTertiary,
  White: style.ButtonWhite,
  WhiteInverted: style.ButtonWhiteInverted,
  NavDark: style.ButtonNavDark,
  Inverted: style.ButtonInverted,
  Text: style.ButtonText,
  TextBlack: style.ButtonTextBlack,
};

const Button = ({
  requiredQuestion,
  variant,
  content,
  handleClick,
  handleClickBack,
  fullWidth,
  icon,
  rounded,
  iconColor,
  iconWidth,
  iconHeight,
  count,
  countPrefix,
  small,
  hasBackButton,
  hasCancelButton,
  hideBackButton,
  isDisabled,
  redirectPath,
  hasArrow,
  className,
  previousPath,
  hasActiveState,
  loginButton,
  isCreateAccountButton,
  isPaymentButton,
  ...others
}) => (
  <>
    {hasBackButton && hideBackButton === false ? (
      <button
        className={classnames(style.Button, style.Back, {
          [style.ButtonCount]: count > 0,
          df: count > 0,
        })}
        onClick={handleClickBack}
        {...others}
      >
        {!rounded && <span className={style.ButtonInner}>Go Back</span>}
        {count > 0 && (
          <span className={style.ButtonCountText}>
            {countPrefix ? countPrefix : null}
            {count}
          </span>
        )}
      </button>
    ) : null}

    {hasCancelButton && hasCancelButton === true ? (
      <button
        className={classnames(
          style.Button,
          ButtonVariants[variant],
          small ? style.ButtonSmall : '',
          {
            'full-width mr2': fullWidth,
            [style.ButtonRounded]: rounded,
            [style.ButtonCount]: count > 0,
            df: count > 0,
          }
        )}
        onClick={() =>
          history.push({
            pathname: `${BASE_PATH}${redirectPath}`,
            state: { previousPath },
          })
        }
      >
        Cancel
      </button>
    ) : null}

    {requiredQuestion && (
      <span className={style.requiredQuestion}>
        *Please answer all required questions
      </span>
    )}

    <button
      className={classnames(
        style.Button,
        hasActiveState && style.ActiveState,
        ButtonVariants[variant],
        small ? style.ButtonSmall : '',
        hasArrow ? style.Animation : '',
          loginButton && 'gtm-login',
          isCreateAccountButton && 'gtm-create-account',
          isPaymentButton && 'gtm-payment',
        {
          'full-width': fullWidth,
          [style.ButtonRounded]: rounded,
          [style.ButtonCount]: count > 0,
          df: count > 0,
        }
      )}
      onClick={handleClick}
      disabled={isDisabled}
      {...others}
    >
      {!rounded && (
        <span
          className={classnames(
            className,
            style.ButtonInner,
            count > 0 && 'full-width'
          )}
        >
          {content}
        </span>
      )}
      {count > 0 && (
        <span className={style.ButtonCountText}>
          {countPrefix ? countPrefix : null}
          {count}
        </span>
      )}
      {icon && (
        <Icon
          icon={icon}
          width={iconWidth}
          height={iconHeight}
          color={iconColor}
        />
      )}
      {hasArrow && <img className={style.Arrow} src={arrow} alt="" />}
    </button>
  </>
);

export default Button;
