import React, { useState, useEffect } from 'react';
import './ManageSubscription.Module.scss';
import { useDispatch, useSelector } from 'react-redux';
// API Actions
import { subscriptionUpdated } from './../store/Actions/CustomerAction';
// Components
import { Grid } from 'styled-css-grid';
import Radio from 'Radio';
import Modal from 'Modal';
import Button from 'Button';

import { BASE_PATH } from 'Config';

import _ from 'lodash';
import moment from 'moment';

function SubscriptionSelector({
  onSubscriptionSelect,
  style,
  value,
  selectedSubscription,
  boxCurrentlySkipped,
  mostRecentOrderDate,
  updatedSubcriptionFormat,
  subscriptionType,
}) {
  const dispatch = useDispatch();
  const current = useSelector((state) =>
    _.get(state, 'customer.data.subscription_type')
  );

  // State hooks
  const [modalVisibility, setModalVisibility] = useState(false);
  const [newSubscription, setNewSubscription] = useState(value || current);

  // Constants

  const choices = [
    {
      id: 'subscription_30_day',
      label: 'Every Month',
      value: 'subscription_30_day',
    },
    {
      id: 'subscription_60_day',
      label: 'Every Other Month',
      value: 'subscription_60_day',
    },
    {
      id: 'subscription_90_day',
      label: 'Every Quarter',
      value: 'subscription_90_day',
    },
    {
      id: 'subscription_on_demand',
      label: 'On Demand',
      value: 'subscription_on_demand',
    },
  ];

  const updatedEstimatedDeliveryDate = newSubscription
    && moment(mostRecentOrderDate)
        .add(parseInt(newSubscription.split('_')[1]) + 5, 'days')
        .format('MM/DD/YYYY');

  // Functions

  function handleRequestChange(current) {
    onSubscriptionSelect(current);
    setNewSubscription(current);

    if (boxCurrentlySkipped) {
      setModalVisibility(true);
    }
  }

  const cancelModalChanges = () => {
    onSubscriptionSelect(subscriptionType);
    setNewSubscription(subscriptionType);
    setModalVisibility(false);
  };

  const confirmModalChanges = () => {
    dispatch(subscriptionUpdated(newSubscription, null));
    setModalVisibility(false);
  };

  // Effects

  useEffect(() => {
    setNewSubscription(newSubscription);
  }, [dispatch]);

  return (
    <Grid className='mt' style={style}>
      {current ? (
        <Radio
          choices={choices}
          multiSelect={false}
          questionRef='subscription_type'
          getValue={handleRequestChange}
          initialValue={
            selectedSubscription
              ? selectedSubscription
              : newSubscription
              ? newSubscription
              : current
          }
        />
      ) : (
        ''
      )}

      {modalVisibility && (
        <Modal show={true} handleClose={() => cancelModalChanges()}>
          <h2 className='ff-body tac h2'>
            Thank you for updating your subscription, any previous change requests are
            void.
          </h2>
          <h3 className='mt2 tac h3'>Your subcription will be saved as:</h3>
          <div className='mt2 tac h3'>{updatedSubcriptionFormat}</div>
          {newSubscription !== 'subscription_on_demand' && (
            <>
              <h3 className='mt2 tac h3'>
                Your next box is scheduled with an estimated delivery date of:
              </h3>
              <div className='mt2 tac h3'>{updatedEstimatedDeliveryDate}</div>
            </>
          )}
          <hr />
          <Grid gap='16px' columns='repeat(auto-fit,minmax(30px,1fr))'>
            <Button
              handleClick={() => cancelModalChanges()}
              variant='Primary'
              content={'Cancel'}
              fullWidth={true}
              type={''}
              small={true}
            />
            <Button
              handleClick={() => confirmModalChanges()}
              variant='Primary'
              content={'Confirm'}
              fullWidth={true}
              type={''}
              small={true}
            />
          </Grid>
        </Modal>
      )}
    </Grid>
  );
}

export default SubscriptionSelector;
