import React from 'react';
import style from './CheckoutSuccess.module.scss';
import OrderReview from 'OrderReview';
import { BASE_PATH } from './../Config';
import Header from 'Dashboard/Header';

function CheckoutSuccess({ match }) {

  const path = match.path.split(`${BASE_PATH}/`)[1];
  const isDRTG = path === 'checkout/success-drtg';

  return (
    <div className={style.CheckoutSuccess}>
      <div className="group">
        <div className={style.CheckoutWrapper}>
          <Header header="Thank you for checking out!" TextAlignCenter FontSizeLarge />
          {isDRTG ? (
            <>
              <p className="p mb2">
                You’ll receive an email confirmation of the charge to your card.
                Please return your unwanted items to your EVEREVE store.
              </p>
              <p className="p mb2">
                We’d love to see your new looks! Tag us on Instagram or Facebook
                @evereveofficial.
              </p>
            </>
          ) : (
            <>
              <p className="p mb2">
                You'll receive an email confirmation of the charge to your card.
              </p>
              <p className="p mb2">
                Please use the USPS return envelope and pre-paid shipping label
                included with your Trendsend to send back your returns and
                exchanges.{' '}
              </p>
              <p className="p mb2">
                Returns must be postmarked by your checkout date, or within two
                days if your checkout date has already passed.{' '}
              </p>
            </>
          )}

          <br />
          <OrderReview />
        </div>
      </div>
    </div>
  );
}

export default CheckoutSuccess;
