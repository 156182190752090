import * as yup from "yup";

export const registerSchemaInStore = yup.object().shape({
  first_name: yup
    .string()
    .min(2, "Min. 2 characters.")
    .matches(/[a-z]+/i, "Please enter a valid first name.")
    .required("Required"),
  last_name: yup
    .string()
    .min(2, "Min. 2 characters.")
    .matches(/[a-z]+/i, "Please enter a valid last name.")
    .required("Required"),
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Required"),
  registration_postal_code: yup
    .string()
    .label("ZIP Code")
    .min(5, "Min. 5 characters.")
    .matches(/^[0-9]{5}(\-)?([0-9]{4})?/, "Must be 5 or 9 digits.")
});


export const registerInitialValuesInStore = {
  first_name: '',
  last_name: '',
  email: '',
  registration_postal_code: ''
}