import React from "react";
import style from "./Loading.module.scss"

function Loading({ title, overlay }) {
  return (
    <div className={style.Loading} style={{backgroundColor: overlay ? "#ffffffbb" : "#ffffff"}}>
      <i className={style.LoadingSpinner} />
    </div>
  );
}

export default Loading;
