import React from 'react';
import style from "./Dashboard.module.scss";

function SecondaryTile(props) {

    return (
        props.isCore ?
        <div className={style.tile} onClick={props.handle} role="button">
            <div className={props.styleclass2}>
                <img src={props.image} alt=""/>
            </div>
            <div>
                <h3>{props.h3}</h3>
                <h4>{props.h4}</h4>    
                    {props.address ? <h4>{props.address}</h4> : ''}                
            </div>
        </div>
        :
        <div className={props.styleclass1}>
            <div className={style.tile} onClick={props.handle} role="button">
                <div className={props.styleclass2}>
                    <img src={props.image} alt=""/>
                </div>
                <div>
                    <h3>{props.h3}</h3>
                    <h4>{props.h4}</h4>
                </div>
            </div>
        </div>
    );
}

export default SecondaryTile;