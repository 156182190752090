import React, { useState } from "react";
import { Cell, Grid } from "styled-css-grid";
import FormInput from "FormInput";
import style from './Birthday.module.scss';
import _ from "lodash";


function Birthday({ getValue, initialValue, label, questionRef }) {
  initialValue = _.isString(initialValue) ? initialValue.split('-') : [];
  const [showMonthError, setShowMonthError] = useState(false);
  const [showDayError, setShowDayError] = useState(false);
  const [showYearError, setShowYearError] = useState(false);
  const [errorMonthMessage, setErrorMonthMessage] = useState('');
  const [errorDayMessage, setErrorDayMessage] = useState('');
  const [errorYearMessage, setErrorYearMessage] = useState('');
  const [date, setDate] = useState([
    {
      label: `Month`,
      placeholder: `MM`,
      value: initialValue[0],
      min: 1,
      max: 12,
      maxLength: 2
    },
    {
      label: `Day`,
      placeholder: `DD`,
      value: initialValue[1],
      min: 1,
      max: 31,
      maxLength: 2
    },
    {
      label: `Year`,
      placeholder: `YYYY`,
      value: initialValue[2],
      min: 1900,
      maxLength: 4
    }
  ]);

  let setValue = (val, index) => {
    date[index].value = val;
    setDate(date);
    getValue(_.map(date, 'value').join('-'), questionRef);
  }

  const validateField = (event) => {
    const field = event.target;
    const maxLength = event.target.maxLength;
    const minNumber = event.target.min;
    const maxNumber = event.target.max;
    let value;
    const fieldLength = event.target.value.length;
    const fieldLabel = event.target.getAttribute('label');
    const currentYear = new Date().getFullYear();

    if (fieldLength >= maxLength) {
      field.value = field.value.substr(0, maxLength);
    }

    switch (fieldLabel) {
      case "Month":
        value = parseFloat(field.value);

        if (value > maxNumber) {
          setShowMonthError(true)
          setErrorMonthMessage('Please enter a month between 01 and 12');
        } else if (value <= maxNumber && field.value.length === maxLength) {
          setShowMonthError(false);
        }

        break;
      case "Day":
        value = parseFloat(field.value);

        if (value > maxNumber) {
          setShowDayError(true)
          setErrorDayMessage('Please enter a day between 01 and 31');
        } else if (value <= maxNumber && field.value.length === maxLength) {
          setShowDayError(false);
        }
        break;
      case "Year":
        value = parseFloat(field.value);

        if ((value < minNumber || value > currentYear) && field.value.length === maxLength) {
          setShowYearError(true)
          setErrorYearMessage(`Please enter a year between 1900 and ${currentYear}`);
        } else if ((value >= minNumber || value > currentYear) && field.value.length === maxLength) {
          setShowYearError(false);
        }
        break;

      default:
        break;
    }
  }

  return (
    <Cell width={12} className={style.Birthday}>
      <Grid columns={"minmax(0, 100px) minmax(0, 100px) minmax(0, 100px)"} gap="16px">
        {date.map(({label, placeholder, value, min, max, maxLength}, index) => {
          return (
            <Cell key={index}>
              <FormInput
                type="number"
                label={label}
                placeholder={placeholder}
                initialValue={value}
                min={min}
                max={max}
                maxLength={maxLength}
                getValue={(val) => { setValue(val, index) } }
                form={{}}
                validateField={validateField}
              />
            </Cell>
          )
        })}
      </Grid>
      {showMonthError === true ? <div className="mb mt c-error">{errorMonthMessage}</div> : ''}
      {showDayError === true ? <div className="mb mt c-error">{errorDayMessage}</div> : ''}
      {showYearError === true ? <div className="mb mt c-error">{errorYearMessage}</div> : ''}
    </Cell>
  );
}

export default Birthday;