import React, { Component, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import style from './Dashboard.module.scss';
import CoreContentWithBtn from './CoreContentWithBtn.jsx';
import history from '../History';
import CoreContent from './CoreContent.jsx';
import SecondaryTile from './SecondaryTile.jsx';
// core icons
import NothingScheduled from '../images/Tile/Core/NothingScheduled.svg';
import Scheduled from '../images/Tile/Core/Scheduled.svg';
import BeingStyled from '../images/Tile/Core/BeingStyled-or-Arrived.svg';
import OnItsWay from '../images/Tile/Core/Returns.svg';
import IsHere from '../images/Tile/Core/IsHere.svg';
import CheckoutComplete from '../images/Tile/Core/CheckOut.svg';
import DrtgOrder from '../images/Tile/Core/CheckOut.svg';
import ReturnShipped from '../images/Tile/Core/Shipped-OnItsWay.svg';
import DrtgNoOrder from '../images/Tile/Core/BagIcon.svg';
import ThemeBoxImg from '../images/Tile/Core/ThemeBox.svg';
import {
  fetchOrders,
  cancelOrder,
  fetchOrCreateInvoice,
  extendCheckout,
} from './../store/Actions/OrderAction';
import { BASE_PATH } from './../Config';
import moment from 'moment';
import classnames from 'classnames';

function Core({ isDressingRoomToGo }) {
  const dispatch = useDispatch();
  const customer = useSelector((reduxState) => reduxState.customer.data);
  const currentOrder = useSelector(
    (reduxState) => reduxState.orders.currentOrder || {}
  );
  const theOrders = useSelector(
    (reduxState) => reduxState.orders || {}
  );
  const currentInvoice = useSelector(
    (reduxState) => reduxState.orders.currentInvoice || {}
  );
  const isDressingRoomToGoOrder = useSelector(
    (state) =>
      state.orders.currentOrder && state.orders.currentOrder.customer_pick_up
  );
  const mostRecentOrderDate = useSelector((reduxState) =>
    _.chain(reduxState)
      .get('orders.orders', [])
      .filter((f) => f.subscription_type !== 'subscription_on_demand')
      .map((o) => new Date(o.expected_delivery_date))
      .orderBy()
      .reverse()
      .get('0')
  );

  const customerInvoice = _.filter(currentOrder.invoices, {
    invoice_type: 'customer',
  });

  const isCustomerInvoicePaid = _.filter(customerInvoice, {
    status: 'paid',
  });

  const adminInvoice = _.filter(currentOrder.invoices, {
    invoice_type: 'admin',
  });

  const isAdminInvoicePaid = _.filter(adminInvoice, {
    status: 'paid',
  });

  useEffect(() => {
    localStorage.removeItem('ship_by_date');
    localStorage.removeItem('selectedThemebox');
    localStorage.removeItem('selectedDate');
    localStorage.removeItem('selectedSubscription');
    dispatch(fetchOrders());
  }, [dispatch]);

  function handleGetStyled() {
    history.push(`${BASE_PATH}/customize/order/order`);
  }

  function handleScheduleDRTG() {
    history.push(`${BASE_PATH}/customize/order/custom/pickup`);
  }

  function handleCustomizeDRTG() {
    history.push(`${BASE_PATH}/customize/order/new/pickup`);
  }

  function handleCustomizeOrder() {
    history.push(`${BASE_PATH}/customize/order/custom`);
  }

  function handleCancelOrder() {
    dispatch(cancelOrder(currentOrder.id));
  }

  function trackingNumber() {
    return _.get(currentOrder.outgoing_shipment, 'tracking_code');
  }

  function handleTrackShipment() {
    const trackingUrl = `https://tools.usps.com/go/TrackConfirmAction.action?tRef=fullpage&tLc=1&text28777=&tLabels=${trackingNumber()}%2C`;
    window.location.assign(trackingUrl);
  }

  function handleCheckout() {
    history.push(`${BASE_PATH}/checkout/stylist-note`); // brings the user to the first screen in the checkout flow
  }

  function handleExtendCheckout() {
    let currentCheckoutDate =
      (currentOrder &&
        currentOrder.checkout_by_date &&
        moment(currentOrder.checkout_by_date)) ||
      moment();

    let extendDate = currentCheckoutDate.add(5, 'days').format('MM/DD');
    dispatch(extendCheckout(currentOrder, extendDate));
  }

  function showCoreText() {
    let subscriptionType = customer.subscription_type || '';
    let currentDeliveryDate = moment(currentOrder.delivery_date).format(
      'MM/DD'
    );
    let checkoutDate =
      (currentOrder &&
        currentOrder.checkout_by_date &&
        moment(currentOrder.checkout_by_date)) ||
      moment();

    let estimatedDeliveryDate = currentOrder.id
      ? currentOrder.expected_delivery_date
      : !_.isNaN(parseInt(subscriptionType.split('_')[1])) &&
        moment(mostRecentOrderDate).add(
          parseInt(subscriptionType.split('_')[1]) + 5,
          'days'
        );
    if (estimatedDeliveryDate < moment()) {
      estimatedDeliveryDate = moment().add(5, 'days');
    }

    let pickupDate = currentOrder.id ? currentOrder.ship_by_date : '';
    let deliveryDate = currentOrder.id ? currentOrder.delivery_date : '';
    let pickupAddress1 = currentOrder.id
      ? currentOrder.assigned_location.address_line_1
      : '';
    let pickupAddress2 = currentOrder.id
      ? currentOrder.assigned_location.address_line_2
      : '';
    let pickupCity = currentOrder.id
      ? currentOrder.assigned_location.address_city
      : '';
    let pickupState = currentOrder.id
      ? currentOrder.assigned_location.address_state
      : '';
    let pickupAdressPostalCode = currentOrder.id
      ? currentOrder.assigned_location.address_postal_code
      : '';
    let pickupName = currentOrder.id ? currentOrder.assigned_location.name : '';

    let estimatedDeliveryHeader = `Estimated delivery ${moment(
      estimatedDeliveryDate
    ).format('MM/DD/YYYY')}`;
    let pickupHeader = `Pick up from ${pickupName} on ${moment(
      pickupDate
    ).format('MM/DD/YYYY')}.`;
    let pickupReadyHeader = `Pick up from EVEREVE ${pickupName} today.`;
    let pickedUpHeader = ` You picked it up on ${moment(deliveryDate).format(
      'MM/DD/YYYY'
    )}. Remember to checkout and return items not kept by ${moment(
      checkoutDate
    ).format('MM/DD/YYYY')}.`;

    let showAddress = (
      <Fragment>
        <div>
          {pickupAddress1} {pickupAddress2}
        </div>
        <div>
          {pickupCity}, {pickupState} {pickupAdressPostalCode}
        </div>
      </Fragment>
    );

    // didn't include subscription type because all subscription types follow the same tile state progression

    switch (currentOrder.status) {
      // if status is not completed, fraud, or cancelled, it's considered a current order
      // "no order" tile state
      case 'completed':
      case 'fraud':
      case 'cancelled':
      default:
        if (
          subscriptionType !== 'subscription_on_demand' &&
          _.isEmpty(currentOrder.status) &&
          !isDressingRoomToGo
        ) {
          return (
            <SecondaryTile
              image={ThemeBoxImg}
              h3='Order a Trendsend Box'
              h4='Get outfits shipped to your door.'
              handle={handleGetStyled}
              isCore={true}
            />
          );
        } else if (isDressingRoomToGo) {
          return (
            <SecondaryTile
              image={DrtgNoOrder}
              h3='Pick up a Trendsend'
              h4='Order a Trendsend to be picked up at your local EVEREVE store.'
              CTA='Order Now'
              handle={handleCustomizeDRTG}
              isCore={true}
            />
          );
        } else {
          return (
            <SecondaryTile
              image={ThemeBoxImg}
              h3='Order a Trendsend Box'
              h4='Get outfits shipped to your door.'
              CTA='Get Styled'
              handle={handleGetStyled}
              isCore={true}
            />
          );
        }
      // "scheduled" tile state
      case 'created':
        return isDressingRoomToGoOrder ? (
          <CoreContentWithBtn
            image={DrtgOrder}
            h3='Your Trendsend Order is Placed'
            h4={pickupHeader} // this should be current order's estimated delivery
            CTA={`View Order`}
            CTA2='Cancel Order'
            handle={handleScheduleDRTG}
            handle2={handleCancelOrder}
          />
        ) : (
          <CoreContentWithBtn
            image={Scheduled}
            h3='Your Trendsend is Scheduled'
            h4={estimatedDeliveryHeader} // this should be current order's estimated delivery
            CTA='Customize Order'
            CTA2='Cancel Order'
            handle={handleCustomizeOrder}
            handle2={handleCancelOrder}
          />
        );
      // "in_progress" tile state
      case 'styling':
      case 'styled':
      case 'ready_to_ship':
        return isDressingRoomToGoOrder ? (
          <CoreContent
            image={DrtgOrder}
            h3='Your Trendsend is Being Styled'
            h4='We’ll text you when it’s ready to pick up.'
          />
        ) : (
          <CoreContent
            image={BeingStyled}
            h3='Your Trendsend is Being Styled'
            h4="You'll receive an email when your order ships!"
          />
        );

      // "shipped" tile state
      case 'ready_for_customer_pick_up':
      case 'ready_to_pick_up':
      case 'in_transit_to_customer':
        return isDressingRoomToGoOrder ? (
          <CoreContent
            image={DrtgOrder}
            h3='Your Trendsend is Ready To Go!'
            h4={pickupReadyHeader}
            showAddress={showAddress}
            isDrtg={true}
          />
        ) : (
          <CoreContentWithBtn
            image={OnItsWay}
            h3='Your Trendsend is On Its Way!'
            h4={estimatedDeliveryHeader}
            CTA='Track Shipment'
            CTA2='Review and Check Out'
            handle={handleTrackShipment}
            handle2={handleCheckout}
          />
        );
      // "arrived" tile state
      case 'delivered_to_customer':
      case 'payment_processing':
        return isDressingRoomToGoOrder ? (
          <CoreContentWithBtn
            image={DrtgOrder}
            h3="It's Time To Try On And Check Out"
            h4={pickedUpHeader}
            CTA='Review and Check Out'
            handle={handleCheckout}
          />
        ) : (
          <CoreContentWithBtn
            image={BeingStyled}
            h3='Your Trendsend is Here'
            h4={`Delivered ${currentDeliveryDate}. Please check out by ${checkoutDate.format(
              'MM/DD'
            )}.`}
            CTA='Review and Check Out'
            CTA2='Track Shipment'
            CTA3={
              checkoutDate > moment() &&
              currentOrder.extended !== undefined &&
              currentOrder.extended === false
                ? 'Extend Checkout'
                : null
            }
            handle={handleCheckout}
            handle2={handleTrackShipment}
            handle3={handleExtendCheckout}
          />
        );
      // "return" tile state
      case 'checked_out_by_customer':
      case 'post_customer_checkout_processing':
      case 'checked_out_by_system':
      case 'post_system_checkout_processing':
      case 'checked_out_by_admin':
      case 'post_admin_checkout_processing':
        return isDressingRoomToGoOrder ? (
          <CoreContent
            image={DrtgOrder}
            h3='Checkout Complete'
            h4={`Please return your unwanted items to your EVEREVE store by ${checkoutDate.format(
              'MM/DD'
            )}.`}
            // h4={checkoutDate && `Please postmark returns by ${checkoutDate}`}
          />
        ) : (
          <CoreContent
            image={CheckoutComplete}
            h3='Checkout Complete'
            h4={
              checkoutDate < moment()
                ? `Please postmark returns by ${checkoutDate
                    .add(2, 'days')
                    .format('MM/DD')}`
                : `Please postmark returns by ${checkoutDate.format('MM/DD')}`
            }
            // h4={checkoutDate && `Please postmark returns by ${checkoutDate}`}
          />
        );
      // "track_return" tile state
      case 'in_transit_to_trendsend':
      case 'delivered_to_trendsend':
        if (
          isCustomerInvoicePaid.length ||
          isAdminInvoicePaid.length
        ) {
          return isDressingRoomToGoOrder ? (
            <CoreContent
              image={DrtgOrder}
              h3='Return Received'
              h4='Your Trendsend return is being processed'
            />
          ) : (
            <CoreContent
              image={ReturnShipped}
              h3='Return Shipped'
              h4='Your return is on its way to Trendsend'
            />
          );
        } else {
          return isDressingRoomToGoOrder ? (
            <CoreContent
              image={DrtgOrder}
              h3='Return Received'
              h4='Your Trendsend return is being processed'
              handle={handleCheckout}
            />
          ) : (
            <CoreContentWithBtn
              image={ReturnShipped}
              h3='Return Shipped'
              h4='Your return is on its way to Trendsend'
              CTA='Check Out'
              handle={handleCheckout}
            />
          );
        }
    }
  }

  return (
    <div
      className={classnames(
        isDressingRoomToGo ? style.c1 : style.c2,
        currentOrder.hasOwnProperty('id') ? style.currentOrder : ''
      )}
    >
      {showCoreText()}
    </div>
  );
}

export default Core;
