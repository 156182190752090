import React from "react";
import style from "./TextArea.module.scss";
import { Cell } from "styled-css-grid";
import _ from "lodash";

const TextArea = ({ label, text, getValue, questionRef, initialValue, placeholder, value, onChange }) => {
  return (
    <Cell width={12} className={style.TextArea}>
      <label className={style.TextAreaLabel}>
        <textarea
          className={style.TextAreaInput}
          onBlur={({ target: { value: text } }) => getValue(text, questionRef)}
          placeholder={placeholder || `Start typing here...`}
          defaultValue={initialValue}
        >
          {text}
        </textarea>
      </label>
    </Cell>
  );
};

export default TextArea;
