import React, { Fragment } from "react";
import Footer from "Footer";
import { NavLink } from "react-router-dom";
import SectionHeader from "SectionHeader";
import history from "../History";
import ReviewItem from "ReviewItem";
import _ from "lodash";
import FormInput from "FormInput";
import Button from "Button";
import cardImage from "images/card.svg";
import { BASE_PATH } from "./../Config";
import { useSelector } from "react-redux";

function OrderHistoryExpanded() {
    const checkoutItems = useSelector(state => state.checkout.getCheckoutItems);
    let products = [];

    if (checkoutItems.length === 0) {
        history.push(`${BASE_PATH}/checkout/review`);
    } else {
        products = [
            {
                title: `Bought`,
                items: _.filter(checkoutItems, { 'label': 'Keep' })
            },
            {
                title: `Returned`,
                items: _.filter(checkoutItems, { 'label': 'Return' })
            }
        ]
    }

    let values = []
    let discounted = []
    checkoutItems.map((item) => {
        let i = 0
        values.push(item.price)
        if (item.discounted === true) return discounted.push(i++)
    })


    const subTotal = eval(values.join("+"))
    const totalPrice = Math.round(subTotal && subTotal - (10 / 100 * subTotal));
    const discount = totalPrice && Math.abs(Math.round(totalPrice - subTotal));

    function getReviewItem() {

    }

    return (
        <div className="group group--slim" style={{ paddingBottom: `30vh` }}>
            <SectionHeader
                title="Review Your Order"
                description="Order date MM/DD/YYYY"
                hr
            />

            <div className="mb2">

                {products.map((product) => {
                    return (
                        <div className="mb2" key={product.title}>
                            {product.items.length > 0 && <h2 className="pt ff-body mb ttu fw-bold c-gray">{product.title}</h2>}
                            {product.items.map((item) => {
                                return <ReviewItem discount={discounted.length >= 4 && product.title !== `Returning`} showSize={product.title === `Exchanging`} hr={product.items.length > 1} noOptions key={item.desc} productValue={getReviewItem} products={item} />
                            })}
                        </div>
                    )
                })}

                <h2 className="pt2 ff-body ttu fw-bold c-gray">Price Breakdown</h2>

                <ul>
                    <li className="df jfs mb">
                        Subtotal <span>${subTotal}</span>
                    </li>
                    {discounted.length >= 4 &&
                        <li className="df jfs c-tertiary mb">
                            TRENDSEND10 <span>-${discount}</span>
                        </li>
                    }
                    <li className="df jfs ttu fw-bold mb">
                        Total <span>${totalPrice}</span>
                    </li>
                </ul>


                <Footer
                    bgColor={[`#CE0058`, `#E67FAB`]}
                />
            </div>
        </div>
    );
}

export default OrderHistoryExpanded;
