import React, { useState, useEffect, Fragment } from 'react';
import { getQuestionSelector } from 'QuestionSelector';
import style from './Quiz.module.scss';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import Footer from 'Footer';
import SectionHeader from 'SectionHeader';
import ProgressBar from 'ProgressBar';
import StyleQuiz from '../StyleQuiz';
import { Grid, Cell } from 'styled-css-grid';
import history from '../History';
import classnames from 'classnames';
import { BASE_PATH } from './../Config';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { fetchStoreLocations } from '../store/Actions/CustomerAction';
import {
  fetchStyleProfile,
  updateStyleProfile,
  updateResponse,
} from '../store/Actions/QuestionAction';
import Loading from 'Loading';
import * as tracking from './../utils/gt-tracking';

const onboardingSections = {
  style: [`#F9423A`, `#FCA09C`],
  fit: [`#FFC600`, `#FFE27F`],
  payment: [`#CE0058`, `#E67FAB`],
  stylist: [`#FA86C8`, `#FDC3E4`],
};

const stepMap = {
  style: 1,
  stylist: 2,
  fit: 3,
  payment: 4,
};

function Quiz({ match }) {
  const dispatch = useDispatch();
  const isDressingRoomToGo = useSelector((state) =>
    _.chain(state).get('customer.data.dressing_room_to_go_signup').value()
  );
  const isThemeboxSignUp = useSelector((state) =>
    _.chain(state).get('customer.data.themebox_signup').value()
  );
  const isInStore = useSelector((state) =>
    _.chain(state).get('customer.data.registered_in_store').value()
  );
  const drtgSignup = useSelector((state) => state.customer.drtgSignup);

  const shuffle = useSelector((state) =>
    _.chain(state).get('questions.data.shuffle').value()
  );
  const shuffleComplete = useSelector(
    (state) => state.questions.shuffleComplete
  );
  const quizName = useSelector((state) => state.questions.name);

  const version = window.location.search[2];
  const num = +match.params.id;
  let v = quizName && quizName.split('(')[1] ? quizName.split('(')[1][0] : 0;
  if (!version && quizName) {
    history.push(`${BASE_PATH}/quiz/${num}?v${v}`);
  }

  const fastFlow = isThemeboxSignUp || isInStore;

  const fields = useSelector((state) => {
    return (
      state.questions &&
      _.filter(
        state.questions.data.fields,
        fastFlow && !drtgSignup
          ? (f) => f.fast_signup
          : fastFlow && drtgSignup
          ? (f) =>
              f.fast_signup && !_.includes(['subscription_type_group'], f.ref)
          : (f) => !f.exclude_from_onboarding
      )
    );
  });

  const responses = useSelector((state) => state.questions.responses);
  const isLoading = useSelector((state) => state.questions.isLoading);
  const [button, setButton] = useState(false);
  const [hideBackButton, setHideBackButton] = useState(true);

  useEffect(() => {
    dispatch(fetchStyleProfile());
    dispatch(fetchStoreLocations());

    if (num >= 2) {
      setHideBackButton(false);
    }
  }, [dispatch, fields.length]);

  let isValid = () => {
    return !_.some(requiredRefs, (ref) => {
      return _.isEmpty(currentResponses[ref]);
    });
  };

  const step = match.params.id;
  const current =
    (!_.isEmpty(fields) && fields[parseInt(step) - 1]) ||
    fields[parseInt(step) - 2];

  if (!current) return '';

  const getField =
    current.type === `group`
      ? _.map(current.properties.fields, (f) =>
          _.merge(
            f,
            _.pick(current, [
              'section',
              'onboarding_section',
              'exclude_from_onboarding',
            ])
          )
        )
      : current && [current];
  const requiredRefs = _.chain(getField)
    .filter((f) => _.get(f, 'validations.required'))
    .map('ref')
    .value();

  let currentResponses =
    !_.isEmpty(responses) &&
    _.reduce(
      getField,
      (current, field) => {
        return _.merge(current, { [field.ref]: responses[field.ref] });
      },
      {}
    );

  if (isValid(requiredRefs) && !button) {
    setButton(true);
  } else if (!isValid(requiredRefs) && button) {
    setButton(false);
  }

  let val = (value, ref) => {
    setButton(isValid());
    if (_.isString(ref)) {
      setTimeout(() => {
        dispatch(updateResponse(ref, value));
      }, 100);
    }
  };

  let handleStep = () => {
    if (!fastFlow && num === fields.length - 2) {
      dispatch(updateStyleProfile(currentResponses));
      return history.push(`${BASE_PATH}/quiz/delivery`);
    }

    if (!fastFlow && num === fields.length) {
      dispatch(updateStyleProfile(currentResponses));
      tracking.push({
        eventCategory: 'onboarding',
        eventAction: 'provided fit information',
        eventLabel: `completed - Quiz Version ${v}`,
      });
      return history.push(`${BASE_PATH}/payment/info`);
    }
    if (num < fields.length + 1) {
      setButton(false);

      dispatch(updateStyleProfile(currentResponses));
      if (num === 14) {
        history.push(`${BASE_PATH}/quiz/${num + 1}?v${v}`);
      } else {
        history.push(`${BASE_PATH}/quiz/${num + 1}?v${v}`);
      }
    } else {
      dispatch(updateStyleProfile(currentResponses));

      history.push(`${BASE_PATH}/quiz/delivery`);
    }

    if (fastFlow && num === 14) {
      dispatch(updateStyleProfile(currentResponses));
      tracking.push({
        eventCategory: 'onboarding',
        eventAction: 'provided fit information',
        eventLabel: `completed - Quiz Version ${v}`,
      });
      history.push(`${BASE_PATH}/quiz/delivery`);
    }

    if (fastFlow && num === 15) {
      dispatch(updateStyleProfile(currentResponses));
      tracking.push({
        eventCategory: 'onboarding',
        eventAction: 'provided fit information',
        eventLabel: `completed - Quiz Version ${v}`,
      });
      history.push(`${BASE_PATH}/payment/info`);
    }

    if (num >= 1) {
      setHideBackButton(false);
      return;
    }
  };

  let handleStepBack = () => {
    if (num === 2) {
      history.push(`${BASE_PATH}/quiz/${num - 1}`);
      setHideBackButton(true);
      return;
    }
    history.push(`${BASE_PATH}/quiz/${num - 1}`);
  };

  let footerColor = '';
  const getStep = current && current.onboarding_section;
  const actualStep = stepMap && getStep && stepMap[getStep];

  if (current.shuffle && !shuffleComplete) {
    return (
      <StyleQuiz
        nextStep={num}
        currentStep={getStep}
        width={actualStep}
        currentQuestion={step - 1}
        totalQuestions={fields.length}
      />
    );
  } else {
    return (
      <div className={classnames(style.Quiz)}>
        <NavBar
          background='#003b5c'
          text='#003b5c'
          logo={EvereveTrendsendStackedLogoWhite}
          dashboard
          hasLogOut
          centerLogo
          isDressingRoomToGo={isDressingRoomToGo}
        />

        <div className='group group--slim mt2 mb6'>
          {current && current.type !== `statement` && (
            <Fragment>
              {!isDressingRoomToGo && (
                <div className='pl pr pb2'>
                  <ProgressBar
                    color={`#003b5c`}
                    currentStep={getStep}
                    width={actualStep}
                    currentQuestion={step - 1}
                    totalQuestions={fields.length}
                  />
                </div>
              )}
              <SectionHeader
                title={current.title}
                description={
                  current.properties && current.properties.description
                }
                style={{ textAlign: 'center' }}
              />
              {current.attachment && current.attachment.href && (
                <img
                  className='db mla mra mt2 mb2'
                  width='300px'
                  height='auto'
                  src={current.attachment.href}
                  alt={current.title}
                />
              )}
            </Fragment>
          )}

          {isLoading || _.isEmpty(getField) ? (
            <Loading />
          ) : (
            <Fragment>
              <Grid columns={12} gap='12px' justifyContent='center'>
                {getField.map((item) => {
                  const isCarousel =
                    current && _.includes(current.title, `$carousel`);
                  const Question = getQuestionSelector(
                    isCarousel ? `picture_choice_carousel` : item.type
                  );

                  const section = item.onboarding_section;
                  footerColor = onboardingSections[section];

                  return (
                    <Fragment key={item.id}>
                      {current.properties &&
                        current.properties.fields &&
                        item.type !== `short_text` &&
                        item.type !== `dropdown` &&
                        item.type !== `statement` && (
                          <Cell width={12}>
                            <h3 className='mb0 ff-body mt c-gray'>
                              {item.title}
                            </h3>
                          </Cell>
                        )}

                      <Question
                        label={item.title}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.id === `nYZtNuKKsChw`
                            ? `Start typing here...`
                            : item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                        columnWidth={item.id === 'nQuZvHgJqLn7' ? 12 : 6}
                      />
                    </Fragment>
                  );
                })}
              </Grid>

              <Footer
                bgColor={footerColor}
                disableButton={!button && !_.isEmpty(requiredRefs)}
                footerStep={handleStep}
                footerStepBack={handleStepBack}
                hasBackground={true}
                hasBackButton={true}
                hideBackButton={hideBackButton}
                buttonCopy={`Continue`}
                hasArrow
                isQuiz
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Quiz;
