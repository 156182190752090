import * as yup from "yup";

export const schema = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  shipping_line_1: yup.string().required("Required"),
  shipping_line_2: yup.string(),
  shipping_city: yup.string().required("Required"),
  shipping_state: yup.string().required("Required"),
  shipping_postal_code: yup.number().label('ZIP Code').min(5, "Min. 5 characters.").required("Required"),
  shipping_phone: yup.string().min(9, 'Please enter a valid Cell Phone Number').required("Required"),
  email: yup.string().email('Please enter a valid email address').required("Required"),
  current_password: yup.string().label('Current Password').min(8, `Min. 8 characters.`),
  new_password: yup.string().label('New Password').min(8, `Min. 8 characters.`),
  assigned_location_id: yup.string(),
  preferred_stylist: yup.string().nullable()
});
