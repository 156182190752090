import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import Footer from "Footer";
import style from "./ForgotPassword.module.scss";
import classnames from "classnames";
import SubHeader from "../CustomizeOrder/SubHeader";
import ProgressBar from "ProgressBar";
import FormInput from "FormInput";
import Button from "Button";
import forgotPassword from "./forgot_password.json";
import { forgotPasswordSchema, forgotPasswordInitialValues } from "./schema";
import { Grid, Cell } from "styled-css-grid";
import { Formik, Form, Field } from "formik";
import { getReferralCode, getCampaignCode, getCampaignUrl } from '.././utils/cookie';
import history from "../History";
import store from "./../store"
import { createNewPassword } from "./../store/Actions/AuthAction";
import { BASE_PATH } from "./../Config";
import _ from 'lodash';

const forgotPasswordMap = {
  "forgot_password": forgotPassword,
}

const schemaMap = {
  "forgot_assword": forgotPasswordSchema,
}

function ForgotPassword({match}) {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => _.get(state, 'auth.isLoading'));

  function submitForm(values) {
    dispatch(createNewPassword(values.email));
  }

  const path = _.get(match, 'path', '').split(`${BASE_PATH}/`)[1];
  const item = forgotPasswordMap[path];

  return (
    <div className={classnames('group group--slim mt2', [style.ForgotPassword])}>

      <SubHeader
        header={[<span>Forgot your password?</span>, <div className='mt mb2'>No problem!</div>]}
        subheader={item.subheader}
        description={item.description}
        TextAlignCenter
      />

      <Formik
        initialValues={forgotPasswordInitialValues}
        validationSchema={schemaMap[path]}
        onSubmit={submitForm}
      >
        {({ isValid }) => (
          <Form>
            <Grid>
              {item.fields.map(({ label, placeholder, columnWidth, type, name, mask }) => {
                return (
                  <Cell width={columnWidth} key={name} className="mb">
                    <Field
                      name={name}
                      type={type}
                      label={label}
                      mask={mask}
                      placeholder={placeholder}
                      component={FormInput}
                    />
                  </Cell>
                )
              })}
              <Cell width={12} className="mb">
                  <Button
                    variant="Primary"
                    content={isLoading ? 'One moment...' : 'Reset Password'}
                    fullWidth={'Reset Password'}
                    type="submit"
                  />
              </Cell>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ForgotPassword;