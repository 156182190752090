import React from 'react';
import { useSelector } from 'react-redux';
import "./OrderHistory.Module.scss";
import moment from 'moment';
import _ from 'lodash';
import history from "../History";

function OrderBoxItem({order}) {

    const orders = useSelector(state => state.orders.orders);
    function showStatus() {
        // show completed or cancelled orders
        switch (order.status) {
            case "completed":
                return "Completed"
            case "cancelled":
                return "Cancelled"
        }
    }

    const previewImage = _.chain(order.outfits)
      .map('line_items')
      .flatten()
      .find(l => l.image_url && !_.includes(l.image_url, 'img-not-available'))
      .get('image_url')
      .value();

    return (
      <div className="OrderItemInner">
          <div className="OrderTextDiv">
              <h3 className="OrderItemStatus">{showStatus()}</h3>
              <p className="OrderItemDate">Order Number {order.code}</p>
              <p className="OrderItemDate">Order Date {moment(order.expected_delivery_date).format("MM/DD/YYYY")}</p>
          </div>
      </div>
    );
}


export default OrderBoxItem;