import React, { useState, useEffect, Fragment } from "react";
import Button from "Button";
import classnames from "classnames";
import { Grid, Cell } from "styled-css-grid";
import style from "./SwipeableCard.module.scss";
import history from "../History";
import { BASE_PATH, API_BASE } from "./../Config";

const color = {
  dislike: `#F9423A`,
  like: `#FA86C8`,
};

function SwipeableCard({ cards, getValue, reshuffle, isReshuffleComplete, styleProfilePath }) {
  const [cta, setCta] = useState(true);
  let isReshuffle = reshuffle;

  function directionClick(id, image, dir) {
    const getDivs = document.getElementsByClassName([style.SwipeableCardItem]);
    const getFooterDivs = document.getElementsByClassName([
      style.SwipeableCardFooter,
    ]);
    let current = getDivs[getDivs.length - 1];
    let currentFooter = getFooterDivs[getFooterDivs.length - 1];

    if (getDivs.length === 1) {
      if (!isReshuffle) {
        history.push(`${BASE_PATH}/register`);
        setCta(false);
      }
    }

    setTimeout(() => {
      current.remove();
      currentFooter.remove();
    }, 350);

    if (dir === `left`) {
      current.classList.add(style.SwipeableCardItemLeft);
      // currentFooter.classList.add(style.SwipeableCardItemLeft);
      getValue(id, { liked: false, image });
    } else {
      current.classList.add(style.SwipeableCardItemRight);
      // currentFooter.classList.add(style.SwipeableCardItemRight);
      getValue(id, { liked: true, image });
    }
  }

  return (
    <div className={style.SwipeableCard}>
      <ul className={style.SwipeableCardList}>
        {cards.map(({ id, image }) => {
          let src = reshuffle ? `${API_BASE}/product_images/${id}` : image;
          return (
            <Fragment key={id}>
              <li
                className={classnames([style.SwipeableCardItem], {
                  [style.SwipeableCardItemHide]: false,
                })}
                style={{ backgroundImage: `url(${src})` }}
              >
                <img src={src} alt={id} />
              </li>
            </Fragment>
          );
        })}
      </ul>
      {!isReshuffleComplete && styleProfilePath && (
        <Grid columns={12}>
          <Cell width={12} className="shuffle-incomplete">
            <p className='mt2 tac'>Is this your style?</p>
          </Cell>
        </Grid>
      )}
      {cards.map(({ id, image }) => {
        return (
          <Fragment key={id}>
            {cta && (
              <div className={classnames(style.SwipeableCardFooter)}>
                <Button
                  variant="Primary"
                  content={`No`}
                  handleClick={() => directionClick(id, image, "left")}
                />

                <Button
                  variant="Primary"
                  content={`Yes`}
                  handleClick={() => directionClick(id, image, "right")}
                />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export default SwipeableCard;
