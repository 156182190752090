import axios from 'axios';
import {getCookie} from './utils/cookie';
import history from "./History";
import { toast } from 'react-toastify';
import { e, serverErrorsFromActiveRecordErrors } from './utils/errors';
import { API_URL, API_BASE, BASE_PATH } from "./Config";
import _ from "lodash";

// TODO: We'll need to make this configurable when we get our staging/prod envs set up.
const NON_AUTH_PATHS = [
  `${BASE_PATH}/login`,
  `${BASE_PATH}/forgot_password`,
  `${BASE_PATH}/reset_password`,
  `${BASE_PATH}/register`,
  `${BASE_PATH}/quiz/style`,
  `${BASE_PATH}/quiz/delivery`,
  `${BASE_PATH}/schedule-pickup`,
  `${BASE_PATH}/schedule-pickup-in-store`,
  `${BASE_PATH}/trendsend-store-signup`,
  `${BASE_PATH}/quiz/1`,
  `${BASE_PATH}/order-theme-box`,
  `${BASE_PATH}/catalog-qr`,
];

function api() {
  return setInterceptors(axios.create({
    withCredentials: true,
    baseURL: API_URL,
    timeout: 300000,
    headers: {'Accept': 'application/json; version=1'}
  }));
}

function authApi() {
  return setInterceptors(axios.create({
    withCredentials: true,
    baseURL: API_URL,
    timeout: 300000,
    headers: {
      'Accept': 'application/json; version=1',
      'X-CSRF-Token': getCookie('XSRF-TOKEN')
    }
  }));
}


function setInterceptors(instance) {
  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {

    let errors = serverErrorsFromActiveRecordErrors(error.response);

    if (error.response.status === 401 && !_.find(NON_AUTH_PATHS, p => _.includes(window.location.pathname, p))) {
      localStorage.removeItem('userSession');
      localStorage.removeItem('styleProfile');
      if (!localStorage.getItem('redirectTo')) {
        localStorage.setItem('redirectTo', window.location.pathname);
      }

      history.push(`${BASE_PATH}/login`);
    } else if (error.response.status === 401 && error.config.method === 'get' && _.find(NON_AUTH_PATHS, p => _.includes(window.location.pathname, p))) {
      errors = [];
    }
    if (error.response.status !== 404) {
      _.each(errors, (error) => {
        toast.error(e(error));
      });
    }
    return Promise.reject(error);
  });
  return instance;
}


export default {
  //
  // Sessions
  //
  createUserSession: (credentials) => {
    return api().post('/sessions', {
      customer: {
        email: credentials.email,
        password: credentials.password,
      },
    });
  },

  destroyUserSession: (credentials) => {
    return api().delete('/sessions');
  },

  sessionHeartbeat: () => authApi().get('/sessions/ttl'),

  //
  // Passwords
  //
  createNewPassword: (email) => {
    return api().post('/passwords', { customer: { email: email } });
  },

  updatePassword: (resetPasswordData) => {
    return api().put('/passwords', { customer: resetPasswordData });
  },

  //
  // Customers
  //
  getCustomer: function () {
    return authApi().get(`/account`);
  },

  registerCustomer: function (customerData) {
    return authApi().post(`/registrations`, { customer: customerData });
  },

  changePassword: function (customerData) {
    return authApi().put(`/registrations`, { customer: customerData });
  },

  updateCustomer: function (customerData) {
    return authApi().patch(`/account`, {
      customer: customerData,
    });
  },

  validateSignupCode: function (signup_code) {
    return authApi().get(`/account/validate_signup_code/${signup_code}`);
  },

  skipABox: function () {
    return authApi().patch(`/account/skip_a_box`);
  },

  unSkipABox: function () {
    return authApi().patch(`/account/unskip_a_box`);
  },


  //
  // Themeboxes
  //
  getThemeboxes: function () {
    return authApi().get(`${API_BASE}/current_themeboxes`);
  },

  //
  // Orders
  //
  getOrders: function (params) {
    return authApi().get('/orders', { params: params });
  },

  getOrder: function (orderId) {
    return authApi().get(`/orders/${orderId}`);
  },

  createOrder: function (orderData) {
    return authApi().post(`/orders`, { order: orderData });
  },

  updateOrder: function (orderId, orderData) {
    return authApi().patch(`/orders/${orderId}`, { order: orderData });
  },

  cancelOrder: function (orderId) {
    return authApi().post(`/orders/${orderId}/cancel`);
  },

  addCouponToInvoice: function (invoiceId, couponCode) {
    return authApi().post(`/invoices/${invoiceId}/coupons`, {
      code: couponCode,
    });
  },

  removeCouponFromInvoice: function (invoiceId) {
    return authApi().delete(`/invoices/${invoiceId}/coupons`);
  },

  addGiftCardToInvoice: function (invoiceId, giftCardCode) {
    return authApi().post(`/invoices/${invoiceId}/gift_cards`, {
      code: giftCardCode,
    });
  },

  removeGiftCardFromInvoice: function (invoiceId) {
    return authApi().delete(`/invoices/${invoiceId}/gift_cards`);
  },

  checkoutOrder: function (invoiceId) {
    return authApi().post(`/invoices/${invoiceId}/checkout`);
  },

  availableExchangeSizes: function (lineItemId) {
    return authApi().get(`/invoices/available_exchange_sizes/${lineItemId}`);
  },

  extendCheckout: function (orderId) {
    return authApi().patch(`/orders/${orderId}/extend`);
  },

  //
  // Suggested Items
  //

  addSuggestedItemsClickTracking: function (suggestedItemId) {
    return authApi().get(`/suggested_items/${suggestedItemId}/click`);
  },

  //
  // Invoice
  //

  getOrderInvoice: function (orderId) {
    return authApi().get(`/orders/${orderId}/invoices/current`);
  },

  createOrderInvoice: function (orderId) {
    return authApi().post(`/orders/${orderId}/invoices`);
  },

  //
  // Shipping addresses
  //
  createShippingAddress: function (addressData) {
    return authApi().post(`/shipping_addresses`, {
      shipping_address: addressData,
    });
  },

  updateShippingAddress: function (addressId, addressData) {
    return authApi().patch(`/shipping_addresses/${addressId}`, {
      shipping_address: addressData,
    });
  },

  deleteShippingAddress: function (addressId) {
    return authApi().delete(`/shipping_addresses/${addressId}`);
  },

  //
  // Style profile
  //
  currentStyleProfile: function (version = 'current') {
    return authApi().get(`/style_profiles/current?version=${version}`);
  },

  updateStyleProfile: function (id, data) {
    return authApi().patch(`/style_profiles/${id}`, { responses: data });
  },

  createImageUploadUrl: function (id, filename) {
    return authApi().post(`/style_profiles/${id}/upload_urls`, {
      filename: filename,
    });
  },

  //
  // Credit cards
  //
  createCreditCard: function (billingInfo) {
    return authApi().post(`/credit_cards`, billingInfo);
  },

  getCreditCard: function () {
    return authApi().get(`/credit_card`);
  },

  updateCreditCard: function (data) {
    return authApi().patch(`/credit_cards`, data);
  },

  // Holidays
  getHolidays: function () {
    return authApi().get(`/holidays`);
  },

  //
  // Locations
  //
  getLocations: function (params) {
    return authApi().get('/locations', { params });
  },

  getEstimatedDeliveryDateForLocation: function (locationId) {
    return authApi().get(`/locations/${locationId}/estimated_delivery_date`);
  },

  //
  // Referrals
  //

  getReferrals: function (params) {
    return authApi().get('/referrals', { params });
  },
};

export function stubResponse(resp) {
  return new Promise(function(resolve, reject) {
    window.setTimeout(function() {
      if(Math.random() <= 0.05) { // Fake an error every few requests
        reject({errors: [{
          "code": "unexpected_error",
          "message": "Unexpected error"
        }]});
      } else {
        resolve({data: resp});
      }
    }, Math.random() * 1000);
  });
}
