import * as yup from "yup";
import valid from 'card-validator'; 

export const billingSchema = yup.object().shape({
  billing_name: yup.string(),
  billing_credit_card_number: yup.string()
    .label('Card Number')
    .min(15, 'Please enter a valid card number')
    .test('test-CC-number', // this is used internally by yup
    'Credit Card number is invalid', //validation message
     value => valid.number(value).isValid)
    .required("Required"),
  billing_expiration: yup.string().typeError('Not a valid expiration date. Example: MM/YY')
    .max(5, 'Not a valid expiration date. Example: MM/YY')
    .matches(
      /([0-9]{2})\/([0-9]{2})/,
      'Not a valid expiration date. Example: MM/YY'
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid expiration date.',
      expirationDate => {
        if (!expirationDate) {
          return false
        }

        const today = new Date()
        const monthToday = today.getMonth() + 1
        const yearToday = today
          .getFullYear()
          .toString()
          .substr(-2)

        const [expMonth, expYear] = expirationDate.split('/')

        if (Number(expYear) < Number(yearToday)) {
          return false
        } else if (
          Number(expMonth) < monthToday &&
          Number(expYear) <= Number(yearToday)
        ) {
          return false
        }

        return true
      }
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid Expiration Month',
      expirationDate => {
        if (!expirationDate) {
          return false
        }

        const [expMonth] = expirationDate.split('/')

        if (Number(expMonth) > 12) {
          return false
        }

        return true
      }
    )
    .required('Expiration date is required'),

  billing_cvv: yup.string().required("Required"),
  billing_street_address1: yup.string().required("Required"),
  billing_street_address2: yup.string().nullable(),
  billing_city: yup.string().required("Required"),
  billing_state: yup.string().required("Required"),
  billing_zip_code: yup.string().min(5).matches(/(^[0-9]+$)/, 'Please enter a valid zip code.')
    .label('ZIP Code')
    .typeError('Please enter a valid zip code.')
    .required("Required"),
  billing_phone: yup.string().min(12, 'Please enter a valid Cell Phone Number').required("Required")
})

export const shippingSchema = yup.object().shape({
  shipping_line_1: yup.string().required("Required"),
  shipping_line_2: yup.string().nullable(),
  shipping_city: yup.string().required("Required"),
  shipping_state: yup.string().required("Required"),
  shipping_postal_code: yup.string().min(5).matches(/(^[0-9]+$)/, 'Please enter a valid zip code.')
  .label('ZIP Code')
  .typeError('Please enter a valid zip code.')
  .required("Required"),
  shipping_phone: yup.string().min(12, 'Please enter a valid Cell Phone Number').required("Required")
})

export const billingInitialValues = {
  billing_name: '',
  billing_credit_card_number: '',
  billing_expiration: '',
  billing_cvv: '',
  billing_street_address1: '',
  billing_street_address2: '',
  billing_city: '',
  billing_state: '',
  billing_zip_code: '',
  billing_phone: ''
}

export const shippingInitialValues = {
  shipping_line_1: '',
  shipping_line_2: '',
  shipping_city: '',
  shipping_state: '',
  shipping_postal_code: '',
  shipping_phone: ''
}