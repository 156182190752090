import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./Dashboard.module.scss";
import heroImage from "../images/Dash-Welcome.svg";

function Welcome({ passMember }) {
  const customer = useSelector((state) => state.customer.data);

  return (
    <div className={style.Welcome}>
      
      <h1 className={passMember ? style.marginTop : ""}>
        Hi <span>{customer.first_name}!</span>
      </h1>
    </div>
  );
}

export default Welcome;
