import {
  HOLIDAYS_REQUESTED,
  HOLIDAYS_RECEIVED,
  HOLIDAYS_ERROR,
} from "../Actions/CustomerAction";

const initState = {
  company_holidays: [],
  mail_holidays: [],
  isLoading: false,
  errors: [],
};

export default function (state = initState, action = '') {
  switch (action.type) {
    case HOLIDAYS_RECEIVED:
      return {
        ...state,
        pickup_holidays: action.data.pickup_holidays,
        company_holidays: action.data.company_holidays,
        mail_holidays: action.data.mail_holidays,
        isLoading: false,
        errors: []
        };
    default:
      return state;
  }
}
