import React, { Fragment, useEffect } from "react";
import style from "../Quiz/Quiz.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { US_STATES } from "./../utils/us-states";
import Footer from "Footer";
import SectionHeader from "SectionHeader";
import FormInput from "FormInput";
import { Grid, Cell } from "styled-css-grid";
import dropdownStyle from "./../Dropdown/Dropdown.module.scss";
import Button from "Button";
import { Formik, Form, Field } from "formik";
import history from "../History";
import classnames from "classnames";
import { BASE_PATH } from "./../Config";
import queryString from 'query-string';
import payment from "./payment.json";
import { billingSchema } from "./schema";
import { updateBillingInfo, fetchBillingInfo, createBillingInfo } from "./../store/Actions/CustomerAction";
import _ from "lodash";

function ChangePayment({ location }) {

  const dispatch = useDispatch();
  const USStates = _.map(US_STATES, s => <option key={`states-${s.value}`} value={s.value}>{s.label}</option>);
  const errors = useSelector(state => _.get(state, 'customer.errors'));
  const billingInitialValues = useSelector(state => _.get(state, 'customer.billingInfo.cardholder', {}))
  const isFirstTimer = useSelector(state => _.get(state, 'customer.data.first_timer', {}));
  const currentOrder = useSelector((state) => state.orders.currentOrder);

  if (_.size(billingInitialValues.phone) === 10) {
    let phone = billingInitialValues.phone;
    billingInitialValues.phone = `${phone.substring(0,3)}-${phone.substring(3,6)}-${phone.substring(6,10)}`;
  }

  const initial_billing_values = !_.isEmpty(billingInitialValues);
  const previousPath = location.pathname && location.pathname;
  const options = { initial_billing_values, previousPath: previousPath };

  useEffect(() => {
    !initial_billing_values && dispatch(fetchBillingInfo());
  }, [dispatch]);

  function submitForm(values) {
    initial_billing_values ? dispatch(updateBillingInfo(values, (location.navProps !== undefined ? `${BASE_PATH}${location.navProps.prevPath}` : `${BASE_PATH}/dashboard`), previousPath)) :
    dispatch(createBillingInfo(values, (location.navProps !== undefined ? `${BASE_PATH}${location.navProps.prevPath}` : `${BASE_PATH}/dashboard`), options));
  }

  if (isFirstTimer && currentOrder.id) {
    history.push('/')
  }

  return (
    <div className='group group--slim mt2 mb2'>
      <SectionHeader
        title='Change Payment Method'
        description={
          <>
            <div className='mb'>
              Please enter a valid credit card. We do not accept debit cards.
            </div>
            <div>
              Have a promo code? Enter at time of checkout after you receive
              your box.
            </div>
          </>
        }
      />

      <Formik
        isInitialValid={false}
        initialValues={billingInitialValues}
        validationSchema={billingSchema}
        onSubmit={submitForm}
      >
        {({ isValid }) => (
          <Form>
            <Grid>
              {payment.map(
                ({ label, placeholder, columnWidth, type, name, mask }) => {
                  return (
                    <Fragment key={`${name}-fragment`}>
                      <Cell
                        width={columnWidth}
                        key={name}
                        className='mb'
                        label={label}
                      >
                        {name === 'state' ? (
                          <Field
                            className={dropdownStyle.Dropdown}
                            as='select'
                            name={name}
                          >
                            <option>Select Your State</option>
                            {USStates}
                          </Field>
                        ) : (
                          <Field
                            name={name}
                            type={type}
                            label={label}
                            mask={mask}
                            placeholder={placeholder}
                            component={FormInput}
                          />
                        )}
                        {name === 'billing_credit_card_number' &&
                        _.find(errors, (e) =>
                          _.includes(e.message, 'We do not accept debit cards')
                        ) ? (
                          <span
                            style={{ top: -30, position: 'relative' }}
                            className='mb c-error'
                          >
                            We do not accept debit cards.
                          </span>
                        ) : (
                          ''
                        )}
                      </Cell>
                    </Fragment>
                  );
                }
              )}
            </Grid>
            <Footer
              bgColor={[`#CE0058`, `#E67FAB`]}
              disableButton={!isValid}
              buttonType='submit'
              buttonCopy='Save New Card'
              hasCancelButton={true}
              redirectPath={
                location.navProps !== undefined
                  ? location.navProps.prevPath
                  : ''
              }
              previousPath={location.pathname}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePayment;
