import React, { useState } from "react";
import style from "./FormInput.module.scss";
import { Cell } from "styled-css-grid";
import Icon from "Icon";
import classnames from "classnames";
import InputMask from 'react-input-mask'
import PasswordOff from "images/password-off.svg";
import PasswordOn from "images/password-on.svg";

function FormInput({
  getValue,
  label,
  placeholder,
  type,
  questionRef,
  initialValue,
  name,
  form: { touched, errors },
  field,
  mask,
  min,
  max,
  onSubmit,
  maxLength,
  validateField,
  isReadOnly,
  noBorderBottom
}) {


  const [passwordField, setPasswordField] = useState(false);
  const togglePassword = () => setPasswordField(!passwordField);

  function updateField(e) {
    getValue(e.target.value, questionRef);
  }

  const inputClasses = classnames(
    style.FormInputField, noBorderBottom == true && 'nb', isReadOnly && style.isReadOnly,
    {
      [style.FormInputErrorField]: errors && errors[field.name] && touched[field.name]
    }
  )

  return (
    <Cell width={12}>
      <label className={style.FormInput}>
        {!mask ? (
          type !== `password` ? (
            <input
              className={inputClasses}
              label={label}
              onBlur={getValue && updateField}
              onKeyDown={(e) => e.keyCode === 13 && onSubmit && onSubmit(e.target.value, questionRef)}
              name={name}
              type={type}
              defaultValue={initialValue}
              placeholder={placeholder ? placeholder : label}
              min={min}
              max={max}
              maxLength={maxLength}
              data-hj-whitelist=""
              {...field}
              onKeyUp={validateField}
              readOnly={isReadOnly}
            />
          ) : (
              <input
                className={inputClasses}
                onBlur={getValue && updateField}
                onKeyDown={(e) => e.keyCode === 13 && onSubmit && onSubmit(e.target.value, questionRef)}
                name={name}
                type={passwordField ? `text` : `password`}
                placeholder={placeholder}
                defaultValue={initialValue}
                {...field}
              />
            )
        ) : (
            <InputMask
              mask={mask}
              maskChar={null}
              className={inputClasses}
              onBlur={getValue && updateField}
              name={name}
              type={type}
              placeholder={placeholder}
              defaultValue={initialValue}
              {...field}
            >
              {(inputProps) => <input {...inputProps} />}
            </InputMask>
          )}

        <span className={style.FormInputLabel}>
          {placeholder || label}
        </span>

        {type === 'password' ? <div className={style.FormInputAdditionalCopy}>Password must be at least 8 characters</div> : ''}

        {type === `password` &&
          <div onClick={togglePassword} className={style.FormInputPasswordToggle}>
          {passwordField ?
            <img src={PasswordOn} alt="Hide Password" />
            :
            <img src={PasswordOff} alt="Hide Password" />
          }
          </div>
        }

        {touched && touched[field.name] &&
          errors && errors[field.name] &&
          type !== `password` &&
          <div className={style.FormInputErrorIcon}>
            <Icon icon="error" width={20} height={20} color="#EA2323" />
          </div>
        }
      </label>

      {touched && touched[field.name] &&
        errors && errors[field.name] &&
        <span className={style.FormInputErrorMessage}>{errors[field.name]}</span>
      }
    </Cell>
  );
}

export default FormInput;
