import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import style from '../Quiz/Quiz.module.scss';
import styles from '../Dropdown/Dropdown.module.scss';
import inputStyles from '../FormInput/FormInput.module.scss';
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from 'credit-card-type';
import './AccountManagement.Module.scss';
import Footer from 'Footer';
import SectionHeader from 'SectionHeader';
import ProgressBar from 'ProgressBar';
import FormInput from 'FormInput';
import Dropdown from 'Dropdown';
import { Grid, Cell } from 'styled-css-grid';
import { Formik, Form, Field } from 'formik';
import history from '../History';
import classnames from 'classnames';
import Button from 'Button';
import Header from '../Dashboard/Header.jsx';
import line from '../images/Tile/StyleProfile/linePink.svg';
import contact from './account_contact.json';
import password from './account_password.json';
import shipping from './account_shipping.json';
import EEstore from './account_store.json';
import name from './account_name.json';
import { schema } from './schema';
import {
  fetchBillingInfo,
  updateCustomerData,
  fetchStoreLocations,
} from './../store/Actions/CustomerAction';
import _ from 'lodash';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';
import { NavLink } from 'react-router-dom';
import cardImage from 'images/card.svg';
import { BASE_PATH } from './../Config';
import check from '../images/Tile/StyleProfile/Check.svg';

const color = {
  color: '#003b5c',
};

function AccountManagement() {
  const dispatch = useDispatch();

  function submitForm(values) {
    dispatch(updateCustomerData(values, `${BASE_PATH}/dashboard`));
  }

  const customer = useSelector((state) => state.customer.data);
  const currentOrder = useSelector((state) => state.orders.currentOrder);

  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  const ccNumber = useSelector((state) =>
    _.chain(state)
      .get('customer.billingInfo.card_info.credit_card_number', '')
      .toString()
      .value()
      .slice(-4)
  );

  const storeLocations = useSelector((state) => state.customer.storeLocations);

  const recommendedStoreLocation = useSelector(
    (state) => state.customer.recommendedStoreLocation
  );

  const isSubmitting = useSelector((state) => state.customer.isLoading);

  useEffect(() => {
    dispatch(fetchBillingInfo());
    dispatch(fetchStoreLocations());
  }, [dispatch]);

  const sortedLocations = _.orderBy(storeLocations, ['address_state'], ['asc']);

  if (_.isEmpty(customer) || _.isEmpty(customer)) return '';

  return (
    <div>
      <NavBar
        text='white'
        background="#003b5c"
        logo={EvereveTrendsendStackedLogoWhite}
        profile={profile}
        logOut={logOut}
      />
      <div className='group group--slim mt2 mb2'>
        <Header
          header='Purchase History'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />
        <Grid>
          <Cell width={12}>
            <div>
              <NavLink
                className='editField Border'
                to={`${BASE_PATH}/account/purchase-history`}
              >
                View Your Purchase History
              </NavLink>
            </div>
          </Cell>
        </Grid>
        <Header
          header='Stylist Notes'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />
        <Grid>
          <Cell width={12}>
            <div>
              <NavLink
                className='editField Border'
                to={`${BASE_PATH}/account/stylist-notes`}
              >
                View Your Outfits and Stylist Notes
              </NavLink>
            </div>
          </Cell>
        </Grid>
        <Header
          header='Account Management'
          subheader='Edit your billing and shipping details, your contact information, or EVEREVE Store.'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />
        <Formik
          isInitialValid={true}
          validationSchema={schema}
          initialValues={{ ...shippingAddress, ...customer }}
          onSubmit={submitForm}
        >
          {({ isValid }) => (
            <Form className='accountManagementForm'>
              <SectionHeader title='Name' description='' />
              <Grid>
                {name.map(
                  ({ label, placeholder, columnWidth, type, name, mask }) => {
                    return (
                      <Cell width={columnWidth} key={name} className='mb2'>
                        <Field
                          name={name}
                          type={type}
                          label={label}
                          mask={mask}
                          placeholder={placeholder}
                          component={FormInput}
                        />
                      </Cell>
                    );
                  }
                )}
              </Grid>
              <SectionHeader title='Shipping Address' description='' />
              <Grid>
                {shipping.map(
                  (
                    { label, placeholder, columnWidth, type, name, mask },
                    index
                  ) => {
                    if (index === 0) {
                      return (
                        <Cell width={columnWidth} key={name} className='mb2'>
                          <Field
                            name={name}
                            type={type}
                            label={label}
                            mask={mask}
                            placeholder={placeholder}
                            component={FormInput}
                            isReadOnly
                            noBorderBottom={true}
                          />
                        </Cell>
                      );
                    }
                  }
                )}
                <Cell width={12} key={name}>
                  <NavLink
                    className='editField Border'
                    to={{
                      pathname: `${BASE_PATH}/edit/address`,
                      navProps: {
                        prevPath: `/account/management`,
                      },
                    }}
                  >
                    Change Shipping Address
                  </NavLink>
                </Cell>
              </Grid>
              <SectionHeader title='Contact Details' description='' />
              <Grid>
                {contact.map(
                  ({ label, placeholder, columnWidth, type, name, mask }) => {
                    return (
                      <Cell width={columnWidth} key={name} className='mb2'>
                        <Field
                          name={name}
                          type={type}
                          label={label}
                          mask={mask}
                          placeholder={placeholder}
                          component={FormInput}
                        />
                      </Cell>
                    );
                  }
                )}
              </Grid>

              <SectionHeader title='Payment Method' description='' />
              <Grid>
                <Cell width={12}>
                  <div className='paymentMethod mb4'>
                    <img
                      className='dib vam mr-'
                      width='25px'
                      src={cardImage}
                      alt='Card'
                    />
                    <span className='cardInfo'>•••• •••• •••• {ccNumber}</span>
                    <img
                      className='check dib vam mr-'
                      width='25px'
                      src={check}
                      alt='Card'
                    />
                  </div>
                  <div>
                  {
                    (customer.first_timer && currentOrder.id) ? <p style={{textAlign: 'center', fontStyle: 'italic'}}>Contact 1 (877)290-6262 to Update Payment Information</p> : 
                      ( 
                        <NavLink
                          className='editField Border'
                          to={`${BASE_PATH}/change/payment`}
                        >
                          Change Payment Method
                        </NavLink> 
                      )
                  }
                    
                  </div>
                </Cell>
              </Grid>

              <SectionHeader title='Update Password' description='' />
              <Grid>
                {password.map(
                  ({ label, placeholder, columnWidth, type, name, mask }) => {
                    return (
                      <Cell width={columnWidth} key={name} className='mb2'>
                        <Field
                          name={name}
                          type={type}
                          label={label}
                          mask={mask}
                          placeholder={placeholder}
                          component={FormInput}
                        />
                      </Cell>
                    );
                  }
                )}
              </Grid>

              <SectionHeader title='EVEREVE Store' />
              <Grid>
                <Cell width={12}>
                  <Field
                    className={styles.Dropdown}
                    name='assigned_location_id'
                    component='select'
                    placeholder='EVEREVE Store'
                  >
                    {sortedLocations.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.address_state}, {item.address_city} -{' '}
                          {item.name}
                        </option>
                      );
                    })}
                  </Field>
                </Cell>
                <Cell width={12} className='mb2'>
                  <Field
                    name='preferred_stylist'
                    type='text'
                    label='Preferred Stylist'
                    placeholder='Preferred Stylist'
                    component={FormInput}
                  />
                </Cell>
              </Grid>
              <Footer
                bgColor={[`#CE0058`, `#E67FAB`]}
                buttonType='submit'
                buttonCopy={`Save Changes`}
                disabled={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AccountManagement;
