import {
  THEMEBOXES_REQUESTED,
  THEMEBOXES_RECEIVED,
  THEMEBOXES_ERROR,
} from "../Actions/ThemeboxActions";

const initState = {
  themeboxes: {},
  isLoading: false,
  errors: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case THEMEBOXES_RECEIVED:
      return {
        ...state,
        themeboxes: action.themeboxes.results,
        isLoading: false,
        errors: []
        };
    case 'THEMEBOX_SELECTED':
      return {
        ...state,
        selectedThemebox: action.themebox
      }
    default:
      return state;
  }
}
