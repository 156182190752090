import api from "../../api";
import { serverErrors, UNEXPECTED_ERROR } from "./../../utils/errors";
import {fetchOrders, boxRequested} from "./OrderAction";
import {fetchStyleProfile, updateStyleProfile} from "./QuestionAction";
import {loginSubmitted} from "./AuthAction";
import history from "./../../History";
import { toast } from 'react-toastify';
import * as tracking from './../../utils/gt-tracking';
import { BASE_PATH } from "./../../Config";
import _ from "lodash";

const ACCOUNT_PARAMS = ['email', 'password', 'password_confirmation', 'first_name', 'last_name', 'phone', 'subscription_type', 'assigned_location_id', 'preferred_stylist'];
const SHIPPING_PARAMS = ['id', 'name', 'phone', 'fax', 'line_1', 'line_2', 'city', 'state', 'postal_code', 'country', 'is_primary'];
const BILLING_PARAMS = ['name', 'street_address1', 'street_address2', 'city', 'state', 'zip_code', 'country', 'phone'];

export const CUSTOMER_DATA_REQUESTED   = 'CUSTOMER_DATA_REQUESTED';
export const CUSTOMER_DATA_RECEIVED    = 'CUSTOMER_DATA_RECEIVED';
export const CUSTOMER_DATA_ERROR       = 'CUSTOMER_DATA_ERROR';

export function loadCustomerData(){
  return function(dispatch, getState) {


    dispatch({type: CUSTOMER_DATA_REQUESTED});
    api.getCustomer()
      .then(resp => {
        dispatch({type: CUSTOMER_DATA_RECEIVED, data: resp.data});
        dispatch(fetchOrders());
        localStorage.setItem('userSession', JSON.stringify(resp));
        // redirectIfProfileIncomplete(resp.data);
      })
      .catch(errorResp => {
        let state = getState();

        dispatch({type: CUSTOMER_DATA_ERROR, errors: serverErrors(errorResp)})
      });
  }
}

export const UPDATING_CUSTOMER_DATA           = 'UPDATING_CUSTOMER_DATA';
export const UPDATING_CUSTOMER_DATA_SUCCESS   = 'UPDATING_CUSTOMER_DATA_SUCCESS';
export const UPDATING_CUSTOMER_DATA_ERROR     = 'UPDATING_CUSTOMER_DATA_ERROR';

export function updateCustomerData(data, redirectTo) {
  return function (dispatch) {
    dispatch({ type: UPDATING_CUSTOMER_DATA, data: _.pick(data, ACCOUNT_PARAMS) });

    if (data.new_password) {
      data.password = data.new_password;
      data.password_confirmation = data.new_password;
    }
    api.updateCustomer(_.pick(data, ACCOUNT_PARAMS))
      .then(resp => {
        dispatch({ type: UPDATING_CUSTOMER_DATA_SUCCESS, data: resp.data });
        if (data.new_password) {
          dispatch(loginSubmitted({ email: data.email, password: data.new_password }))
        } else {
          if (redirectTo) history.push(redirectTo);
        }
      }, errorResp => {
        dispatch({
          type: UPDATING_CUSTOMER_DATA_ERROR,
          errors: serverErrors(errorResp)
        });
      });


    let shippingData = _.pick(_.reduce(_.pickBy(data, (val, key) => { return _.includes(key, "shipping_") } ), (current, val, key) => { current["is_primary"] = true; current[key.replace("shipping_", "")] = val; return current }, {}), SHIPPING_PARAMS);

    if (!_.isEmpty(shippingData)) {
      if (shippingData.id) {
        dispatch(updateShippingAddress(shippingData.id, _.pick(shippingData, SHIPPING_PARAMS)));
      } else {
        dispatch(createShippingAddress(shippingData));
      }
    }

    let billingData = _.pick(_.reduce(_.pickBy(data, (val, key) => { return _.includes(key, "billing_") } ), (current, val, key) => { current[key.replace("billing_", "")] = val; return current }, {}), BILLING_PARAMS);
    if (!_.isEmpty(billingData)) {
      dispatch(updateBillingInfo(billingData));
    }

  }
}


export const SUBSCRIPTION_UPDATE_REQUEST = 'SUBSCRIPTION_UPDATE_REQUEST';
export const SUBSCRIPTION_UPDATE_SUCCESS = 'SUBSCRIPTION_UPDATE_SUCCESS';
export const SUBSCRIPTION_UPDATE_ERROR   = 'SUBSCRIPTION_UPDATE_ERROR';

export function subscriptionUpdated(subscription_type, redirectTo, skipBoxCallback) {
  return function(dispatch, getState) {
    let prevSubscriptionType = _.get(getState(), 'customer.data.subscription_type')
    dispatch({type: SUBSCRIPTION_UPDATE_REQUEST, subscription_type});
    api.updateCustomer({subscription_type})
      .then(resp => {
        dispatch({type: SUBSCRIPTION_UPDATE_SUCCESS});
        dispatch({type: CUSTOMER_DATA_RECEIVED, data: resp.data });

        if (prevSubscriptionType && prevSubscriptionType === 'subscription_on_demand' && subscription_type !== 'subscription_on_demand' && resp.data.styling_fee_override_code === '2020_one_dollar_styling_fee') toast.success("Success! As long as you remain on a subscription, you'll be charged a $1 styling fee on each box through 2020.")

        if (skipBoxCallback) {
          skipBoxCallback === 'skip'
            && dispatch(skipABox())
        }

        if (redirectTo) history.push(redirectTo);
      }, errorResp => {
        dispatch({type: SUBSCRIPTION_UPDATE_ERROR, errors: serverErrors(errorResp)});
      });
   };
}


export const STORE_LOCATIONS_REQUESTED             = 'STORE_LOCATIONS_REQUESTED';
export const STORE_LOCATIONS_RECEIVED              = 'STORE_LOCATIONS_RECEIVED';
export const STORE_LOCATIONS_RECEIVED_ERROR        = 'STORE_LOCATIONS_RECEIVED_ERROR';

export function fetchStoreLocations(params) {
  return function(dispatch){
    dispatch({type: STORE_LOCATIONS_REQUESTED});

    api.getLocations(params)
      .then(resp => {
        dispatch({
          type: STORE_LOCATIONS_RECEIVED,
          storeLocations: resp.data.locations
        });
      }, errorResp => {
        dispatch({
          type: STORE_LOCATIONS_RECEIVED_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}


export const SHIPPING_ADDRESS_REQUESTED             = 'SHIPPING_ADDRESS_REQUESTED';
export const SHIPPING_ADDRESS_RECEIVED              = 'SHIPPING_ADDRESS_RECEIVED';
export const SHIPPING_ADDRESS_RECEIVED_ERROR        = 'SHIPPING_ADDRESS_RECEIVED_ERROR';

export function fetchShippingAddress(addressId) {
  return function(dispatch){
    dispatch({type: SHIPPING_ADDRESS_REQUESTED});

    api.getCustomer()
      .then(resp => {
        dispatch({
          type: SHIPPING_ADDRESS_RECEIVED,
          data: _.find(resp.data.shipping_addresses, {id: addressId})
        });
      }, errorResp => {
        dispatch({
          type: SHIPPING_ADDRESS_RECEIVED_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}


export const CREATE_SHIPPING_ADDRESS_REQUESTED      = 'CREATE_SHIPPING_ADDRESS_REQUESTED';
export const CREATE_SHIPPING_ADDRESS_RECEIVED       = 'CREATE_SHIPPING_ADDRESS_RECEIVED';
export const CREATE_SHIPPING_ADDRESS_RECEIVED_ERROR = 'CREATE_SHIPPING_ADDRESS_RECEIVED_ERROR';

export function createShippingAddress(data, redirectTo) {
  return function(dispatch, getState){
    let name = `${_.get(getState(), 'customer.data.first_name')} ${_.get(getState(), 'customer.data.last_name')}`;
    data = _.merge(data, {name});
    dispatch({type: CREATE_SHIPPING_ADDRESS_REQUESTED, data: data});

    api.createShippingAddress(data)
      .then(resp => {
        dispatch({type: CREATE_SHIPPING_ADDRESS_RECEIVED, data: data});
        redirectTo ? history.push(redirectTo) : history.push(`${BASE_PATH}/payment/billing`);
      }, errorResp => {
        dispatch({
          type: CREATE_SHIPPING_ADDRESS_RECEIVED_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}


export const UPDATE_SHIPPING_ADDRESS_REQUESTED      = 'UPDATE_SHIPPING_ADDRESS_REQUESTED';
export const UPDATE_SHIPPING_ADDRESS_RECEIVED       = 'UPDATE_SHIPPING_ADDRESS_RECEIVED';
export const UPDATE_SHIPPING_ADDRESS_RECEIVED_ERROR = 'UPDATE_SHIPPING_ADDRESS_RECEIVED_ERROR';

export function updateShippingAddress(addressId, data, redirectTo) {
  return function(dispatch, getState){
    dispatch({type: UPDATE_SHIPPING_ADDRESS_REQUESTED, data: data});
    let currentOrderStatus = _.get(getState(), 'orders.currentOrder.status');
    api.updateShippingAddress(addressId, data)
      .then(resp => {
        dispatch({type: UPDATE_SHIPPING_ADDRESS_RECEIVED, data: resp.data});
        if (_.includes(['ready_to_ship', 'ready_to_pick_up', 'shipped_to_customer'], currentOrderStatus)) {
          toast.success("Updates made to your address will be in effect for future orders. Labels have been printed for your current Trendsend order. Please call us at 1-877-290-6262 if you need to update your shipping address and we will do our best to change it.", {autoClose: false})
        }
        if (redirectTo) {
          history.push(redirectTo)
        }
      }, errorResp => {
        dispatch({
          type: UPDATE_SHIPPING_ADDRESS_RECEIVED_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}

export function editShippingAddress(data, redirectTo){
  return function(dispatch, getState){
    dispatch({type: UPDATE_SHIPPING_ADDRESS_REQUESTED, data: _.pick(data, SHIPPING_PARAMS)});

    let shippingData = _.pick(_.reduce(_.pickBy(data, (val, key) => { return _.includes(key, "shipping_") } ), (current, val, key) => { current["is_primary"] = true; current[key.replace("shipping_", "")] = val; return current }, {}), SHIPPING_PARAMS);

    if (!_.isEmpty(shippingData)) {
      if (shippingData.id) {
        dispatch(updateShippingAddress(shippingData.id, _.pick(shippingData, SHIPPING_PARAMS), `${BASE_PATH}${redirectTo}`));

      } else {
        dispatch(createShippingAddress(shippingData, `${BASE_PATH}${redirectTo}`));
      }
    }
  }
}

export function makeDefaultShippingAddress(addressId, data) {
  return function(dispatch){
    dispatch({type: UPDATE_SHIPPING_ADDRESS_REQUESTED, data: data});

    api.updateShippingAddress(addressId, {is_primary: true})
      .then(resp => {
        dispatch({type: UPDATE_SHIPPING_ADDRESS_RECEIVED, data: data});
      }, errorResp => {
        dispatch({
          type: UPDATE_SHIPPING_ADDRESS_RECEIVED_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}


export const DELETE_SHIPPING_ADDRESS_REQUEST        = 'DELETE_SHIPPING_ADDRESS_REQUEST';
export const DELETE_SHIPPING_ADDRESS_SUCCESS        = 'DELETE_SHIPPING_ADDRESS_SUCCESS';
export const DELETE_SHIPPING_ADDRESS_ERROR          = 'DELETE_SHIPPING_ADDRESS_ERROR';

export function deleteShippingAddress(addressId) {
  return function(dispatch){
    dispatch({type: DELETE_SHIPPING_ADDRESS_REQUEST});

    api.deleteShippingAddress(addressId)
      .then(resp => {
        dispatch({type: DELETE_SHIPPING_ADDRESS_SUCCESS});
      }, errorResp => {
        dispatch({
          type: DELETE_SHIPPING_ADDRESS_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}


export const CREATE_PAYMENT_DATA_REQUESTED      = 'CREATE_PAYMENT_DATA_REQUESTED';
export const CREATE_PAYMENT_DATA_RECEIVED       = 'CREATE_PAYMENT_DATA_RECEIVED';
export const CREATE_PAYMENT_DATA_ERROR          = 'CREATE_PAYMENT_DATA_ERROR';

export const BOX_REQUESTED       = 'BOX_REQUESTED';
export const BOX_REQUESTED_SUCCESS   = 'BOX_REQUESTED_SUCCESS';
export const BOX_REQUESTED_ERROR     = 'BOX_REQUESTED_ERROR';

export function createBillingInfo(billingInfo, redirectTo = `${BASE_PATH}/payment/confirmation`, options = {}) {
  const previousPath = options.previousPath;

  return (dispatch, getState) => {
    dispatch({ type: CREATE_PAYMENT_DATA_REQUESTED, data: billingInfo });
    billingInfo.credit_card_number = billingInfo.credit_card_number.split('-').join('');
    billingInfo.phone = billingInfo.phone.split('-').join('');
    billingInfo.country = 'US';
    billingInfo.expiration = billingInfo.expiration.split('/').join('');

    api.createCreditCard({cardholder: _.pick(billingInfo, ['name', 'state', 'street_address1', 'street_address2', 'city', 'zip_code', 'country', 'phone']), card_info: _.pick(billingInfo, ['credit_card_number', 'expiration']), options })
      .then(resp => {
        tracking.push({eventCategory: 'onboarding', eventAction: 'provided payment information', eventLabel: 'completed'});
        tracking.push({eventCategory: 'onboarding', eventAction: 'requested first box', eventLabel: 'completed'});

        if (options.isDressingRoomToGo || options.isThemeboxSignUp) {
          // boxRequested(schedulePickupInfo);
          let state = getState();

          let params = {
            customer_pick_up: options.isDressingRoomToGo,
            ship_by_date: _.get(state, 'questions.responses.ship_by_date'),
            on_demand_customer_msg: _.get(state, 'questions.responses.msg'),
            themebox_sign_up: options.isThemeboxSignUp,
            themebox: options.themeboxTheme,
            themebox_description: options.themeboxDescription
          }

          return api.createOrder(params).then(
            (resp) => {
              dispatch({ type: CREATE_PAYMENT_DATA_RECEIVED, payment: resp.data });
              dispatch({ type: BOX_REQUESTED_SUCCESS, order: resp.data });
              if (options.isDressingRoomToGo) {
                tracking.push({eventCategory: 'purchase', eventAction: 'first order placed', eventLabel: 'purchase'});
                tracking.push({eventCategory: 'purchase', eventAction: 'pickup order placed', eventLabel: 'purchase'});
              }
              history.push({ pathname: redirectTo, state: { previousPath } })
            },
            (errorResp) => {
              dispatch({
                type: BOX_REQUESTED_ERROR,
                error: serverErrors(errorResp),
              });
            }
          );
        } else {
          dispatch({ type: CREATE_PAYMENT_DATA_RECEIVED, payment: resp.data });
          tracking.push({eventCategory: 'purchase', eventAction: 'first order placed', eventLabel: 'purchase'});
          history.push({ pathname: redirectTo, state: { previousPath } })
        }

        dispatch(fetchOrders());
        dispatch(loadCustomerData());
      }, errorResp => {
        dispatch({type: CREATE_PAYMENT_DATA_ERROR, errors: serverErrors(errorResp)});
      });
  };
}


export const BILLING_INFO_REQUESTED     = 'BILLING_INFO_REQUESTED';
export const BILLING_INFO_RECEIVED      = 'BILLING_INFO_RECEIVED';
export const BILLING_INFO_ERROR         = 'BILLING_INFO_ERROR';

export function fetchBillingInfo(){
  return function(dispatch){
    dispatch({ type: BILLING_INFO_REQUESTED });
    api.getCreditCard()
      .then(resp => {
        dispatch({ type: BILLING_INFO_RECEIVED, data: resp.data });
      }, errorResp => {
        dispatch({
          type: BILLING_INFO_ERROR,
          errors: serverErrors(errorResp)
        })
      });
  }
}

export const HOLIDAYS_REQUESTED     = 'HOLIDAYS_REQUESTED';
export const HOLIDAYS_RECEIVED      = 'HOLIDAYS_RECEIVED';
export const HOLIDAYS_ERROR         = 'HOLIDAYS_ERROR';

export function fetchHolidays(){
  return function(dispatch){
    dispatch({ type: HOLIDAYS_REQUESTED });
    api.getHolidays()
      .then(resp => {
        dispatch({ type: HOLIDAYS_RECEIVED, data: resp.data });
      }, errorResp => {
        dispatch({
          type: HOLIDAYS_ERROR,
          errors: serverErrors(errorResp)
        })
      });
  }
}

export const UPDATE_BILLING_INFO_REQUEST = 'UPDATE_BILLING_INFO_REQUEST';
export const UPDATE_BILLING_INFO_SUCCESS = 'UPDATE_BILLING_INFO_SUCCESS';
export const UPDATE_BILLING_INFO_ERROR   = 'UPDATE_BILLING_INFO_ERROR';

export function updateBillingInfo(billingInfo, redirectTo, previousPath){
  return function(dispatch){
    billingInfo.credit_card_number = billingInfo.credit_card_number.split('-').join('');
    billingInfo.phone = billingInfo.phone.split('-').join('');
    billingInfo.expiration = billingInfo.expiration.split('/').join('');
    billingInfo.country = 'US';
    dispatch({ type: UPDATE_BILLING_INFO_REQUEST, data: billingInfo });
    api.updateCreditCard({cardholder: _.pick(billingInfo, ['name', 'state', 'street_address1', 'street_address2', 'city', 'zip_code', 'country', 'phone']), card_info: _.pick(billingInfo, ['credit_card_number', 'expiration']) })
      .then(resp => {
        dispatch({ type: UPDATE_BILLING_INFO_SUCCESS, data: resp.data });
        if (redirectTo) {
          history.push({ pathname: redirectTo, state: { previousPath } });
        }
      }, errorResp => {
        dispatch({
          type: UPDATE_BILLING_INFO_ERROR,
          errors: serverErrors(errorResp)
        })
      })
  }
}



export const ALL_REFERRALS_REQUESTED  = 'ALL_REFERRALS_REQUESTED';
export const ALL_REFERRALS_SUCCESS    = 'ALL_REFERRALS_SUCCESS';
export const ALL_REFERRALS_ERROR      = 'ALL_REFERRALS_ERROR';

export function getReferrals(page){
  return dispatch => {
    dispatch({ type: ALL_REFERRALS_REQUESTED, page: page });

    api.getReferrals({
      page: page,
      limit: 10,
      sort_field: 'created_at',
      sort_order: 'asc'
    }).then(resp => {
      dispatch({
        type: ALL_REFERRALS_SUCCESS,
        page: page,
        data: resp.data
      })
    }).catch(errorResp => {
      dispatch({
        type: ALL_REFERRALS_ERROR,
        page: page,
        errors: serverErrors(errorResp)
      })
    })
  }
}

export const SET_IN_STORE_SIGNUP = 'SET_IN_STORE_SIGNUP';
export function setInStoreSignup() {
  return dispatch => {
    dispatch({
      type: SET_IN_STORE_SIGNUP
    })
  }
}

export const SET_DRESSING_ROOM_TO_GO_SIGNUP = 'SET_DRESSING_ROOM_TO_GO_SIGNUP';
export function setDressingRoomToGoSignup() {
  return (dispatch, getState) => {
    localStorage.setItem('drtgSignup', true);
    dispatch({
      type: SET_DRESSING_ROOM_TO_GO_SIGNUP
    })
  }
}


export function redirectIfProfileIncomplete(customer) {

  let billingComplete = customer.registration_billing_info_complete;
  let shippingComplete = customer.registration_shipping_address_complete;
  let quizComplete = customer.registration_style_profile_complete;
  let path = window.location.pathname;
  let to;

  if (!quizComplete) {
    to = !_.includes(path, "/q") ? `${BASE_PATH}/dashboard` : null;
  } else if (!shippingComplete) {
    to = !_.find(["/q", "/payment/shipping", "payment/info"], p => _.includes(path, p)) ? `${BASE_PATH}/payment/shipping` : null;
  } else if (!billingComplete) {
    to = !_.find(["/q", "/payment/billing"], p => _.includes(path, p)) ? `${BASE_PATH}/payment/billing` : null;
  } else if (!billingComplete && path === "/payment/billing") {
    to = `${BASE_PATH}/payment/confirmation`;
  }
  if (to) {
    history.push(to);
    return true;
  } else {
    return false;
  }
}

export const SET_SKIP_A_BOX_RECEIVED       = 'SET_SKIP_A_BOX_RECEIVED';
export const SET_SKIP_A_BOX_RECEIVED_ERROR = 'SET_SKIP_A_BOX_RECEIVED_ERROR';

export function skipABox() {
  return function(dispatch){
    api.skipABox()
      .then(resp => {
        dispatch({type: SET_SKIP_A_BOX_RECEIVED, data: resp.data});
      }, errorResp => {
        dispatch({
          type: SET_SKIP_A_BOX_RECEIVED_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}

export const SET_UNSKIP_A_BOX_RECEIVED       = 'SET_UNSKIP_A_BOX_RECEIVED';
export const SET_UNSKIP_A_BOX_RECEIVED_ERROR = 'SET_UNSKIP_A_BOX_RECEIVED_ERROR';

export function unSkipABox() {
  return function(dispatch){
    api.unSkipABox()
      .then(resp => {
        dispatch({type: SET_UNSKIP_A_BOX_RECEIVED, data: resp.data});
      }, errorResp => {
        dispatch({
          type: SET_UNSKIP_A_BOX_RECEIVED_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}

