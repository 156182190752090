import theme0 from '../images/Themebox/LetsGetDressed.jpg';
import theme1 from '../images/Themebox/SummerHitList.jpg';
import theme2 from '../images/Themebox/TS_ThemeBox_DenimDrawer.jpg';
import theme3 from '../images/Themebox/TS_ThemeBox_App_TaurusStyle.jpg';
import theme4 from '../images/Themebox/CapsuleCollection.jpg';
import theme5 from '../images/Themebox/TropicStateofMind.jpg';
import theme6 from '../images/Themebox/HeartOnYourTeeNew.jpg';
import theme7 from '../images/Themebox/HotMama.jpg';

export const choices = [
  {
    id: 0,
    label: 'The Let’s Get Dressed Box',
    theme: 'The Let’s Get Dressed Box',
    description: `Ready to get dressed? We'll style you in our one-and-done must-haves—perfect from iced coffee runs to patio date night.`,
    attachment: {
      href: theme0,
    },
    ref: 'theme_boxes',
  },
  {
    id: 1,
    label: 'The Summer Hit List Box',
    theme: 'The Summer Hit List Box',
    description: `Summer is here, and so are new must-have pieces! We'll style the hottest new trends—from beachy pants to modern boho dresses.`,
    attachment: {
      href: theme1,
    },
    ref: 'theme_boxes',
  },
  {
    id: 2,
    label: 'The Denim Drawer Box',
    theme: 'The Denim Drawer Box',
    description: `We were on a break—but now we're back and ready to refresh our denim drawer! We'll style the hottest new silhouettes (that'll have you falling in love all over again).`,
    attachment: {
      href: theme2,
    },
    ref: 'theme_boxes',
  },
  {
    id: 3,
    label: 'The Taurus Style Box',
    theme: 'The Taurus Style Box',
    description: `It's Taurus season, and we're ready for fashion that meets function as we get into gear. We'll send looks that mean business—and keep up as we get back out.`,
    attachment: {
      href: theme3,
    },
    ref: 'theme_boxes',
  },
  {
    id: 4,
    label: 'The Capsule Collection Box',
    theme: 'The Capsule Collection Box',
    description: `Skipped shopping last spring? We'll style a capsule of essentials to jump start your wardrobe (and build great outfits all season long).`,
    attachment: {
      href: theme4,
    },
    ref: 'theme_boxes',
  },
  {
    id: 5,
    label: 'The Tropic State of Mind Box',
    theme: 'The Tropic State of Mind Box',
    description: `Whether you're going on vacay (or already on stay-cay), we’ll give your wardrobe the warm-weather glow-up it needs.`,
    attachment: {
      href: theme5,
    },
    ref: 'theme_boxes',
  },
  {
    id: 6,
    label: 'The Heart On Your Tee Box',
    theme: 'The Heart On Your Tee Box',
    description: `Wear your heart on your tee with mood-boosting (and supersoft) graphics that keep you moving forward, in style.`,
    attachment: {
      href: theme6,
    },
    ref: 'theme_boxes',
  },
  {
    id: 7,
    label: 'The Hot Mama Box',
    theme: 'The Hot Mama Box',
    description: `Fall in love with looks that do date night right, whether you're cozied up at a cafe or snuggling on the couch.`,
    attachment: {
      href: theme7,
    },
    ref: 'theme_boxes',
  },
];

export default { choices };
