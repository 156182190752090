import * as yup from "yup";

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .label("Password")
    .min(8, `Min. 8 characters.`)
    .required("Required"),
  password_confirmation: yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: yup.string().oneOf(
      [yup.ref("password")],
      "Both password need to be the same"
    )
  })
})


export const resetPasswordInitialValues = {
  password: '',
  password_confirmation: ''
}