import React from "react";
import style from "./Modal.module.scss";
import Icon from "Icon";
import Button from "Button";

const Modal = ({ handleClose, saveChanges, show, disableButton, children, saveButtonText }) => {
  const showHideClassName = show ?  ([style.Modal], [style.ModalShow]) : [style.Modal];

  return (
    <div className={showHideClassName}>
      <div className={style.ModalBackdrop} onClick={handleClose} />

      <section className={style.ModalInner}>
        {children}
        <button className={style.ModalClose} onClick={handleClose}>
          <Icon icon="cross" width={32} height={32} color="#333333" />
        </button>

        {saveChanges &&
          <div className={style.ModalBtn}>
          <Button handleClick={saveChanges} disabled={disableButton} content={saveButtonText ? saveButtonText : `Save Changes`} fullWidth variant="Primary" />
          </div>
        }
      </section>
    </div>
  );
};

export default Modal;