import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import QuestionReducer from "./QuestionReducer";
import OrderReducer from "./OrderReducer";
import CustomerReducer from "./CustomerReducer";
import ThemeboxReducer from './ThemeboxReducer';
import HolidaysReducer from './HolidaysReducer';

export default combineReducers({
  auth: AuthReducer,
  questions: QuestionReducer,
  orders:  OrderReducer,
  customer: CustomerReducer,
  themeboxes: ThemeboxReducer,
  holidays: HolidaysReducer
});
