import React, { useState } from "react";
import { Cell } from "styled-css-grid";
import Button from "Button";
import style from "./FileUpload.module.scss";
import classnames from "classnames";

import { useDispatch } from "react-redux";
import { updateResponse } from "../store/Actions/QuestionAction";

function FileUpload({ getValue, questionRef, initialValue, rotationDegree, parentComponentName }) {

  const dispatch = useDispatch();

  const degree = rotationDegree ? rotationDegree : 0;
  const [imagePreviewUrl, setImagePreviewUrl] = useState(initialValue);
  const [imageRotationDegree, setImageRotationDegree] = useState(degree);
  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();

  function uploadFile(e) {

    const reader = new FileReader();
    const file = e.target.files[0];

    getValue(file, questionRef);

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    }
    reader.readAsDataURL(file)
  }

  function ResetForm() {
    setImagePreviewUrl(null);
  }

  const getPreviewImage = {
    transform: `rotate(${imageRotationDegree}deg)`,
    marginTop: (imageRotationDegree === 90 || imageRotationDegree === 270) && (imageWidth > imageHeight) ? (imageWidth / 2) - (imageHeight / 2) : 0,
    marginBottom: (imageRotationDegree === 90 || imageRotationDegree === 270) && (imageWidth > imageHeight) ? (imageWidth / 2) - (imageHeight / 2) + 26 : '26px',
  };


  const ButtonStyles = {
    position: `absolute`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: `auto`,
    pointerEvents: `none`,
    width: `60px`,
    height: `60px`,
  }

  const editStyles = {
    pointerEvents: `none`,
  }

  function rotateImage() {
    let degree = imageRotationDegree + 90;
    degree = degree > 360 ? 90 : degree;
    setImageRotationDegree(degree);

    dispatch(updateResponse('full_body_image_rotation', degree));
  }

  return (
    <Cell left={4} width={6} className={style.FileUploadLabel}>
      <>
        <label>
          <img
            src={imagePreviewUrl}
            onLoad={(event) => {
              event.target && setImageHeight(event.target.height);
              event.target && setImageWidth(event.target.width);
            }}
            className={classnames(
              style.FileUpload,
              `mb2`,
              imagePreviewUrl && style.FileUploadActive
            )}
            style={getPreviewImage}
          />

          <input
            className={style.FileUploadInput}
            type="file"
            name="file"
            onChange={(e) => {
              uploadFile(e);
            }}
            accept="image/*"
          />

          {!imagePreviewUrl && (
            <Button
              variant="Primary"
              rounded
              icon="add"
              iconColor="#FFFFFF"
              iconWidth={32}
              iconHeight={32}
              style={ButtonStyles}
            />
          )}

          <div className={'df jfse'}>
            {imagePreviewUrl && (
              <div>
                <Button variant="Primary" content="Edit" style={editStyles} />
              </div>
            )}

            {imagePreviewUrl && (
              <div>
                <Button
                  variant="Primary"
                  content="Rotate Image"
                  onClick={rotateImage}
                />
              </div>
            )}
          </div>
        </label>
      </>
    </Cell>
  );
}

export default FileUpload;
