import api from "../../api";
import { serverErrors, UNEXPECTED_ERROR, serverErrorsFromActiveRecordErrors } from "./../../utils/errors";
import _ from 'lodash';

export const THEMEBOXES_REQUESTED   = 'THEMEBOXES_REQUESTED';
export const THEMEBOXES_RECEIVED    = 'THEMEBOXES_RECEIVED';
export const THEMEBOXES_ERROR       = 'THEMEBOXES_ERROR';

export function loadThemeboxes(){
  return function(dispatch, getState) {

    dispatch({type: THEMEBOXES_REQUESTED});
    api.getThemeboxes()
      .then(resp => {
        dispatch({type: THEMEBOXES_RECEIVED, themeboxes: resp.data});
      })
      .catch(errorResp => {
        dispatch({type: THEMEBOXES_ERROR, errors: serverErrors(errorResp)})
      });
  }
}