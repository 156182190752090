import React, { useState } from 'react';
import Footer from 'Footer';
import Header from '../Dashboard/Header';
import ReviewItem from 'ReviewItem';
import DiscountStatus from 'DiscountStatus';
import Modal from 'Modal';
import TextArea from 'TextArea';
import Radio from 'Radio';
// import items from "./dummyData.json";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Cell } from 'styled-css-grid';
import _ from 'lodash';

import {
  fetchOrCreateInvoice,
  fetchAvailableExchangeSizes,
  updateOrderLineItems,
} from '../store/Actions/OrderAction';

function Review() {
  let itemCount = 0;
  let buildLineItems = [];
  let lineItemCollection = [];

  const order = useSelector((state) => _.get(state, 'orders.currentOrder', {}));
  const invoice = useSelector(
    (state) => _.get(state, 'orders.currentInvoice'),
    {}
  );
  const fetchingInvoice = useSelector((state) =>
    _.get(state, 'orders.fetchingInvoice')
  );
  const isLoading = useSelector((state) => _.get(state, 'orders.isLoading'));
  const exchangeSizes = useSelector(
    (state) => _.get(state, 'orders.exchangeSizes'),
    []
  );
  const items = _.uniqBy(_.flatten(_.map(order.outfits, (outfit) => { return outfit.line_items })), item => item.id) || [];
  
  const ordersLength = items && items.length;

  _.each(order.outfits, (outfit, i) => {
    outfit.title = `OUTFIT ${i + 1}`;
    buildLineItems.push(outfit.line_items);
  });
  
  buildLineItems.map((item, index) => {
    item.reduce((o, v) => {
      v.choices = order.customer_pick_up
        ? [
            { id: `return-${v.id}`, label: 'Return' },
            { id: `keep-${v.id}`, label: 'Keep' },
          ]
        : [
            { id: `return-${v.id}`, label: 'Return' },
            { id: `exchange-${v.id}`, label: 'Exchange' },
            { id: `keep-${v.id}`, label: 'Keep' },
          ];
      return lineItemCollection.push({
        ...v,
        label: '',
        discounted: false,
      });
    }, {});

    return (itemCount = _.uniqBy(lineItemCollection, 'id').length);
  });

  const [choices, setChoices] = useState({});

  const [keptCount, setKeptCount] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [returnModal, setReturnModal] = useState(false);
  const [keepModal, setKeepModal] = useState(false);
  const [exchangeModal, setExchangeModal] = useState(false);
  const [activeLineItem, setActiveLineItem] = useState();
  const dispatch = useDispatch();

  if (order.id && !isLoading && _.isEmpty(choices)) {
    let newChoices = _.chain(_.uniqBy(lineItemCollection, 'id'))
      .reduce((c, l) => {
        let info, additionalInfo;
        if (_.includes(l.customer_feedback, '. Additional comments: ')) {
          info = l.customer_feedback
            .split('. Additional comments: ')[0]
            .split(' and ');
          additionalInfo = l.customer_feedback.split(
            '. Additional comments: '
          )[1];
        } else {
          info = l.exchange_size ? l.exchange_size : [];
          additionalInfo = l.customer_feedback;
        }
        c[l.id] = {
          action: _.isNull(l.claimed_kept)
            ? ''
            : l.claimed_kept
            ? l.exchange_size
              ? 'Exchange'
              : 'Keep'
            : 'Return',
          info,
          additionalInfo,
        };
        return c;
      }, {})
      .value();
    setChoices(newChoices);
    updateCounts(newChoices);
  }
  
  if (order.id && !fetchingInvoice && _.isEmpty(invoice)) {
    dispatch(fetchOrCreateInvoice(order.id));
  }

  function getReviewItem(val, lineItemProps) {
    setActiveLineItem(lineItemProps);

    let newChoices = {
      ...choices,
      [lineItemProps.id]: {
        info: '',
        additionalInfo: '',
        action: val,
      },
    };
    setChoices(newChoices);
    if (val === `Return`) setReturnModal(lineItemProps.id);
    if (val === `Keep`) setKeepModal(lineItemProps.id);
    if (val === `Exchange`) {
      setExchangeModal(lineItemProps.id);
      dispatch(fetchAvailableExchangeSizes(lineItemProps.id));
    }
    updateCounts(newChoices);
  }

  function updateCounts(newChoices) {
    setKeptCount(
      _.chain(_.uniqBy(lineItemCollection, 'id'))
        .filter((l) =>
          _.includes(['Exchange', 'Keep'], _.get(newChoices, `${l.id}.action`))
        )
        .size()
        .value()
    );
    setAnsweredCount(
      _.chain(_.uniqBy(lineItemCollection, 'id'))
        .filter((l) => _.get(newChoices, `${l.id}.action`))
        .size()
        .value()
    );
  }

  function hideModal() {
    setReturnModal(false);
    setExchangeModal(false);
    setKeepModal(false);
  }

  function getOption(val, id, additional = false) {
    setChoices({
      ...choices,
      [id]: {
        ..._.get(choices, `${id}`, {}),
        info: additional ? _.get(choices, `${id}.info`, []) : val,
        additionalInfo: additional ? val : '',
      },
    });
  }

  function saveExchangeChanges(id) {
    if (_.isEmpty(exchangeSizes)) {
      let newChoices = {
        ...choices,
        [id]: {
          ..._.get(choices, `${id}`, {}),
          action: 'Return',
        },
      };
      setChoices(newChoices);
      updateCounts(newChoices);
      setReturnModal(id);
    }
    setExchangeModal(false);
  }

  function saveChanges(id) {
    hideModal();
  }

  function goToConfirm() {
    dispatch(updateOrderLineItems(choices));
  }

  const modalOptions = {
    sizes: [
      { label: 'XS' },
      { label: 'S' },
      { label: 'M' },
      { label: 'L' },
      { label: 'XL' },
    ],
    returnReasons: [
      { label: 'Too Small' },
      { label: 'Too Big' },
      { label: 'Not My Style' },
      { label: 'Have Something Similar' },
      { label: 'Too Expensive' },
      { label: "Didn't Like Fit" },
      { label: 'Damaged' },
      { label: "Wouldn't Wear Enough" },
      { label: "Couldn't Keep It All" },
    ],
    keepReasons: [
      { label: 'Perfect Fit' },
      { label: 'Versatile' },
      { label: 'Comfortable' },
      { label: 'Totally my style' },
      { label: "Wouldn't have picked myself" },
      { label: 'Great Price' },
    ],
  };

  return (
    <>
      {!_.isEmpty(order) && (
        <DiscountStatus
          keptCount={keptCount}
          text={`Keep ${4 - keptCount} more ${
            4 - keptCount <= 1 ? `item` : `items`
          } & get 10% off`}
          success="You’ve unlocked 10% off!"
          length={keptCount}
          completed={keptCount > 3}
        />
      )}

      <div className="group group--slim" style={{ paddingBottom: `30vh` }}>
        <Header
          header="Review Your Order"
          subheader={
            !_.isEmpty(order) &&
            'Let us know which items you’ll be adding to your wardrobe. Providing feedback on each item helps your stylist do even better next time! Keep four or more items and receive 10% off your entire purchase.'
          }
          TextAlignCenter
        />

        <div>
          {
            items.map((lineItem, i) => {
              const suggestedItemsLength = 0;
              const isLastOrder = ordersLength === i + 1;
              const isLastLineItem = i ? true : false;
              return (
                
                <ReviewItem
                  noIcon
                  discount={keptCount > 3}
                  hr
                  key={lineItem.description}
                  lineItemValue={getReviewItem}
                  title={''}
                  lineItem={lineItem}
                  action={_.get(choices, `${lineItem.id}.action`)}
                  isLastLineItem={
                    isLastOrder &&
                    suggestedItemsLength === 0 &&
                    isLastLineItem
                  }
                  isDrtg={order.customer_pick_up}
                />                  
              )})
          }
          {activeLineItem && (
            <>
              {/* Exchange Modal */}
              {exchangeModal ? (
                <Modal
                  show={exchangeModal === activeLineItem.id}
                  handleClose={() => {
                    saveExchangeChanges(activeLineItem.id);
                  }}
                  saveChanges={() => {
                    saveExchangeChanges(activeLineItem.id);
                  }}
                  saveButtonText={
                    !isLoading &&
                    _.isEmpty(exchangeSizes) &&
                    'Return Item'
                  }
                >
                  <h2 className="ff-body tac h2">Exchange</h2>
                  <ReviewItem
                    showSize
                    title="Exchange"
                    noOptions
                    lineItem={activeLineItem}
                  />
                  <hr />
                  {!isLoading && _.isEmpty(exchangeSizes) ? (
                    <h4 className="h4 ttu fw-medium ls1 mb tac">
                      No exchange sizes found
                    </h4>
                  ) : (
                    <>
                      <h4 className="h4 ttu fw-medium ls1 mb tac">
                        What size would you like?
                      </h4>
                      <Grid
                        gap="16px"
                        columns="repeat(auto-fit,minmax(30px,1fr))"
                      >
                        <Radio
                          initialValue={_.get(
                            choices,
                            `${activeLineItem.id}.info`
                          )}
                          questionId={activeLineItem.id}
                          getValue={(val) => {
                            getOption(val, activeLineItem.id);
                          }}
                          width={4}
                          choices={_.map(exchangeSizes, (s) => {
                            return { label: s, value: s };
                          })}
                          isExchangeModal={true}
                        />
                      </Grid>
                    </>
                  )}
                </Modal>
              ) : (
                ''
              )}

              {/* Return Modal */}
              {returnModal ? (
                <Modal
                  show={returnModal === activeLineItem.id}
                  handleClose={() => {
                    hideModal(activeLineItem.id);
                  }}
                  saveChanges={() => {
                    saveChanges(activeLineItem.id);
                  }}
                >
                  <h2 className="ff-body tac h2">Return</h2>
                  <ReviewItem
                    title="Return"
                    noOptions
                    lineItem={activeLineItem}
                  />
                  <hr />

                  <h4 className="h4 ttu fw-medium ls1 mb tac">
                    Let your stylist know why you didn't love this
                  </h4>
                  <Grid gap="16px" columns="repeat(1, 1fr)">
                    <Radio
                      initialValue={_.get(
                        choices,
                        `${activeLineItem.id}.info`,
                        []
                      )}
                      questionId={activeLineItem.id}
                      multiSelect
                      getValue={(val) => {
                        getOption(val, activeLineItem.id);
                      }}
                      choices={modalOptions.returnReasons}
                    />

                    <Cell width={12}>
                      <h4 className="h4 ttu fw-medium ls1 mt2">
                        Note for stylist
                      </h4>
                    </Cell>
                    <TextArea
                      initialValue={_.get(
                        choices,
                        `${activeLineItem.id}.additionalInfo`,
                        []
                      )}
                      getValue={(val) => {
                        getOption(val, activeLineItem.id, true);
                      }}
                      placeholder="Let your stylist know more so they can have a better understanding of your needs..."
                    />
                  </Grid>
                </Modal>
              ) : (
                ''
              )}

              {/* Keep Modal */}
              {keepModal ? (
                <Modal
                  show={keepModal === activeLineItem.id}
                  handleClose={() => {
                    hideModal(activeLineItem.id);
                  }}
                  saveChanges={() => {
                    saveChanges(activeLineItem.id);
                  }}
                >
                  <h2 className="ff-body tac h2">Keep</h2>
                  <ReviewItem
                    title="Keep"
                    noOptions
                    lineItem={activeLineItem}
                  />
                  <hr />

                  <h4 className="h4 ttu fw-medium ls1 mb tac">
                    What did you like about this item?
                  </h4>
                  <Grid gap="16px" columns="repeat(1, 1fr)">
                    <Radio
                      initialValue={_.get(
                        choices,
                        `${activeLineItem.id}.info`,
                        []
                      )}
                      questionId={activeLineItem.id}
                      multiSelect
                      getValue={(val) => {
                        getOption(val, activeLineItem.id);
                      }}
                      choices={modalOptions.keepReasons}
                    />

                    <Cell width={12}>
                      <h4 className="h4 ttu fw-medium ls1 mt2">
                        Note for stylist
                      </h4>
                    </Cell>
                    <TextArea
                      initialValue={_.get(
                        choices,
                        `${activeLineItem.id}.additionalInfo`,
                        []
                      )}
                      getValue={(val) => {
                        getOption(val, activeLineItem.id, true);
                      }}
                      placeholder="Let your stylist know more so they can have a better understanding of your needs..."
                    />
                  </Grid>
                </Modal>
              ) : (
                ''
              )}
            </>
          )}
        </div>

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType="submit"
          buttonCopy="Review Order"
          footerCount={keptCount}
          disableButton={answeredCount < itemCount}
          footerStep={() => {
            goToConfirm();
          }}
        />
      </div>
    </>
  );
}

export default Review;
