import * as yup from "yup";
import valid from 'card-validator'; 


export const billingSchema = yup.object().shape({
  name: yup.string(),
  credit_card_number: yup.string()
    .label('Card Number')
    .min(15, 'Please enter a valid card number')
    .test('test-CC-number', // this is used internally by yup
    'Credit Card number is invalid', //validation message
     value => valid.number(value).isValid)
    .required("Required"),
  expiration: yup.string().typeError('Not a valid expiration date. Example: MM/YY')
    .max(5, 'Not a valid expiration date. Example: MM/YY')
    .matches(
      /([0-9]{2})\/([0-9]{2})/,
      'Not a valid expiration date. Example: MM/YY'
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid Expiration Date has past',
      expirationDate => {
        if (!expirationDate) {
          return false
        }

        const today = new Date()
        const monthToday = today.getMonth() + 1
        const yearToday = today
          .getFullYear()
          .toString()
          .substr(-2)

        const [expMonth, expYear] = expirationDate.split('/')

        if (Number(expYear) < Number(yearToday)) {
          return false
        } else if (
          Number(expMonth) < monthToday &&
          Number(expYear) <= Number(yearToday)
        ) {
          return false
        }

        return true
      }
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid Expiration Month',
      expirationDate => {
        if (!expirationDate) {
          return false
        }

        const [expMonth] = expirationDate.split('/')

        if (Number(expMonth) > 12) {
          return false
        }

        return true
      }
    )
    .required('Expiration date is required'),

  cvv: yup.string().required("Required"),
  street_address1: yup.string().required("Required"),
  street_address2: yup.string(),
  city: yup.string().required("Required"),
  zip_code: yup.number().label('ZIP Code').min(5, "Min. 5 characters.").required("Required"),
  state: yup.string().required("Required"),
  phone: yup.string().min(12, 'Please enter a valid Phone Number').required("Required")
})


export const billingInitialValues = {
  name: '',
  credit_card_number: '',
  expiration: '',
  cvv: '',
  street_address1: '',
  street_address2: '',
  city: '',
  zip_code: '',
  phone: '',
  state: ''
}

