import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Dashboard/Header.jsx';
import SectionHeader from '../SectionHeader';
import ReferralList from './ReferralList';
import './ReferFriend.Module.scss';
import line from '../images/Tile/StyleProfile/linePink.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../FormInput/FormInput.module.scss';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';
import { getReferrals } from './../store/Actions/CustomerAction';
import _ from 'lodash';

const color = {
  color: '#003b5c',
};

function ReferFriend() {
  const dispatch = useDispatch();
  const customer = useSelector((reduxState) => reduxState.customer.data);
  const referrals = useSelector((reduxState) =>
    _.get(reduxState, 'customer.referrals.results', [])
  );
  const [copiedTrendsend, setCopiedTrendsend] = useState(false);
  const [copiedDrtg, setCopiedDrtg] = useState(false);

  const link = `${window.location.protocol}//${window.location.host}?referral=${customer.referral_code}`;

  useEffect(() => {
    dispatch(getReferrals());
  }, [dispatch]);
  return (
    <div>
      <NavBar
        background="#003b5c"
        text="white"
        logo={EvereveTrendsendStackedLogoWhite}
        profile={profile}
        logOut={logOut}
      />
      <div className="group mt ReferralContainer">
        <Header
          header="Refer a Friend"
          subheader="Your friends deserve to be styled too. Share your link and you'll both be rewarded."
          color={color}
          TextAlignCenter
          FontSizeLarge
        />

        <div className="referralLink">
          <input
            className="textInput"
            value={link}
            onChange={({ target: { value } }) => setCopiedTrendsend(false)}
            readOnly
          />
          <CopyToClipboard text={link} onCopy={() => setCopiedTrendsend(true)}>
            <button className="copyBtn">
              {copiedTrendsend ? 'COPIED' : 'COPY'}
            </button>
          </CopyToClipboard>
        </div>

        <div className="referrals">
          <ol>
            <li>
              Share your Trendsend referral link with a friend via text, email,
              or social media. Be sure to let them know why you love being styled.
            </li>
            <li>
              When a friend clicks your link, creates an account, and places her
              first order, she’ll receive 20% off every item in that order.
            </li>
            <li>
              You’ll earn 20% off your next Trendsend at the time it is styled!
            </li>
          </ol>
          <br />
          <h2>Your Referrals</h2>
          <p>
            All listed referrals are active and have not yet been applied to an
            order.
          </p>

          <ReferralList referrals={referrals} />
        </div>
        <div className="finePrint">
          <p>
            While you can accrue multiple referral discounts, each expires 365
            days after being earned (signing up for a Trendsend subscription
            helps ensure you won’t ever lose a discount). Your oldest referral
            discount is automatically applied to an order when a box is styled.
            A discount can only be used once, is not retained if all items are
            returned and cannot be used in combination.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ReferFriend;
