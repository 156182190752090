import React from "react";
import classnames from "classnames";
import style from "./DiscountStatus.module.scss";
import off from "images/Tile/Icon/Dash-Discount.svg";
import unlockedLeft from "images/unlocked-left.svg";
import unlockedRight from "images/unlocked-right.svg";

function DiscountStatus({ text, completed, success, length, keptCount }) {

  const discountStyles = {
    "--discount-width": length
  }

  return (
    <div className={style.DiscountStatus} style={discountStyles}>
      {completed ? (
        <p className={classnames(style.DiscountStatusText, `c-white`)}>
          <img className="dib vam mr" src={unlockedLeft} alt={success} />
          {success}
          <img className="dib vam ml" src={unlockedRight} alt={success} />
        </p>
      ) : (
        <p className={style.DiscountStatusText}>
          <span>Keep <span className={style.CountMoreText}>{4 - keptCount} more</span> {4 - keptCount <= 1 ? `item` : `items`} & get 10% off</span>
          <img className="dib vam ml" src={off} alt={text} />
        </p>
      )}
    </div>
  );
}

export default DiscountStatus;
