import React, { useEffect } from 'react';
import style from './CatalogQRLandingPage.module.scss';
import Button from 'Button';

import { BASE_PATH } from './../Config';
import history from '../History';

export default function CatalogQRLandingPage() {
  useEffect(() => {
    document.body.classList.add('overflow-none');
    return () => {
      document.body.classList.remove('overflow-none');
    };
  }, []);
  
  return (
    <div className={style.CatalogContainer}>
      <div className={style.Gradiant}></div>

      <div className={style.CatalogWrapper}>
        <div className='tac'>
          <h1 className={style.Title}>How</h1>
          <h1 className={style.Title}>Trendsend</h1>
          <h1 className={style.Title}>Works</h1>
        </div>
        <div className={style.CardContainer}>
          <div className={style.Card}>
            <div className={style.StepNumber}>1</div>
            <h2>Take Your Style Quiz</h2>
          </div>
          <div className={style.Card}>
            <div className={style.StepNumber}>2</div>
            <h2>Try It All On</h2>
          </div>
          <div className={style.Card}>
            <div className={style.StepNumber}>3</div>
            <h2>Keep What You Love</h2>
          </div>
        </div>
        <div className={style.ButtonWrapper}>
          <Button
            handleClick={() => {
              history.push(`${BASE_PATH}/register`);
            }}
            variant='WhiteInverted'
            content={'Get Started'}
            fullWidth={true}
          />
        </div>
      </div>
    </div>
  );
}
