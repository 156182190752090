import React, { useState, useEffect } from 'react';
import './ManageSubscription.Module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import {
  subscriptionUpdated,
  fetchShippingAddress,
  fetchBillingInfo,
} from './../store/Actions/CustomerAction';
import history from '../History';
import _ from 'lodash';
import moment from 'moment';

// components
import { Grid } from 'styled-css-grid';
import NavBar from 'NavBar';
import Header from '../Dashboard/Header.jsx';
import SubHeader from '../CustomizeOrder/SubHeader';
import Modal from 'Modal';
import Button from 'Button';
import Footer from 'Footer';
import SubscriptionSelector from './SubscriptionSelector';
import ConfirmShippingAddress from './../ConfirmShippingAddress';
import ConfirmPayment from './../ConfirmPayment';

// images
import EvereveTrendsendStackedLogoWhite from '../images/EVEREVE-TrensendStackedLogoWhite.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';
import { BASE_PATH } from 'Config';

const color = {
  color: '#003b5c',
};

function ManageSubscription() {
  // Redux state constants
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer.data);
  const subscriptionType = customer.subscription_type || '';
  const currentSubscriptionType = useSelector((state) =>
    _.get(state, 'customer.data.subscription_type')
  );
  const currentOrder = useSelector((state) => state.orders.currentOrder);
  const mostRecentOrderDate = useSelector((reduxState) =>
    _.chain(reduxState)
      .get('orders.orders', [])
      .filter((f) => f.subscription_type !== 'subscription_on_demand')
      .map((o) => new Date(o.expected_delivery_date))
      .orderBy()
      .reverse()
      .get('0')
      .value()
  );

  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  const celerantId = useSelector((state) =>
    _.get(state, 'customer.data.celerant_id', {})
  );

  const customerSkipDays = useSelector((state) =>
    _.get(state, 'customer.data.skip_days')
  );

  // State hooks
  const [modalVisibility, setModalVisibility] = useState(false);
  const [newSubscription, setNewSubscription] = useState(
    currentSubscriptionType
  );

  // Constants
  const isAbandonedAccount = _.isEmpty(celerantId) && !_.isEmpty(customer);

  const boxCurrentlySkipped = customerSkipDays && customerSkipDays !== 0;

  const currentOrderDeliveryDate = currentOrder.expected_delivery_date;

  const hasCurrentOrder = !_.isEmpty(currentOrder);
  
  const estimatedDeliveryDate = hasCurrentOrder
    ? moment(currentOrderDeliveryDate).format('MM/DD/YYYY')
    : moment(mostRecentOrderDate)
        .add(parseInt(subscriptionType.split('_')[1]) + 5, 'days')
        .add(customerSkipDays, 'days').format('MM/DD/YYYY');

  const skipABoxNotAvailable =
    newSubscription !== 'subscription_90_day' &&
    newSubscription !== 'subscription_on_demand';

  // Functions
  function handleRequestChange(value) {
    setNewSubscription(value);
  }

  function submitForm(e) {
    dispatch(subscriptionUpdated(newSubscription, `${BASE_PATH}/dashboard`));
  }

  function showModal() {
    setModalVisibility(true);
  }

  function hideModal() {
    setModalVisibility(false);
  }

  function saveSkipBox() {
    const skipBoxCallback = boxCurrentlySkipped ? 'unSkip' : 'skip';
    const subscriptionSelected = newSubscription
      ? newSubscription
      : subscriptionType;
      
    dispatch(subscriptionUpdated(subscriptionSelected, null, skipBoxCallback));
    hideModal();
  }

  function showCurrentSubscription() {
    switch (newSubscription ? newSubscription : currentSubscriptionType) {
      case 'subscription_30_day':
        return 'Every Month';
      case 'subscription_60_day':
        return 'Every Other Month';
      case 'subscription_90_day':
        return 'Every Quarter';
      case 'subscription_on_demand':
        return 'On Demand';
    }
  }

  useEffect(() => {
    dispatch(fetchBillingInfo());
    dispatch(fetchShippingAddress());
  }, [dispatch]);

  useEffect(() => {
    setNewSubscription(currentSubscriptionType);
  }, [currentSubscriptionType]);

  return (
    <>
      <NavBar
        background='#003b5c'
        text='#003b5c'
        logo={EvereveTrendsendStackedLogoWhite}
        profile={profile}
        logOut={logOut}
      />
      <div className='manageSubscriptionContainer group mt mb2'>
        <Header
          header='Subscription'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />

        <SubHeader
          header='How often would you like to have a Trendsend box shipped to you?'
          subheader={[
            `Your current Trendsend’s subscription is `,
            <>
              <div className='subcriptionType'>{showCurrentSubscription()}</div>
              {estimatedDeliveryDate && !hasCurrentOrder && (
                <>
                  <div className='mt2 tac'>
                    Your next box is scheduled to arrive on:
                  </div>
                  <div className='subcriptionType'>
                    {estimatedDeliveryDate}
                  </div>{' '}
                </>
              )}
            </>,
          ]}
          color={color}
          TextAlignCenter
          FontSizeLarge
        />

        <SubHeader
          subheader={
            <span className='mb2 ff-body'>
              Choose a different subscription frequency by clicking below:
            </span>
          }
          color={color}
          TextAlignCenter
        />
        <div>
          <SubscriptionSelector
            onSubscriptionSelect={handleRequestChange}
            boxCurrentlySkipped={boxCurrentlySkipped}
            mostRecentOrderDate={mostRecentOrderDate}
            updatedSubcriptionFormat={showCurrentSubscription()}
            subscriptionType={subscriptionType}
            newSubscription={newSubscription}
          />

          {!boxCurrentlySkipped &&
          !hasCurrentOrder &&
          estimatedDeliveryDate &&
          skipABoxNotAvailable ? (
            <div className='mt tac tdu' onClick={() => showModal()}>
              Skip Next Box
            </div>
          ) : !hasCurrentOrder && boxCurrentlySkipped ? (
            <div className='onDemandMessage'>
              <div>You are currently skipping your next box.</div>
              <div>To order an On Demand box</div>
              <NavLink
                className='onDemandLink'
                to={{
                  pathname: `${BASE_PATH}/customize/order/order`,
                }}
              >
                click here
              </NavLink>
            </div>
          ) : null}

          {newSubscription === `subscription_on_demand` || !newSubscription ? (
            ''
          ) : (
            <ConfirmShippingAddress
              path={history.location.pathname}
              shippingAddress={shippingAddress}
            />
          )}

          {newSubscription === `subscription_on_demand` || !newSubscription ? (
            ''
          ) : (
            <ConfirmPayment
              path={history.location.pathname}
              isAbandonedAccount={isAbandonedAccount}
            />
          )}

          <Footer
            bgColor={[`#CE0058`, `#E67FAB`]}
            buttonType='submit'
            buttonCopy={`Save`}
            footerStep={submitForm}
          />
        </div>
      </div>
      {modalVisibility && (
        <Modal
          show={true}
          handleClose={() => hideModal()}
          saveButtonText={'Confirm'}
        >
          <h2 className='ff-body tac h2'>
            {boxCurrentlySkipped ? (
              <>
                Change your mind? Great—we're ready to style!
                <br />
                Your next box is rescheduled with an estimated delivery date of:
              </>
            ) : (
              <>
                Your order will be skipped.
                <br />
                The next box will be scheduled with an estimated delivery date of:
              </>
            )}
          </h2>
          <div className='subcriptionType mt2 tac h3'>
            {newSubscription === currentSubscriptionType
              ? moment(estimatedDeliveryDate)
                  .add(parseInt(subscriptionType.split('_')[1]), 'days')
                  .format('MM/DD/YYYY')
              : newSubscription > currentSubscriptionType ? moment(estimatedDeliveryDate)
                  .add(parseInt(newSubscription.split('_')[1]), 'days').add(parseInt(currentSubscriptionType.split('_')[1]), 'days')
                  .format('MM/DD/YYYY'): moment(estimatedDeliveryDate)
                  .format('MM/DD/YYYY')}
          </div>
          {newSubscription !== subscriptionType && (
            <>
              <h3 className='mt2 tac h3'>Your subcription will be saved as:</h3>
              <div className='mt2 tac h3'>{showCurrentSubscription()}</div>
            </>
          )}
          <hr />
          <Grid gap='16px' columns='repeat(auto-fit,minmax(30px,1fr))'>
            <Button
              handleClick={() => hideModal()}
              variant='Primary'
              content={'Cancel'}
              fullWidth={true}
              type={''}
              small={true}
            />
            <Button
              handleClick={() => saveSkipBox()}
              variant='Primary'
              content={'Save'}
              fullWidth={true}
              type={''}
              small={true}
            />
          </Grid>
        </Modal>
      )}
    </>
  );
}

export default ManageSubscription;
