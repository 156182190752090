import React, {Fragment} from "react";
import { Cell } from "styled-css-grid";

function Statement({ title }) {
  return (
    <Fragment>
      <Cell width={12}>
        <div className="mt tac">
          <h2 className="fw-light ff-body" >{title}</h2>
        </div>
      </Cell>
    </Fragment>
  );
}

export default Statement;
